.VerticalSeparator {
  width: 1px;
  background: rgba(82,82,82,1);
  background: -moz-linear-gradient(top, rgba(82,82,82,1) 0%, rgba(82,82,82,1) 1%, rgba(146,146,146,1) 45%, rgba(146,146,146,1) 55%, rgba(82,82,82,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(82,82,82,1)), color-stop(1%, rgba(82,82,82,1)), color-stop(45%, rgba(146,146,146,1)), color-stop(55%, rgba(146,146,146,1)), color-stop(100%, rgba(82,82,82,1)));
  background: -webkit-linear-gradient(top, rgba(82,82,82,1) 0%, rgba(82,82,82,1) 1%, rgba(146,146,146,1) 45%, rgba(146,146,146,1) 55%, rgba(82,82,82,1) 100%);
  background: -o-linear-gradient(top, rgba(82,82,82,1) 0%, rgba(82,82,82,1) 1%, rgba(146,146,146,1) 45%, rgba(146,146,146,1) 55%, rgba(82,82,82,1) 100%);
  background: -ms-linear-gradient(top, rgba(82,82,82,1) 0%, rgba(82,82,82,1) 1%, rgba(146,146,146,1) 45%, rgba(146,146,146,1) 55%, rgba(82,82,82,1) 100%);
  background: linear-gradient(to bottom, rgba(82,82,82,1) 0%, rgba(82,82,82,1) 1%, rgba(146,146,146,1) 45%, rgba(146,146,146,1) 55%, rgba(82,82,82,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#525252', endColorstr='#525252', GradientType=0 );
}
