.EventParametersTable {
  padding-top: 8px;

  .editable-content-container {
    display: block;
  }

  .editable-content-string {
    max-width: none;
    display: block;
  }

}
