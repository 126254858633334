.NotifBlock {

  .md-menu {
    padding: 0;
  }

  .md-button {
    padding: 0 7px;
    margin: 0;
    height: $footer-height;
    line-height: $footer-height;

    svg {
      width: 22px;
      height: 22px;
      fill: white;
    }
  }

  .VerticalSeparator {
    margin: 0;
  }

  pm-language-switch {
    button {
      padding: 0 8px;
      min-width: 0;
    }
    svg, span {
      color: white;
      fillColor: white;
    }
  }
}
