.Form {
  .Form-validationError {
    position: relative;
    overflow: hidden;

    &.ng-enter, &.ng-leave {
      transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    }

    &.ng-enter {
      margin-top: -14px;
      opacity: 0;
    }

    &.ng-enter.ng-enter-active {
      margin-top: 0;
      opacity: 1;
    }

    &.ng-leave {
      margin-top: 0;
      opacity: 1;
    }

    &.ng-leave.ng-leave-active {
      margin-top: -14px;
      opacity: 0;
    }

    span {
      color: rgb(221,44,0);
      font-size: 12px;
    }
  }
}
