$menu-color: #292D35;

$color-base: #525252;
$color-base-2: #4E4E4E;
$color-base-3: #505050;

$color-accent: #2196f3;
$color-warn: #f44336;
$color-primary: #505050;

$color1: #FFFFFF;
$color2: #464646;
$color3: #448aff;
$color4: #444444;
$color6: #BFBFBF;
$color7: #F1F1F1;
$color8: #626262;
$color9: #EEEEEE;
$color10: #E3E3E3;
$color11: #ECECEC;
$color12: #FF5252;
$color16: #9E9E9E;
$color17: #F1F1F1;
$color18: #ECEBEB;

$color20: #0C4B7D;
$color21: #BDBDBD;
$color-table-blue: #B3C4FF;
$color-table-blue-alt: #E5EBFF;
$color-table-yellow: #ffec94;
$color-table-yellow-alt: #fcf9d2;
$color-table-red: #FD8C8C;
$color-table-red-alt: #FBC9C9;

$color-green: #4aa81b;

$black-01: rgba(0, 0, 0, 0.01);
$black-03: rgba(0, 0, 0, 0.03);
$black-12: rgba(0, 0, 0, 0.12);
$black-20: rgba(0, 0, 0, 0.2);
$black-40: rgba(0, 0, 0, 0.4);
$black-65: rgba(0, 0, 0, 0.65);
$black-75: rgba(0, 0, 0, 0.75);
$black-87: rgba(0, 0, 0, 0.87);

$brown: rgba(165, 42, 42, 1);

$sidebar-width: 170px;
$compact-sidebar-width: 100px;
$footer-height: 3.4rem;


$font-size-0: 1.5rem;
$font-size-1: 1.45rem;
$font-size-2: 1.4rem;
$font-size-3: 1.35rem;
$font-size-4: 1.25rem;
$font-size-5: 1.2rem;
$font-size-6: 1.1rem;
$font-size-7: 1rem;
