.Progress {
  position: relative;
  max-width:100%;
  overflow: hidden;

  .Progress-bar {
    z-index: 78;
    width: 0%;
    height: 3px;
    background: $color3;
    -moz-border-radius: 1px;
    -webkit-border-radius: 1px;
    border-radius: 1px;
    -moz-transition: width 500ms ease-out, opacity 400ms linear;
    -ms-transition: width 500ms ease-out, opacity 400ms linear;
    -o-transition: width 500ms ease-out, opacity 400ms linear;
    -webkit-transition: width 500ms ease-out, opacity 400ms linear;
    transition: width 500ms ease-out, opacity 400ms linear;

    dd, dt {
      position: absolute;
      top: 0;
      height: 3px;
      -moz-border-radius: 100%;
      -webkit-border-radius: 100%;
      border-radius: 100%;
    }

     dt {
      opacity: .6;
      width: 180px;
      right: -80px;
      clip: rect(-6px, 90px, 14px, -6px);
    }

     dd {
      opacity: .6;
      width: 20px;
      right: 0;
      clip: rect(-6px, 22px, 14px, 10px);
    }
  }
}
