.Search {
  font-size: 0.8em;

  .mat-form-field-label {
    opacity: 0.35;
  }

  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 1.05em;
  }

}




