.crm-calendar-header-inner {
  display: table-cell;
  vertical-align: middle;
}

.crm-calendar-header-customers {
  width: 149px;
}

.crm-calendar-header-events {
  width: 152px;
  left: 149px;
  border-left: none;
  border-right: 1px solid #ddd;
}

.CrmCalendar-Customers {
  line-height: 21px;
}

.crm-calendar-customer {
  border-bottom: 1px solid #ddd;
  position: relative;
  z-index: 1;

  &.current {
    color: $color20;
  }
}

.CrmCalendar-customer--title {
  display: block;
  display: -webkit-box;
  line-height: 28px;
  overflow: hidden;
  -webkit-box-orient: vertical;
}
.crm-calendar-customer-event-types {

}
.crm-calendar-customer-event-type {
  text-align: left;
  line-height: 28px;
  vertical-align: middle;
  border-bottom: 1px solid #ddd;
}
.crm-calendar-customer-event-type:last-child {
  border-bottom: none;
}
.CrmCalendar-Content {
  position: absolute;
  top: 0;
  right: -1px;
}
.CrmCalendar-Canvas {
  position: absolute;
  top: 23px;
  height: 200px;
  width: 100%;
  overflow: hidden;
  background-color: rgba(0,0,0,0.03);
}

.crm-calendar-content-header {
  position: absolute;
  top: 0;
  height: 44px;
  width: 100%;
}

.CrmCalendar-Ruler {
  position: absolute;
  right: 0;
  text-align: center;
  background-color: rgba(0,0,0,0.03);
}
.crm-calendar-ruler-block {
  width: 80px;
  display: inline-block;
  height: 24px;
  line-height: 24px;
  vertical-align: middle;
  font-weight: bold;
  color: #888;
}
.crm-calendar-ruler-block.active {
  color: #000;
}

.crm-calendar-drag-container {
  position: absolute;
  width: 100%;
  height: 100%;
}
.crm-calendar-columns {
  position: absolute;
  height: 100%;
  cursor: move;
}
.crm-calendar-column {
  position: absolute;
  border-right: 1px dotted #ddd;
  height: 100%;
}
.crm-calendar-column-header {
  background: #F0F3F5;
  color: #555;
  text-align: center;
  height: 20px;
  line-height: 20px;
  vertical-align: middle;
  font-size: 12px;
  font-weight: bold;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.crm-calendar-event-gradient-container {

}
.crm-calendar-event-gradient-overlay {
  position: absolute;
  height: 100%;
  top: 0px;
  right: 0px;
  background: rgba(255, 255, 255, 0.7);

}

.CrmCalendar {
  position: relative;

  * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .CrmCalendar-Events {
    position: absolute;
    top: 21px;
  }

  pm-slider {
    width: 200px;
  }

  .CrmCalendar-Container {
    position: relative;
    border-top: 1px dashed #ddd;
    border-bottom: 1px dashed #ddd;

    button {
      box-shadow: none !important;
      background-color: transparent !important;
    }

    .CrmCalendar-Panel {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      box-shadow: 12px 0px 15px -12px #A0A0A0;

      .CrmCalendar-Panel--Header {
        height: 45px;
        border-bottom: 1px solid #ddd;
        font-weight: bold;
      }
    }

    .CrmCalendar-Header {
      border-bottom: 1px solid #ddd;
      font-weight: bold;
      text-align: center;
      height: 43px;
      position: absolute;
      display: table;
      color: rgba(0, 0, 0, 0.54);

      span {
        padding-left: 11px;
      }
    }

    .CrmCalendar-Event {
      position: absolute;
      width: 300px;
      background-color: #ddd;
      vertical-align: middle;
      border-radius: 3px;
      min-width: 30px;

      .CrmCalendar-Event--Resize---Left, .CrmCalendar-Event--Resize---Right {
        width: 12px;
        position: absolute;
        top: 0;
        bottom: 0;
        background-color: transparent;
        cursor: col-resize;
        z-index: 1;
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
        background: -webkit-radial-gradient(rgb(84,84,84) 18%, rgba(0,0,0,0) 18%), -webkit-radial-gradient(rgb(84,84,84) 18%, rgba(0,0,0,0) 18%), transparent;
        background: -moz-radial-gradient(rgb(84,84,84) 18%, rgba(0,0,0,0) 18%), -moz-radial-gradient(rgb(84,84,84) 18%, rgba(0,0,0,0) 18%), transparent;
        background: radial-gradient(rgb(84, 84, 84) 18%, rgba(0,0,0,0) 18%), radial-gradient(rgba(84,84,84,1) 18%, rgba(0,0,0,0) 18%), transparent;
        background-repeat: repeat;
        background-position: 0 0, 3px 3px;
        -webkit-background-origin: padding-box;
        background-origin: padding-box;
        -webkit-background-clip: border-box;
        background-clip: border-box;
        -webkit-background-size: 6px 6px;
        background-size: 6px 6px;
      }

      .CrmCalendar-Event--Resize---Left {
        left: 0;
      }

      .CrmCalendar-Event--Resize---Right {
        right: 0;
      }

      .CrmCalendar-Event--GradientContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        background: #A6D8FF;
      }

      .CrmCalendar-Event-TitleContainer {
        font-size: 10px;
        font-weight: bold;
        padding: 0 5px;
        position: absolute;
        left: 0;
        right: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &.movable {
          cursor: move;
        }
      }
    }

    .CrmCalendar-FilterPreview {
      font-size: 9px;
      font-weight: normal;
      color: #aaa;
    }
  }
}
