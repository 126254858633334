.Login {
  background: #fbfdff;
  height: 100vh;
}

.Login-logo {
  padding-top: 10px;
}

.Login-users {
}

.Login-users--user {
  background: #f1f1f1;
  border-radius: 3px;
  margin: 2px;
}

.Login-wrapper {
  background-color: #FFFFFF;
  padding: 42px 32px;
  //overflow: auto;
  box-shadow: 2px 4px 10px #dadada;
}

.Login-card {

  p {
    padding-left: 4px;
    font-size: $font-size-6;
    color: $color4;
  }
}
