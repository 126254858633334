html {
  font-size: 62.5%;
  line-height: 1.4;
}

body {
  font-size: $font-size-3;
  height: auto;
  position: static;
}

html, body {
  background-color: transparent;
  max-width: 100%;
  overflow-x: hidden;
}

md-content {
  background-color: #FFF;
}

a, a:not(.mat-tab-link) {
  color: #003d63;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.CursorLoading {
  cursor: wait!important;
}

.MainContent {
  display: flex;
  min-height: 100vh;
  max-height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  overflow: auto;
}

.PaddingLayout,
.PaddingLayout > * {
  padding: 8px;
}

.attention {
  font-weight: 500;
  font-size: 110%;
  min-width: 180px;
}

.Padding-24 { padding: 24px; }

.Padding-16 { padding: 16px; }

.Padding-8 { padding: 8px; }

.Padding-4 { padding: 4px; }

.Padding { padding: 8px; }

.LeftPadding { padding-left: 8px; }

.RightPadding { padding-right: 8px; }

.TopPadding { padding-top: 8px; }

.SidePadding {
  padding-left: 8px;
  padding-right: 8px;
}

.SidePadding-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.NoMargin { margin: 0!important; }

.BottomMargin { margin-bottom: 8px; }

.RightMargin { margin-right: 8px !important; }

.LeftMargin { margin-left: 8px !important; }

.ColorWhite { color: white; }

.ColorWhite fa { color: white; }

.ColorGray { color: gray; }

.ColorOrange { color: orange; }

.ColorPurple { color: #708; }

.ColorRed { color: $color-warn; }

.ColorGreen { color: $color-green; }

// Important for crm calendar
.clearfix:before, .clearfix:after {
  display: table;
  content: " ";
}

.clearfix:after {
  clear: both;
}

.Columns {
  > div {
    min-width: 200px;

    &[fxlayout="column"] {
      padding: 8px;
    }

  }
}

.SimpleAnimation {
  -webkit-transition: 0.2s linear all;
  transition: 0.2s linear all;
  opacity: 1;

  &.ng-hide {
    opacity: 0;
  }

  &.ng-hide-add, &.ng-hide-remove {
    display: block!important;
  }
}

.PriceWithButton {

  .PriceWithButton-Content {
    padding-left: 16px;

    .Button {
      margin: 0 0 0 3px;
      border-radius: 3px;
    }
  }

  .Price {
    padding-bottom: 0;
  }
}

.WarningColor {
  color: $color12;
}

.ButtonLeft {
  .md-button {
    margin: 0 10px 0 0;
  }
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

.color8 {
  color: $color8;
}

.Clickable {
  &:hover {
    cursor: pointer;
  }
}

.PointerEventsNone {
  pointer-events: none;
}

.Copyrights {
  position: absolute;
  padding: 0 0 2px 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  font-size: $font-size-7;
}

md-dialog.fullscreen-dialog {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  border-radius: 0;
}

pm-no-records {
  padding: 8px;
  color: $color8;
}

pm-navigation-tabs, pm-tabbed-states {
  md-tabs.md-hue-1.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]) {
    color: #848484;
  }
}

.Filters {

  md-input-container, .FormItem-container {
    margin: 8px 0 0;
    padding: 0;
  }

  .md-button.md-icon-button {
    margin: 0 4px 0 0;
  }
}


.demo-md-panel {
  min-height: 500px;
}

.demo-dialog-example {
  background: white;
  border-radius: 4px;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
  0 13px 19px 2px rgba(0, 0, 0, 0.14),
  0 5px 24px 4px rgba(0, 0, 0, 0.12);
  width: 500px;
}

.demo-dialog-content {
  padding: 0 15px;
  width: 100%;
}

.demo-dialog-content img {
  height: 300px;
  margin: auto;
}

.demo-dialog-button {
  width: 100%;
}

.demo-menu-example {
  background: white;
  border-radius: 4px;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
  0 13px 19px 2px rgba(0, 0, 0, 0.14),
  0 5px 24px 4px rgba(0, 0, 0, 0.12);
  width: 240px;
  height: 250px;
}

.demo-menu-item {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 48px;
  padding: 0 16px;
  position: relative;
  transition: background 0.15s linear;
  width: auto;
}

.demo-menu-item:hover,
.demo-menu-item:focus {
  background-color: rgb(238, 238, 238);
}

.demo-menu-item.selected {
  color: rgb(16, 108, 200);
}

.mat-drawer-content {
  position: absolute !important;
  left: 0 !important;;
  right: 0 !important;;
}

pm-app {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mat-sidenav-container {
  background-color: white !important;
}

ui-view-ng-upgrade, ui-view, pm-login {
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  box-sizing: border-box;
}

.Btn-actions pro-btn {
  margin: 0 2px;
}

hr {
  width:100%;
  height: 1px;
  border: none;
  color: rgba(0, 0, 0, 0.12);
  background-color: rgba(0, 0, 0, 0.12);
  margin: 0!important;
  padding: 0!important;
}

.DisplayNone { display: none !important; }

.VisibilityHidden { visibility: hidden!important; }

.PositionRelative { position: relative; }

.OverflowAuto { overflow: auto; }

.OverflowHidden { overflow: hidden; }

.DisableScroll {
  overflow: hidden!important;
  & * {
    overflow: hidden!important;
  }
}

.NoPadding { padding: 0 !important; }

.NoMargin { margin: 0 !important; }

.WordBreak { word-break: break-word; }

.WhiteSpacePreWrap { white-space: pre-wrap; }

.MarginAuto { margin: 0 auto; }

.Height100 { height: 100%; }

.Width100 { width: 100%; }

.FontBold { font-weight: bold; }

.CompactFormField {
  height: 42px!important;
  overflow: hidden!important;
}

.TextAlignCenter {
  text-align: center;
}

router-outlet {
  padding: 0!important;
}

.materialFormatsContainer {
  background: #f5f5f5;
  border-radius: 25px;
  padding: 10px 16px;
}

.InvoiceProgressBar {
  position: relative;
  width: 100%;
  height: 26px;
  background: #77b2ff;
}

.InvoiceProgressBarMeter {
  position: absolute;
  top: 0;
  height: 100%;
  background: #317cfa;
}

.InvoiceProgressBar span {
  position: absolute;
  color: white;
  width: 100%;
  height: 100%;
  line-height: 26px;
  text-align: center;
  font-weight: 500;
  text-shadow: 2px 2px 6px #000000;
}

.Text-Accent {
  color: $color-accent;
  padding-bottom: 2px;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.010em;
}

.Text-Warn {
  color: $color-warn;
  padding-bottom: 2px;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.010em;
}

.important-input {

  .mat-form-field-underline {
    background-color: $color-warn !important;
  }
}

mat-dialog-container {
  div[mat-dialog-content] {
    overflow: auto;
  }
}
pm-dashboard-emoji .emojiButton>button{
  font-size: 28px;
}

.parameterColor {

  .mat-input-element:disabled, .mat-disabled, .mat-form-field-infix, .mat-form-field-label {
    color: black;
    opacity: 1 !important;
  }

}

