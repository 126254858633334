.EntityPhotoRound {
  margin: 0 5px;

  img {
    border-radius: 30px;
  }

  svg {
    width: 50px;
    height: 50px;
    color: $color3;
  }

  md-icon {
    height: auto;
    width: auto;
  }

  .md-menu {
    padding: 0;
  }

  .md-button.md-fab {
    width: 50px;
    height: 50px;
    margin: 0;
    padding: 0;
    line-height: 50px;
    box-shadow: none;
  }

  .md-button:not([disabled]).md-fab.md-focused {
    box-shadow: none;
    background-color: transparent;
  }
}
