.Docs2 {
  position: fixed;
  left: -0px;
  top: -0px;
  max-width: 200px;
  font-size: 12px;
  z-index: 15000;
  background: $menu-color;
  border-radius: 8px;
  color: #eee;

  .Docs-Title {
    padding: 16px 16px 0;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
  }

  .Docs-Paragraph {
    padding: 16px 16px 0;
  }

  .Docs-Intro--Link {
    display: inline;
    color: $color-accent;
    text-decoration: underline;
    padding-bottom: 4px;
    text-align: center;


    &:hover {
      font-weight: bold;
    }
  }

}
