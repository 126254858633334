.Files {
  .Files-File {
    margin: 5px 0;

    .Files-File--Metadata {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .Files-File--Metadata---Name {
        margin: 0 0 4px 0;
        font-size: $font-size-2;
        font-weight: 500;
      }

      .Files-File--Metadata---UploadedAt {
        color: $color16;
        font-weight: 500;
      }
    }
  }

  pro-thumbnail {
    padding-right: 10px;

    img {
      @include border-radius(25px);
    }
  }
}
