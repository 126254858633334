.ContactsManager {
  margin-bottom: 20px;

  .md-avatar-icon {
    padding: 14px;
  }

  .md-avatar {
    width: 50px;
    height: 50px;
  }

  md-list {
    padding-top: 0;
  }

  .md-title {
    margin: 0;
  }
}
