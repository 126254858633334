.FilesList{

  .FilesList-File {

    .FilesList-File--Metadata {
      width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .FilesList-File--Metadata---Name {
        margin: 0 0 4px 0;
        font-size: $font-size-2;
        font-weight: 500;
      }

      .FilesList-File--Metadata---UploadedAt {
        color: $color16;
        font-weight: 500;
      }
    }
  }

  pro-thumbnail {
    padding-right: 10px;

    img {
      @include border-radius(25px);
    }
  }
}
