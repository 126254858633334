.Cal {
  border: 3px solid #DCDCDC;
}

.Cal-actions {
  padding: 12px;
  border-bottom: 1px solid #DCDCDC;

  md-select {
    margin: 0 0 6px 0;
  }

  .Editable.Switch {
    margin-bottom: 0;
    padding-bottom: 0;
    padding-left: 8px;
  }

  .md-button.md-icon-button {
    margin: 0;
  }
}

.Cal-rows {
  background-color: #E4E4E4;
}

.Cal-rows-row {
  padding: 0 4px;
  height: 70px;
  text-align: center;
}

.Cal-chart {
  background-color: #f6f6f6;
  font-family: 'Open Sans Regular';
}

.Cal-timeline {
  position: relative;
}

.Cal-timeline--item {
  position: absolute;
  background-color: #f6f6f6;
}

.Cal-timeline--item---date {
  padding-top: 2px;
  text-align: center;
  color: #A7A7A7;
  font-size: 17px;
  font-family: 'Open Sans Condensed';
}

.Cal-timeline--item---time {
  display: inline-block;
  text-align: center;
  font-size: $font-size-6;
}

.Cal-bounds {
  position: relative;
  overflow: hidden;
  cursor: move;
  background-repeat: no-repeat;
}

.Cal-canvas {
  width: 1px;
}

.Cal-item {
  position: absolute;
  height: 69px;
  cursor: pointer;
  color: white;

  &.is-drag-active {
    z-index: 10;
  }
}

.Cal-item--content {
  box-sizing: border-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  max-width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 5px;
  right: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  & > * {
    max-width: 100%;
    box-sizing: border-box;
  }
}

.Cal-item--rl, .Cal-item--rr {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.2);
  width: 5px;
  cursor: ew-resize;
}

.Cal-item--rl {
  top: 0;
  left: 0;
  bottom: 0;
}

.Cal-item--rr {
  top: 0;
  right: 0;
  bottom: 0;
}

.Cal-itemPlaceholder {
  position: absolute;
  height: 68px;
  background-color: rgba(0, 128, 255, 0.1);
  border: 1px solid rgb(0, 125, 175);
}

.Cal-tags {
  & > div {
    margin: 1px;
    padding: 0 2px;
    text-align: center;
    font-size: $font-size-7;
    @include border-radius(4px);
    border: 2px solid rgba(255, 255, 255, 0.5)
  }
}

.Cal-currentTimeBar {
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: rgba(0, 147, 255, 1);
}
