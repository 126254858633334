.FilePreview {
  cursor: pointer;
  background-color: #ffffff;
  width: 50px;
  height: 50px;

  &.is-rounded {
    border-radius: 25px;
  }
}
