.SubTabs {
  background: $black-01;

  md-pagination-wrapper {
    height: 44px;

    .md-tab {
      padding: 12px 20px;
      font-size: 12px;
      line-height: 20px;
    }
  }
}
