$tooltip-background: rgba(10, 10, 10, .9);
$tooltip-border-color: rgba(74, 74, 74, .9);
$tooltip-animation-time: 1.5s;

@mixin toolTipInitKeyframes () {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  45% {
    opacity: 0;
    visibility: hidden;
  }

  55% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes tooltipInit {
  @include toolTipInitKeyframes()
}

@-webkit-keyframes tooltipInit {
  @include toolTipInitKeyframes()
}


[data-tooltip]:not(.is-disabled),
[data-tooltip]:not(.is-loading),
[data-tooltip]:not([disabled]) {
  cursor: pointer;
  overflow: visible;
  position: relative
}

[data-tooltip]:not(.is-disabled)::after,
[data-tooltip]:not(.is-disabled)::before,
[data-tooltip]:not(.is-loading)::after,
[data-tooltip]:not(.is-loading)::before,
[data-tooltip]:not([disabled])::after,
[data-tooltip]:not([disabled])::before {
  box-sizing: border-box;
  color: #fff;
  display: inline-block;
  font-size: .9rem;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  visibility: hidden;
  z-index: 1020
}

[data-tooltip]:not(.is-disabled)::after,
[data-tooltip]:not(.is-loading)::after,
[data-tooltip]:not([disabled])::after {
  content: '';
  border-style: solid;
  border-width: 6px;
  border-color: $tooltip-border-color transparent transparent transparent;
  margin-bottom: -5px
}

[data-tooltip]:not(.is-disabled)::after,
[data-tooltip]:not(.is-loading)::after,
[data-tooltip]:not([disabled])::after {
  top: 0;
  right: auto;
  bottom: auto;
  left: 50%;
  margin-top: -5px;
  margin-right: auto;
  margin-bottom: auto;
  margin-left: -5px;
  border-color: $tooltip-border-color transparent transparent transparent
}

[data-tooltip]:not(.is-disabled)::before,
[data-tooltip]:not(.is-loading)::before,
[data-tooltip]:not([disabled])::before {
  background: $tooltip-border-color;
  border-radius: 2px;
  content: attr(data-tooltip);
  padding: .5rem 1rem;
  text-overflow: ellipsis;
  white-space: pre
}

[data-tooltip]:not(.is-disabled)::before,
[data-tooltip]:not(.is-loading)::before,
[data-tooltip]:not([disabled])::before {
  top: 0;
  right: auto;
  bottom: auto;
  left: 50%;
  top: 0;
  margin-top: -5px;
  margin-bottom: auto;
  -webkit-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%)
}

[data-tooltip]:not(.is-disabled).has-tooltip-bottom::after,
[data-tooltip]:not(.is-loading).has-tooltip-bottom::after,
[data-tooltip]:not([disabled]).has-tooltip-bottom::after {
  top: auto;
  right: auto;
  bottom: 0;
  left: 50%;
  margin-top: auto;
  margin-right: auto;
  margin-bottom: -5px;
  margin-left: -5px;
  border-color: transparent transparent $tooltip-border-color transparent
}

[data-tooltip]:not(.is-disabled).has-tooltip-bottom::before,
[data-tooltip]:not(.is-loading).has-tooltip-bottom::before,
[data-tooltip]:not([disabled]).has-tooltip-bottom::before {
  top: auto;
  right: auto;
  bottom: 0;
  left: 50%;
  margin-top: auto;
  margin-bottom: -5px;
  -webkit-transform: translate(-50%, 100%);
  transform: translate(-50%, 100%)
}

[data-tooltip]:not(.is-disabled).has-tooltip-left::after,
[data-tooltip]:not(.is-loading).has-tooltip-left::after,
[data-tooltip]:not([disabled]).has-tooltip-left::after {
  top: auto;
  right: auto;
  bottom: 50%;
  left: 0;
  margin-top: auto;
  margin-right: auto;
  margin-bottom: -6px;
  margin-left: -11px;
  border-color: transparent transparent transparent $tooltip-border-color
}

[data-tooltip]:not(.is-disabled).has-tooltip-left::before,
[data-tooltip]:not(.is-loading).has-tooltip-left::before,
[data-tooltip]:not([disabled]).has-tooltip-left::before {
  top: auto;
  right: auto;
  bottom: 50%;
  left: -11px;
  -webkit-transform: translate(-100%, 50%);
  transform: translate(-100%, 50%)
}

[data-tooltip]:not(.is-disabled).has-tooltip-right::after,
[data-tooltip]:not(.is-loading).has-tooltip-right::after,
[data-tooltip]:not([disabled]).has-tooltip-right::after {
  top: auto;
  right: 0;
  bottom: 50%;
  left: auto;
  margin-top: auto;
  margin-right: -11px;
  margin-bottom: -6px;
  margin-left: auto;
  border-color: transparent $tooltip-border-color transparent transparent
}

[data-tooltip]:not(.is-disabled).has-tooltip-right::before,
[data-tooltip]:not(.is-loading).has-tooltip-right::before,
[data-tooltip]:not([disabled]).has-tooltip-right::before {
  top: auto;
  right: -11px;
  bottom: 50%;
  left: auto;
  margin-top: auto;
  -webkit-transform: translate(100%, 50%);
  transform: translate(100%, 50%)
}

[data-tooltip]:not(.is-disabled).has-tooltip-multiline::before,
[data-tooltip]:not(.is-loading).has-tooltip-multiline::before,
[data-tooltip]:not([disabled]).has-tooltip-multiline::before {
  height: auto;
  width: 15rem;
  max-width: 15rem;
  text-overflow: clip;
  white-space: normal;
  word-break: keep-all
}

[data-tooltip]:not(.is-disabled).has-tooltip-black.has-tooltip-bottom::after,
[data-tooltip]:not(.is-loading).has-tooltip-black.has-tooltip-bottom::after,
[data-tooltip]:not([disabled]).has-tooltip-black.has-tooltip-bottom::after {
  border-color: transparent transparent $tooltip-background transparent
}

[data-tooltip]:not(.is-disabled).has-tooltip-black.has-tooltip-left::after,
[data-tooltip]:not(.is-loading).has-tooltip-black.has-tooltip-left::after,
[data-tooltip]:not([disabled]).has-tooltip-black.has-tooltip-left::after {
  border-color: transparent transparent transparent $tooltip-background
}

[data-tooltip]:not(.is-disabled).has-tooltip-black.has-tooltip-right::after,
[data-tooltip]:not(.is-loading).has-tooltip-black.has-tooltip-right::after,
[data-tooltip]:not([disabled]).has-tooltip-black.has-tooltip-right::after {
  border-color: transparent $tooltip-background transparent transparent
}

[data-tooltip]:not(.is-disabled).has-tooltip-black:not(.has-tooltip-bottom)::after,
[data-tooltip]:not(.is-disabled).has-tooltip-black:not(.has-tooltip-left)::after,
[data-tooltip]:not(.is-disabled).has-tooltip-black:not(.has-tooltip-right)::after,
[data-tooltip]:not(.is-loading).has-tooltip-black:not(.has-tooltip-bottom)::after,
[data-tooltip]:not(.is-loading).has-tooltip-black:not(.has-tooltip-left)::after,
[data-tooltip]:not(.is-loading).has-tooltip-black:not(.has-tooltip-right)::after,
[data-tooltip]:not([disabled]).has-tooltip-black:not(.has-tooltip-bottom)::after,
[data-tooltip]:not([disabled]).has-tooltip-black:not(.has-tooltip-left)::after,
[data-tooltip]:not([disabled]).has-tooltip-black:not(.has-tooltip-right)::after {
  border-color: $tooltip-background transparent transparent transparent
}

[data-tooltip]:not(.is-disabled).has-tooltip-black:before,
[data-tooltip]:not(.is-loading).has-tooltip-black:before,
[data-tooltip]:not([disabled]).has-tooltip-black:before {
  background-color: $tooltip-background;
  color: #fff
}

[data-tooltip]:not(.is-disabled).has-tooltip-active::after,
[data-tooltip]:not(.is-disabled).has-tooltip-active::before,
[data-tooltip]:not(.is-disabled):focus::after,
[data-tooltip]:not(.is-disabled):focus::before,
[data-tooltip]:not(.is-disabled):hover::after,
[data-tooltip]:not(.is-disabled):hover::before,
[data-tooltip]:not(.is-loading).has-tooltip-active::after,
[data-tooltip]:not(.is-loading).has-tooltip-active::before,
[data-tooltip]:not(.is-loading):focus::after,
[data-tooltip]:not(.is-loading):focus::before,
[data-tooltip]:not(.is-loading):hover::after,
[data-tooltip]:not(.is-loading):hover::before,
[data-tooltip]:not([disabled]).has-tooltip-active::after,
[data-tooltip]:not([disabled]).has-tooltip-active::before,
[data-tooltip]:not([disabled]):focus::after,
[data-tooltip]:not([disabled]):focus::before,
[data-tooltip]:not([disabled]):hover::after,
[data-tooltip]:not([disabled]):hover::before {
  opacity: 1;
  visibility: visible;
  animation: tooltipInit $tooltip-animation-time;
  -webkit-animation: tooltipInit $tooltip-animation-time;
}

[data-tooltip]:not(.is-disabled).has-tooltip-fade::after,
[data-tooltip]:not(.is-disabled).has-tooltip-fade::before,
[data-tooltip]:not(.is-loading).has-tooltip-fade::after,
[data-tooltip]:not(.is-loading).has-tooltip-fade::before,
[data-tooltip]:not([disabled]).has-tooltip-fade::after,
[data-tooltip]:not([disabled]).has-tooltip-fade::before {
  animation: tooltipInit $tooltip-animation-time;
  -webkit-animation: tooltipInit $tooltip-animation-time;
}
