.Expression {

  width: 400px;

  md-input-container {
    width: 50px;
    padding-bottom: 6px;
  }

  .List {
    .md-button {
      min-height: 1.6rem;
      padding: 0.2rem;
      line-height: 1.6rem;

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}
