.Welcome {
  background-color: $color11;

  md-menu {
    padding: 0;
  }

  md-input-container {
    padding-bottom: 12px;
  }

  .Welcome-Form {
    position: absolute;
    z-index: 300;
    top: 10%;
    right: 10%;
    width: 220px;
    background-color: $color1;

    form {
      margin-left: 8px;
      margin-bottom: 16px;
    }

    h2 {
      margin: 0;
      padding-left: 4px;
    }

    .md-button {
      margin: 0;
    }
  }

  .Welcome-MainLogo {
    position: absolute;
    margin-left: -75px;
    top: 10%;
    left: 50%;
    z-index: 1;

    &.small {
      width: 100px;
      top: 25%;
      margin-left: -50px;
    }
  }

  .Welcome-Header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;

    .md-button {
      margin-right: 10px;
      color: $color1;
      font-size: $font-size-4;
    }

    &.noBlocks {
      .Welcome-Header--Toolbar {
        padding: 18px 40px;
        background: rgb(198, 210, 222);
      }
    }
  }

  .Welcome-Rows {
    .Welcome-Rows--Row {
      position: relative;

      .Welcome-Rows--Row---Content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: 0 20px;
        z-index: 1;

        .md-button {
          color: $color1;
          font-size: $font-size-4;
        }

        img {
          max-height: 150px;
        }
      }

      &.isImages {
        background: $color11;

        .Welcome-Rows--Row---Content {
          background: rgb(198, 210, 222);
          position: relative;
        }
      }

      &.isVideo {
        .Welcome-Rows--Row--Content---SmallLogo {
          padding-top: 30px;
          padding-bottom: 30px;
          padding-left: 25px;
        }
      }
    }
  }
}

.LoginForm {
  background-color: $color1;
}
