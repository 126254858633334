.MonthView {
  margin: 0 0 18px 0;

  .MonthView-date {
    display: inline-block;
    width: 20px;
    height: 20px;
    padding: 8px;
    @include border-radius(50%);
    text-align: center;
    cursor: pointer;
    border: 2px solid #ffffff;

    &.current {
      border-color: $color3;
    }
  }

  table {
    width: 100%;
    padding: 8px 0;
  }

  td {
    text-align: center;
  }
}
