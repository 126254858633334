.DPTasks {
  .md-checked {

    .md-label {
      text-decoration: line-through;
    }
  }

  md-checkbox {
    margin: 8px;
  }
}
