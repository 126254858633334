.CalendarOverlayFiles {
  max-width: 300px;

  .CalendarOverlayFiles-Item {
    float: left;
    padding: 2px;
  }

  .CalendarOverlayFiles-Clear {
    clear: both;
  }
}
