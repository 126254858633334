.ProductionCalendar {
  button {
    background-color: transparent !important;
    box-shadow: none !important;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}
