.AccountCategory--Header {
  pro-btn {
    .mat-mini-fab {
      height: 32px;
      margin-top: -7px;
    }
  }
}

// froala adjustments
@keyframes froalaToolbarEntry {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 50px;
  }
}

pm-html-txt .fr-toolbar {
  border-top: 2px solid #666;
  animation: 0.25s ease-out 0s 0.5 froalaToolbarEntry;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.time-vis{
    mat-form-field {
        min-width: 7.5em;
    }
}

pm-articles-tests{
    [data-name="value"]{
        min-width: 6em;
    }
}
// force landscape on mobiles
/*
@media screen and (min-width: 320px) and (max-width: 575px) and (orientation: portrait) {
  html.MobileDevice {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}
*/

//h1.hc-reform + p {
//  clear:both;
//}

//.MainContent > router-outlet + * {
//  overflow: auto;
//}
