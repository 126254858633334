.DashboardsGrid {
  margin: -16px;

  .DashboardsGrid-Column {

    md-card {
      margin: 0;
    }

    .DashboardsGrid-Column--WidgetConfig {
      margin: 0 8px;

      .md-button {
        margin: 8px 0 0 0;
      }
    }

    &.edit-mode {
      min-height: 100px;
      border: 3px dashed $color16;

      md-card {
        box-shadow: none;
      }
    }

    .as-sortable-placeholder {
      background-color: $color11;
    }

    .DashboardsGrid-Column--Title {
      margin: 16px 8px 0 8px;
      font-size: $font-size-0;
      font-weight: 500;
    }
  }
}
