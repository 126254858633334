pro-thumbnail {
  img, md-icon {
    cursor: pointer;
  }

  md-icon {
    width: 50px;
    height: 50px;
  }

  img {
    @include border-radius(50%);
  }


}
