button.BtnIcon {
  height: 32px;
  width: 32px;
  border: none;
  padding-top: 7px;
  background: transparent;

  fa {
    position: relative;
    width: 100%;
    height:100%;

    i {
      position: relative;
      width: 100%;
      height:100%;
    }

  }
}

.fa-2x {
  font-size: 1.5em;
}

.fa-1p3x {
  font-size: 1.3em;
}

/*pm-table .EntityTable-textIcon-container,
pm-table pm-tags{
  .fa-2x {
    font-size: 1.45em;
  }
}*/

button {
  &.accent fa { color: $color-accent; }

  &.warn fa { color: $color-warn; }

  &.primary fa , &._black fa { color: $color-primary; }

  &.grey fa { color: $color16; }

  &.green fa { color: #00C382; }

  &.white fa { color: white; }

  &[disabled] fa {
    color: rgba(0,0,0,0.25) !important;
  }

}


.Btn-actions {

  font-size: 16px;

  .fa-2x {
    font-size: 1.5em;
  }

  button.BtnIcon {
    margin: 0 2px;
  }

}

.MoveHandle {
  padding: 4px 16px;
}
