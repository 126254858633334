.TimelineChat {

  .TimelineChat-Header {
    padding: 0 8px;
  }

  .TimelineChat-Messages {
    padding: 0 20px 20px 20px;

    .TimelineChat-Messages--Container {
      max-height:500px;
      overflow: auto;
      overflow-x: visible;

      .TimelineChat-Messages--Container---Message {

        .TimelineChat-Messages--Container---Message----Header {
          min-height: 40px;
          color: $color8;
          font-weight: 500;
          font-size: $font-size-4;
        }

        .TimelineChat-Messages--Container---Message----Text {
          margin-top: 5px;
          overflow-wrap: break-word;
          cursor: pointer;
          white-space: pre-wrap;
        }

        .TimelineChat-Messages--Container---Message----Reply {
          margin-top: 5px;
          overflow-wrap: break-word;
          cursor: pointer;
          white-space: pre-wrap;
          color: #b9b9b9;
        }

      }

    }

    .TimelineChat-Input {

      textarea {
        line-height: normal;
        resize: none;
      }
    }

  }

  &.Fixed {
    position: fixed;
    width: 500px;
    top: 0;
    right: -550px;
    height: 100vh;
    background: rgba(240,240,240,0.9);
    border-left:2px solid black;
    z-index: 10;

    transition: right 300ms ease;

    &.Open {
      right: 0;
    }

  }

}
