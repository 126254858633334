$dark-mode-background : #555;
$dark-mode-color: #bbb;

.dark-mode {

  .MainContent {
    background: $dark-mode-background;
    color: $dark-mode-color;

  }

  .mat-expansion-panel {
    background: $dark-mode-background;
  }

  .mat-expansion-panel, .mat-expansion-panel-header-title {
    color: $dark-mode-color;
  }

  .mat-form-field {
    color: $dark-mode-color;
  }

  .mat-form-field-underline {
    background-color: $color16 !important;
  }

  .mat-form-field-label {
    color: #888;
  }

  button {
    color: $dark-mode-color;

    &.primary fa, &._black {
      color: $dark-mode-color;
    }

    &.warn fa {
      color: #ff857c;
    }
  }
  .mat-warn {
    color: #ff857c;
  }


  .mat-input-element:disabled, .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after, .mat-select-disabled .mat-select-value {
    color: rgba(200, 200, 200, 0.28);
  }

  hr {
    background-color: $dark-mode-color!important;
  }

  .Editable {
    margin-bottom: 16px;

    .Editable-Label, .Editable-CustomLabel {
      color: #888;
    }

    &.Editable-Disabled {

      .Editable-Item {
        color: $dark-mode-color;
      }
    }

  }

  .Table {

    thead, .Table-Filters  {
      background: $dark-mode-background!important;
      color: $dark-mode-color;

      th, td {
        background: $dark-mode-background;
        color: $dark-mode-color;
      }
    }

    tbody {
      > tr:hover, > tr.marked {
        -webkit-transition: background-color 100ms linear;
        -moz-transition: background-color 100ms linear;
        -o-transition: background-color 100ms linear;
        -ms-transition: background-color 100ms linear;
        transition: background-color 100ms linear;
        background-color: #333;
        color: #ddd;
      }

      td {
        span {
          white-space: nowrap;
        }
      }

    }

    tbody, tfoot, thead {
      //color: $;

      tr {
        border-bottom: 1px solid $color16;
        vertical-align: middle;
      }

      tr > th, tr > td {
        padding: 0 4px;
        line-height: 1.42857143;
        overflow: hidden;
      }
    }

    &.stripped {
      tbody {
        color: rgba(205, 205, 205, 0.86);
        > tr:nth-child(odd) > td,  > tr:nth-child(odd) > th {
          background-color: transparent;
        }

        > tr:nth-child(odd) {
          background-color: rgba(62, 62, 62, 0.86);

          &:hover {
            background-color: rgba(54, 54, 54, 0.86);

          }

        }

        > tr.green {
          background-color: rgba($green_row, 0.8);
          color: rgba(62, 62, 62, 0.86);

          &:hover {
            background-color: rgba($green_row, 0.5);
          }

          &:nth-child(odd) {
            background-color: rgba($green_row_odd, 0.8);
            color: rgba(62, 62, 62, 0.86);

            &:hover {
              background-color: rgba($green_row_odd, 0.5);
            }
          }
        }

        > tr.blue {
          background-color: $color-table-blue;
          color: rgba(62, 62, 62, 0.86);

          &:hover {
            background-color: $color9;
          }

          &:nth-child(odd) {
            background-color: $color-table-blue-alt;
          }
        }

        > tr.yellow {
          background-color: $color-table-blue;

          &:hover {
            background-color: $color9;
          }

          &:nth-child(odd) {
            background-color: $color-table-blue-alt;
          }
        }

        > tr.red {
          background-color: rgba($red_row, 0.8);
          color: rgba(62, 62, 62, 0.86);

          &:hover {
            background-color: rgba($red_row, 0.5);
          }

          &:nth-child(odd) {
            background-color: rgba($red_row_odd, 0.8);

            &:hover {
              background-color: rgba($red_row_odd, 0.5);
            }
          }
        }



        > tr.grey {
          background-color: rgba($grey_row, 0.1);

          &:hover {
            background-color: rgba($grey_row, 0.2);
          }

          &:nth-child(odd) {
            background-color: rgba($grey_row_odd, 0.1);

            &:hover {
              background-color: rgba($grey_row_odd, 0.2);
            }
          }
        }

      }
    }

  }

  .mat-toolbar.mat-primary {
    background: $dark-mode-background!important;
  }

  .Tabs, .mat-tab-label {
    background: #666;
    color: #ddd;
  }

  .Toolbar {
    margin: 0;
    white-space: nowrap;

    .Toolbar-wrapper {
      background: #666;
      color: #ddd;
    }

    .Toolbar-Subheader {
     color: $dark-mode-color;
      .grey {
       color: $dark-mode-color;
     }

   }

  }

  .mat-dialog-container {
    background: $dark-mode-background;
    color: $dark-mode-color;
  }

  .materialFormatsContainer {
    background: $dark-mode-background;
  }

  pm-dialog-title {
    h1 {
      color: #444!important;
    }

    .dialog-close::before, .dialog-close::after {
      background-color: #444!important;
    }
  }

  .pagination__item:not(.mat-tab-link) {
    background-color: $dark-mode-background!important;
  }

  .pagination__item--active:not(.mat-tab-link) {
    color: rgba(200, 200, 200, 0.28);
  }

  pm-no-records {
    color: rgba(200, 200, 200, 0.28);
  }

  .dark-theme.fr-box.fr-basic .fr-wrapper {
    background: $dark-mode-background;
  }

  .dark-theme.fr-box.fr-basic .fr-element {
    color: $dark-mode-color;
  }



  .mat-menu-content {
    background: $dark-mode-background;
  }

  .List-row {

    &:nth-of-type(odd) {
      background-color: #717171;
    }

    &:nth-of-type(even) {
      background-color: #5c5c5c;
    }

    &:hover {
      background-color: #505050;
    }
  }

  .mat-select-panel {
    background: $dark-mode-background;
    border: 1px solid $dark-mode-color;

    .mat-option-text {
      color: $dark-mode-color;
    }

  }

  .mat-select-value-text, .mat-select-arrow-wrapper, mat-pseudo-checkbox {
    color: $dark-mode-color;
  }

  .List {
    .Color {
      margin-bottom: 2px;
    }
  }

  .Calendar-Container .pane-header {
    color: $dark-mode-color;
  }

  a:link, a:visited, a:hover {
    color: $color-accent;
  }

  .TimelineChat {

    .TimelineChat-Messages {

      .TimelineChat-Messages--Container {

        .TimelineChat-Messages--Container---Message {

          .TimelineChat-Messages--Container---Message----Header {
            color: $dark-mode-color;
          }

        }

      }

    }

  }

  .List-row--extra, .List-row--title {
    color: $dark-mode-color;
  }
}

mat-autocomplete:after {
  color: $dark-mode-color;
}
