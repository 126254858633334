.ContentSlider {
  position: relative;

  .ContentSlider-Slide {
    width: 100%;
    height: auto;

    img {
      width: 100%;
      height: auto;
    }

    &.ng-hide-add, &.ng-hide-remove {
      display: block!important;
      -webkit-transition: all linear 1.5s;
      -moz-transition: all linear 1.5s;
      -o-transition: all linear 1.5s;
      transition: all linear 1.5s;
    }

    &.ng-hide-add.ng-hide-add-active {
      position: absolute;
    }

    &.ng-hide-add.ng-hide-add-active, &.ng-hide-remove {
      opacity: 0;
    }

    &.ng-hide-add, &.ng-hide-remove.ng-hide-remove-active {
      opacity: 1;
    }
  }

  .ContentSlider-Bubbles {
    position: absolute;
    bottom: 10%;
    left: 5%;
    z-index: 1;

    .ContentSlider-Bubbles--Bubble {
      display: inline-block;
      margin: 5px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 3px solid $color1;
      cursor: pointer;

      &.active {
        background-color: $color1;
      }
    }
  }
}
