.operation.link-hover-operation {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.075), 0rem 0rem 0.8rem rgba(102, 175, 233, 0.6);
  box-shadow: inset 0rem 0.1rem 0.1rem rgba(0, 0, 0, 0.075), 0rem 0rem 0.8rem rgba(102, 175, 233, 0.6);
}

.operation.link-target-operation,
.operation.action-target,
.operation-placeholder.action-target {
  border-color: #0eac00;
  outline: 0;
  -webkit-box-shadow: inset 0rem 0.1rem 0.1rem rgba(0, 0, 0, 0.075), 0rem 0rem 0.8rem rgba(102, 175, 233, 0.6);
  box-shadow: inset 0rem 0.1rem 0.1rem rgba(0, 0, 0, 0.075), 0rem 0rem 0.8rem rgba(69, 236, 156, 0.6);
}
