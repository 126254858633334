.UserContactsCard {
  margin: 15px 0;

  .UserContactsCard-Details {
    padding-top: 5px;
  }

  h3 {
    margin: 0;
    font-weight: 500;
  }

  h4 {
    margin: 0;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.5);
  }

  p {
    margin: 0;
    color: rgba(0, 0, 0, 0.5);
  }

  .UserContactsCard-Languages {
    margin-bottom: 0;
    padding: 0;

    .Editable-Label {
      padding-left: 0;
    }

    img {
      margin-top: 3px;
      margin-right: 5px;
    }
  }

  .UserContactsCard-Skype {
    margin-bottom: 0;
    padding: 0;

    .Editable-Label {
      padding-left: 0;
    }

    a {
      margin: 5px 5px 0 0;
    }
  }
}
