.Overlay {
  position: absolute;
  z-index: 30000;
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 1.2rem;
  pointer-events: none;
  font-size: 11px;
  min-width: 250px;
  -webkit-transition: 0.3s linear opacity;
  -moz-transition: 0.3s linear opacity;
  -ms-transition: 0.3s linear opacity;
  -o-transition: 0.3s linear opacity;
  transition: 0.3s linear opacity;

  &.ng-enter {
    opacity: 0;
  }

  &.ng-enter.ng-enter-active {
    opacity: 1;
  }

  &.ng-leave {
    display: none;
  }

  &.ng-leave.ng-leave-active {
    display: none;
  }
}


.Overlay2 {
  position: absolute;
  z-index: 30000;
  background-color: rgba(255, 255, 255, 0.9);
  font-size: 1.2rem;
  pointer-events: none;
  font-size: 11px;
  min-width: 250px;

    left: -9999px;
    top: -9999px;

  &.NoMinWidth {
    min-width: initial;
  }

  .Overlay-img {
      max-width: 450px;
      //max-height: 450px;

      &> img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }

    }

  .Overlay-saleOpportunity {
    max-width: 400px;
  }

}
