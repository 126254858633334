.custom-animation {
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  -o-animation-duration: 2s;
  animation-duration: 2s;

  &.ng-enter {
    -webkit-animation-name: vanishIn;
    animation-name: vanishIn;
  }

  &.ng-leave {
    -webkit-animation-name: vanishOut;
    animation-name: vanishOut;
  }
}

@-webkit-keyframes vanishIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
    -webkit-transform: scale(2, 2);
    -webkit-filter: blur(90px);
  }

  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
    -webkit-filter: blur(0px);
  }
}

@keyframes vanishIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(2, 2);
    -webkit-filter: blur(90px);
  }

  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
    -webkit-filter: blur(0px);
  }
}

@-webkit-keyframes vanishOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
    -webkit-filter: blur(0px);
  }

  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
    -webkit-transform: scale(2, 2);
    -webkit-filter: blur(20px);
  }
}

@keyframes vanishOut {
  0% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
    -webkit-filter: blur(0px);
  }

  100% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(2, 2);
    -webkit-filter: blur(20px);
  }
}

.animate-if.ng-enter, .animate-if.ng-leave {
  -webkit-transition: 0.5s linear opacity;
  -moz-transition: 0.5s linear opacity;
  -ms-transition: 0.5s linear opacity;
  -o-transition: 0.5s linear opacity;
  transition: 0.5s linear opacity;
}

.animate-if.ng-enter {
  opacity: 0;
}

.animate-if.ng-enter.ng-enter-active {
  opacity:1;
}

.animate-if.ng-leave {
  display: none;
}

.animate-if.ng-leave.ng-leave-active {
  display: none;
}

.box-one.ng-hide-add {
  -webkit-animation: 0.5s hide;
  animation: 0.5s hide;
}

@keyframes hide {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  30% {
    transform: scale(1.02);
  }
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
}

@-webkit-keyframes hide {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  30% {
    transform: scale(1.02);
  }
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
}


.box-one.ng-hide-remove {
  -webkit-animation: 0.3s show;
  animation: 0.3s show;
}

@keyframes show {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  70% {
    transform: scale(1.02);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes show {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  70% {
    transform: scale(1.02);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%, 20% {
    -webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
  }

  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%, 60%, 80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%, 20% {
    -webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
  }

  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%, 60%, 80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@-webkit-keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

@keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

.animated-list {
  &.ng-enter {
    -webkit-transition: 0.3s;
    transition: 0.3s;
    overflow: hidden;

    margin-left: 100%;
  }

  &.ng-enter-active {
    margin-left: 0;
  }

  &.ng-leave {
    -webkit-transition: 0.3s;
    transition: 0.3s;
    overflow: hidden;

    margin-left: 0;
  }

  &.ng-leave-active {
    margin-left: 100%;
  }
}

.animated-height {
  &.ng-enter {
    display: block;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    overflow: hidden;

    max-height: 0;
  }

  &.ng-enter-active {
    max-height: 999px;
  }

  &.ng-leave {
    display: block;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    overflow: hidden;

    max-height: 999px;
  }

  &.ng-leave-active {
    max-height: 0;
  }
}

.animateShowHeight {

  &.ng-hide-remove {
    max-height: 0px;
    opacity: 0;
  }

  &.ng-hide-remove-active {
    display: block;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    overflow: hidden;

    max-height: 999px;
    opacity: 1;
  }

  //&.ng-hide-add {
  //  display: block;
  //  -webkit-transition: 0.3s;
  //  transition: 0.3s;
  //  overflow: hidden;
  //
  //  max-height: 999px;
  //}
  //
  //&.ng-hide {
  //  max-height: 0;
  //}
}

.horizontal-slide {

  &.ng-enter {
    position: absolute;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    transform: translate(-100%, 0%);
  }

  &.ng-enter-active {
    width: 100%;
    transform: translate(0%, 0%);
  }

  &.ng-leave {
    position: absolute;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    transform: translate(0%, 0%);
  }

  &.ng-leave-active {
    width: 100%;
    transform: translate(100%, 0%);
  }
}

.slideUpRetourn {
  &.ng-enter {
    -webkit-animation-name: slideUpRetourn;
    animation-name: slideUpRetourn;
    -webkit-animation-duration: 0.5s;
    -moz-animation-duration: 0.5s;
    -o-animation-duration: 0.5s;
    animation-duration: 0.5s;
  }

  &.ng-leave {
    -webkit-animation-name: slideUp;
    animation-name: slideUp;
    -webkit-animation-duration: 0.5s;
    -moz-animation-duration: 0.5s;
    -o-animation-duration: 0.5s;
    animation-duration: 0.5s;
  }
}

@-webkit-keyframes tinUpIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(1, 1) translateY(-900%);
  }

  50%, 70%, 90% {
    opacity: 1;
    -webkit-transform: scale(1.1, 1.1) translateY(0);
  }

  60%, 80%, 100% {
    opacity: 1;
    -webkit-transform: scale(1, 1) translateY(0);
  }
}

@keyframes tinUpIn {
  0% {
    opacity: 0;
    transform: scale(1, 1) translateY(-900%);
  }

  50%, 70%, 90% {
    opacity: 1;
    transform: scale(1.1, 1.1) translateY(0);
  }

  60%, 80%, 100% {
    opacity: 1;
    transform: scale(1, 1) translateY(0);
  }
}

@-webkit-keyframes tinUpOut {
  0%, 20%, 40%, 50% {
    opacity: 1;
    -webkit-transform: scale(1, 1) translateY(0);
  }

  10%, 30% {
    opacity: 1;
    -webkit-transform: scale(1.1, 1.1) translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1, 1) translateY(-900%);
  }
}

@keyframes tinUpOut {
  0%, 20%, 40%, 50% {
    opacity: 1;
    transform: scale(1, 1) translateY(0);
  }

  10%, 30% {
    opacity: 1;
    transform: scale(1.1, 1.1) translateY(0);
  }

  100% {
    opacity: 0;
    transform: scale(1, 1) translateY(-900%);
  }
}

@-webkit-keyframes slideUpRetourn {
  0% {
    -webkit-transform-origin: 0 0;
    -webkit-transform: translateY(-100%);
  }

  100% {
    -webkit-transform-origin: 0 0;
    -webkit-transform: translateY(0%);
  }
}

@keyframes slideUpRetourn {
  0% {
    transform-origin: 0 0;
    transform: translateY(-100%);
  }

  100% {
    transform-origin: 0 0;
    transform: translateY(0%);
  }
}

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform-origin: 0 0;
    -webkit-transform: translateY(0%);
  }

  100% {
    -webkit-transform-origin: 0 0;
    -webkit-transform: translateY(-100%);
  }
}

@keyframes slideUp {
  0% {
    transform-origin: 0 0;
    transform: translateY(0%);
  }

  100% {
    transform-origin: 0 0;
    transform: translateY(-100%);
  }
}

.fade {

  &.ng-enter {
    transition: 0.3s linear all;
    opacity: 0;
  }

  &.ng-enter-active {
    opacity: 1;
  }
}

.spinner {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 40px;
  height: 40px;
  margin: 0;
  background-color: #0091FF;

  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% { -webkit-transform: scale(0) }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
}

.AppLoader { opacity: 1!important; }

.spinner2 .fill {
  background: $color3 !important;
}
