.compact-layout {

  .Menu-category--name {
    display: none;
  }

  .mat-drawer-inner-container {
    overflow: initial;
  }

  mat-sidenav {
    overflow: visible!important;
  }

  .Menu-tabs {
    position: absolute;
    left: 100%;
    padding: 10px 4px;
    top: 0;
    @include rgbaBackground($menu-color, 0.95);
    border-left: 1px solid;
    overflow: hidden;
  }

  .mat-button-wrapper {
    text-align: center;

    fa {
      font-size: 1.2em!important;
    }
  }

}
