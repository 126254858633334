.ArticlesExposure {

  .ArticlesExposure-Actions {
    min-height: 80px;
  }

  figcaption {
    width: 100%;
  }

  img, pm-image {
    width: 100%;
    height: 100%;
  }

  md-grid-list {
    md-grid-tile {
      .ArticlesExposure-AltName {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 48px;
        padding: 10px;
        background: rgba(255, 255, 255, 0.75);
      }

      md-grid-tile-footer {
        background: rgba(0, 0, 0, 0.55);

        h4 {
          font-size: 14px;
          font-family: 'Open Sans Condensed', sans-serif;
        }
      }
    }
  }

  md-grid-list {
    md-grid-tile {
      md-grid-tile-header {
        background: none;

        h3 {
          font-size: 18px;
          font-weight: bold;
          font-family: 'Open Sans Condensed', sans-serif;
        }
      }
    }
  }
}
