.FixedHeader {
  display: block;
  position: absolute;
  background-color: white;
  z-index: 9999;
  border-bottom: 4px solid #d9dee6;

  .EntityTable-multiselectcheckbox {
    display: none;
  }

  &.with-multiselect {
    .EntityTable-multiselectcheckbox {
      display: table-cell;
    }
  }


  .EntityTable-lineNumber {
    display: none;
  }

  .isLineNumbering {
    .EntityTable-lineNumber {
      display: table-cell;
    }
  }

    .Table thead {
        tr > th, tr > td {
            padding: 0;
        }
    }


}
