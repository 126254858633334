@import "variables";

.List {
  width: 100%;
  margin-bottom: 20px;

  .Color {
    margin-bottom: 2px;
    border-bottom: 0;

    .minicolors {
      margin: 0;
    }
  }
}

.List-header {

  h3 {
    margin: 12px 0;
  }
}

.List-subHeader {
  padding: 0 10px;
  color: $black-40;
}

.List-createdAt {
  padding-bottom: 4px;
  padding-left: 4px;
  font-size: $font-size-6;
  color: $color8;
}

.List-disabled {
  color: $color6;
}

.List-row:not(:empty) {
  border-top: 1px solid $color10;
  padding: 3px 10px 3px 10px;

  &:nth-of-type(odd) {
    background-color: #f9f9f9;
  }

  &:hover {
    background-color: $color9;
  }


}

.List-row--extra {
  color: $black-65;

  &.small {
    font-size: $font-size-5;
    font-style: italic;
  }
}

.List-row--title {
  padding-bottom: 8px;
  color: $black-65;
  font-weight: 300;
  font-size: $font-size-3;
}
