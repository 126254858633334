.CategoryRecursive {
  .CategoryRecursive-Item {
    margin-left: 25px;
    position: relative;
    border-left: 2px dashed $color6;
  }

  h3 {
    margin: 0;
    padding: 12px 0;
  }
}
