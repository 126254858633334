.WarningBlock {
  cursor: pointer;

  > div {
    padding: 8px 12px;
  }

  .WarningBlock-icon {
    background-color: #FFEAEA;
    
    > md-icon {
        width: 38px;
        height: 38px;
    }
  }

  .WarningBlock-content {
    background-color: #FFF7F7;
  }

  md-icon {
    color: #C33D3D;
  }

  md-card-content {
    padding: 0;
  }

  h3 {
    margin: 0;
    font-size: 24px;
    color: #C33D3D;
  }

  p {
    margin: 3px 0 0 0;
    text-transform: uppercase;
  }
}