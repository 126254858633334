.Workplace-plan {
    
  overflow: auto;

  .Workplace-plan-map {

    position: relative;

    .Workplace {

      position: absolute;
      width: 60px;
      height: 48px;

      .indicator {
        width: 48px;
        height: 48px;
        padding: 0;
        font-size: 20px;
      }

      .indicator.positive {
        background-image: url('/src/assets/images/workplace.gif');
        background-repeat: no-repeat;
        background-size: 48px 48px;
        background-position: center center;
      }

      .indicator.negative {
        background-color: #ff0000;
        color: #fff;
      }

      .indicator.neutral {
        background-color: #ffde00;
      }

      .md-subhead, md-card-content {
        font-size: 12px;
      }

      md-card-content .md-subhead {
        color: rgba(0,0,0,0.54);
      }

      .Workplace-remove {
        position: absolute;
        top: -24px;
        right: -36px;
      }

    }
  }
}



