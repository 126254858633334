.Translations {
  padding: 16px;

  .Translations-header {
    font-weight:700;
    font-size: $font-size-2;
  }

  .button-placeholder{
    visibility: hidden!important;
  }

}
