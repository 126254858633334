.Header {
  background-color: #bfbfbf !important;

  .mat-mini-fab {
    margin: 0 4px;
  }

  pm-search {
    position: relative;
    margin: -16px 16px;

    .mat-input-wrapper {
      padding-top: 0.3em;
    }

    .mat-input-infix {
      padding: 0 0 0.2em;
    }

    .mat-input-element, .mat-input-placeholder {
      font-size: 16px;
    }
  }

  .LayoutToggleButton {
    display: none;
  }

}
