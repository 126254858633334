@import '_reset';
@import 'libs/proman/src/lib/styles/variables';
@import 'libs/proman/src/lib/styles/mixins';
@import '_d3';

@import 'material-legacy';

//@import '~projects/proman/fa/fa6';
@import 'libs/proman/src/lib/fa/fa6';

@import '_animations';
@import 'libs/proman/src/lib/styles/material';
@import '_jquery_overrides';
@import '_print';
@import '_fonts';

@import 'editables/_common';
@import 'editables/_list';
@import 'editables/_datetime';
@import 'editables/_time';
@import 'editables/_text_html';
@import 'editables/_quantity';

@import 'components/_tabs';
@import 'components/_tool_btns';
@import 'components/_datetimepicker';
@import 'components/_calendar';
@import 'components/_calendar2';
@import 'components/_account';
@import 'components/_search';
@import 'components/custom_calendar';
@import 'components/_proman_block';
@import 'components/_editable_container';
@import 'components/_upload';
@import 'components/_navigation_header';
@import 'components/_entity_photo';
@import 'components/_login';
@import 'components/_user_contacts_card';
@import 'components/_overlay';
@import 'components/_min_editables';
@import 'components/_docs';
@import 'components/_calendar_overlay_files';
@import 'libs/proman/src/lib/styles/list';
@import 'components/_user_thumbnail';
@import 'components/_users_switcher';
@import 'components/_pagination';
@import 'components/_table_edit';
@import 'libs/proman/src/lib/styles/table';
@import 'components/_alert';
@import 'components/_success';
@import 'components/_content_slider';
@import 'components/_video_player';
@import 'components/_dialog_block';
@import 'components/_permissions';
@import 'components/_operations_manager';
@import 'components/_expression';
@import 'components/_header';
@import 'components/_inline_list';
@import 'components/_category_recursive';
@import 'components/_file_preview';
@import 'components/_crm_calendar';
@import 'components/_vertical_separator';
@import 'components/_notifications';
@import 'components/_order_operations';
@import 'components/_proman_list';
@import 'components/_worktime_schedule';
@import 'components/_links_manager';
@import 'components/_event_parameters_table';
@import 'components/_proman_uploader';
@import 'components/_click_to_call';
@import 'components/_entity_photo_round';
@import 'components/_notif_block';
@import 'components/_contacts_manager';
@import 'components/_sales_opportunities';
@import 'components/_tags';
@import 'components/_regions';
@import 'components/_files';
@import 'components/_thumbnail';
@import 'components/_layout';
@import 'components/_menu';
@import 'components/_timeline_chat';
@import 'components/_minilabel';
@import 'components/_time_graph';
@import 'components/_rounded';
@import 'components/_image_ruler';
@import 'components/_collapsible';
@import 'components/_files_manager';
@import 'components/_files_list';
@import 'components/_sub_tabs';
@import 'components/_validation_error';
@import 'components/_workplace';
@import 'components/_d3_tip';
@import 'components/_bar_chart';
@import 'components/_warning_block';
@import 'components/_form';
@import 'components/_agent_map';
@import 'components/_user_account_settings';
@import 'components/_stepper';
@import 'components/_cal';
@import 'libs/proman/src/lib/common-components/scss/warning';
@import 'components/_inline_form';
@import 'components/_preview';
@import 'components/_chart';
@import 'components/_bottom_toolbar';
@import 'components/_error';
@import 'components/_toolbar';
@import 'components/_expression_n';
@import 'components/_form_item';
@import 'components/_month_view';
@import 'components/_fixed_header.scss';
@import 'components/_zpl_template.scss';
@import 'components/_html_txt.scss';
@import 'components/_progress.scss';
@import 'components/_label.scss';
@import 'components/_production_calendar.scss';
@import 'components/_clipboard_copy.scss';
@import 'components/_entity_activity_log.scss';
@import 'components/_translations.scss';
@import 'components/_google_settings.scss';
@import 'components/_fa.scss';
@import 'components/_production_operation_links.scss';
@import 'autocomplete-caret';

@import 'libs/proman/src/lib/styles/entity_table/_index';
@import 'modules/dynamic_tables/create_table';
@import 'modules/orders/_index';
@import 'modules/materials/_index';
@import 'modules/expressions/_expressions';
@import 'modules/dashboards/_index';
@import 'modules/development_projects/_dp_tasks';
@import 'modules/notifications/_notifications';
@import 'modules/notifications/_toastr';
@import 'modules/articles/_index';
@import 'modules/customer_portal/_index';
@import 'modules/user/_index';
@import 'modules/welcome/_main';
@import 'modules/events/_index';

@import 'scrollbar';
@import 'custom';
@import '_base';
@import 'compact';
@import 'libs/proman/src/lib/fa/fa-assets/scss/tooltip';

@import '_dark_mode';

@import "node_modules/froala-editor/css/froala_editor.pkgd.css";
@import "node_modules/froala-editor/css/froala_style.min.css";
@import "node_modules/froala-editor/css/themes/dark.min.css";
