.ClipboardCopy {
  position: relative;

  .ClipboardCopyBtn {
    position: absolute;
    right:0;
    top:0;
    opacity: 0;
    transition: 0.15s linear opacity;

    button,
    .mat-button-wrapper{
      width:24px;
      height:24px;
      line-height:20px;
      padding: 0;
    }
  }

  &:hover .ClipboardCopyBtn {
    opacity: 1;
  }
}
