.SalesOpportunities {

    .SalesOpportunities-price {
        text-align: center;
    }

    pm-navigation-header {
        margin-bottom: 15px;
    }

    pm-sales-opportunities-filters {
        padding: 0 8px;
    }
}

.SalesOpportunities-Stage {
    min-width: 200px;
}

.SalesOpportunities-Stage--Header {
    color: rgba(0, 0, 0, 0.54);
    padding: 8px 0 8px 16px;

    .SalesOpportunities-Stage--Header---Name {
        width: 0;
        padding: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .SalesOpportunities-Stage--Header---Sum {
        font-size: $font-size-1;
    }
}

.SalesOpportunities-item {
    margin-bottom: 4px;
    flex: 1;
    display: block;
    background-color: $color1;
    border-radius: 0.5rem;
    background-origin: border-box;
    background-clip: border-box;

    &.placeholder {
        background-color: transparent;
    }

    .SalesOpportunities-item--tag {
        display: block;
        width: 24px;
        height: 4px;
        margin: 2px 0 0 2px;
    }
}

.SalesOpportunitiesOverlayFiles {
    max-width: 300px;

    .SalesOpportunitiesOverlayFiles-Item {
        float: left;
        padding: 2px;
    }

    .SalesOpportunitiesOverlayFiles-Clear {
        clear: both;
    }
}

// -------------------------------------
.Projects {
    padding-top: 8px;

    .SalesOpportunities-price {
        text-align: center;
        padding-bottom: 8px;
    }

    pm-navigation-header {
        margin-bottom: 15px;
    }

    pm-projects-filters {
        padding: 0 8px;
    }
}

.SalesOpportunities-Container {

    padding: 0px 8px;

    &.Dragging {

        .SalesOpportunities-Stage {

            // , &:has(> pm-frame.gu-transit) something like this TO BE SUPPORTED in css4
            &:-moz-drag-over {
                border-color: #66afe9;
                outline: 0;
                -webkit-box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.075), 0rem 0rem 0.8rem rgba(102, 175, 233, 0.6);
                box-shadow: inset 0rem 0.1rem 0.1rem rgba(0, 0, 0, 0.075), 0rem 0rem 0.8rem rgba(102, 175, 233, 0.6);
            }

        }

    }

    .SalesOpportunities-Stage {
        border-radius: 0.5rem;
        display: block !important;
        position: relative;
        min-width: 150px;
        padding: 8px 0px 60px;
        margin: 0 4px;
        background: rgb(245, 245, 245);
    }

}

.SalesOpportunities-Stage--Header {
    color: rgba(0, 0, 0, 0.54);
    padding: 8px 0;

    .SalesOpportunities-Stage--Header---Name {
        width: 0;
        padding: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .SalesOpportunities-Stage--Header---Sum {
        font-size: $font-size-1;
    }
}

.SalesOpportunities-item {
    margin-bottom: 8px;
    flex: 1;
    display: block;
    background-color: $color1;
    min-height: 35px;
    position: relative;

    &.placeholder {
        background-color: transparent;
    }

    .SalesOpportunities-item-Color {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 4px;

    }

    .SalesOpportunities-item--Tag-Container {
        position: absolute;
        padding: 0 8px;
    }

    .SalesOpportunities-item--Tag-Container-Icon {
        height: 30px;
        align-items: center;

        div {
            padding: 0 8px;
            font-size: 2em;
        }
    }

    .SalesOpportunities-item--tag {
        display: block;
        width: 24px;
        height: 4px;
        margin: 2px 0 0 2px;
    }

    .SalesOpportunities-item--content {
        padding: 8px 0 8px 12px;
        line-height: 1.2em;
        overflow-wrap: break-word;
        word-wrap: break-word;
        max-width: 100%;
        overflow: hidden;
        //background: rgb(255, 255, 255);
        //background: linear-gradient(180deg, rgba(0,0,255,0) 0%, rgba(255,255,255,1) 100%);
        min-height: 48px;
        border-radius: 0.5rem;

        &.NoRadiusBottom {
            border-radius: 0.5rem 0.5rem 0 0;
        }

        a {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            @supports (-webkit-line-clamp: 2) {
                white-space: initial;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }

    .SalesOpportunities-item--info {
        padding: 0 0 4px 12px;
        line-height: 1.2em;
        font-size: 0.75em;
        overflow-wrap: break-word;
        word-wrap: break-word;
        max-width: 100%;
        overflow: hidden;
        margin-top: -6px;
        align-items: center;
        border-radius: 0 0 0.5rem 0.5rem;

        fa {
            font-size: 7px;
        }
    }
}

.SalesOpportunities-AddButton {
    min-height: 35px;
    border-radius: 0.5rem !important;

    & > div {
        padding: 4px;
    }
}

.SalesOpportunitiesOverlayFiles {
    max-width: 300px;

    .SalesOpportunitiesOverlayFiles-Item {
        float: left;
        padding: 2px;
    }

    .SalesOpportunitiesOverlayFiles-Clear {
        clear: both;
    }
}

