.Collapsible {
  .Collapsible-Content {
    max-height: 0px;
    transition: max-height .5s cubic-bezier(0, 1, 0, 1) -.1s;
    overflow: hidden;

    &.isExpanded {
      max-height: 9999px;
      transition-timing-function: cubic-bezier(0.5, 0, 1, 0);
      transition-delay: 0s;
    }
  }

  .Collapsible-handle {
    cursor: pointer;
  }
}
