.EntityPhoto {
  position: relative;

  .EntityPhoto-Header {
    margin-top: 5px;
    height: 40px;
    color: $color8;

    pm-label {
      margin-left: 11px;
    }
  }

  md-progress-linear {
    .md-container {
      background-color: $color17;
    }
  }

  .md-button {
    margin: 6px 2px;
  }

  pro-thumbnail {
    padding-right: 10px;

    img {
      @include border-radius(25px);
    }
  }
}
