.Layout {
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  box-sizing: border-box;

  // new
  height: 100vh;

  &.compact {

    .LayoutToggleButton {
      display: initial;
    }

    mat-sidenav-container.MenuSidenav  {
      .mat-sidenav {
        overflow-y: visible;
        @include rgbaBackground($menu-color, 1);
      }

    }

  }

  &.mobile {
    pm-tool-btns {
      display: none;
    }

    .LayoutToggleButton {
      display: initial;
    }
  }

  mat-sidenav-container.MenuSidenav  {
    width: 100%;

    & > mat-sidenav {
      @include rgbaBackground($menu-color, 1);
    }
  }

  pm-language-switch {
    button {
      padding: 0 8px;
      min-width: 0;
    }
    svg, span {
      color: white;
      fillColor: white;
    }
  }

  &.fixBug {

    .MenuSidenav > mat-sidenav {
      transform: translate3d(0px, 0px, 0px)!important;
      visibility: visible!important;
    }

  }
}

.Layout-hide {
  margin: 0;
  background-color: transparent !important;
  width: 24px !important;
  height: 24px !important;
  padding: 0 !important;
  border-radius: 5px;
  box-shadow: none !important;
  text-align: right;

  button {
    margin: -4px 0 0 -12px;
  }

  fa {
    color: #909090;
    fill: currentColor;
  }
}
