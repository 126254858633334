.OrderOperations {
  .OrderOperations-Comment {
    &.is-important {
      color: red;
    }
  }

  mat-panel-title {
    font-size: 0.95em;
  }

  fa i {
    font-size: 1.25em;
  }

  fa i.fa-1x {
    font-size: 1em;
  }

  pro-btn[size="1x"] button {
    width: 24px;
    height: 24px;
  }

}
