.OrderCreate {

  .OrderCreate-Info {
    min-width: 180px;
  }

  .OrderCreate-Position {

    .OrderCreate-Position--Header {

      h4 {
        margin: 0 0 4px 0;
        font-weight: 500;
      }

      div {
        color: $color2;
      }
    }
  }

  .OrderCreate-PositionContent {
    .Editable {
      margin-bottom: 4px;
    }
  }

  .md-menu {
    padding: 0;
  }

  md-list {
    padding: 0;

    .md-button {
      color: $color4;
    }
  }

  .mat-expansion-indicator::after {
    margin-top: -8px;
  }

  .OrderCreate-delBtn {
    margin-right: 10px;
    margin-top: 5px;
  }

}

.OrderCreate-position {
  &.ng-invalid.ng-submitted {
    md-divider {
      border-top-color: red;
    }
  }
}

.OrderCreate-newPosition {
  padding: 0 14px 8px 14px;
  border-top: 4px solid #f1f1f1;

  .OrderCreate-newPosition--add .md-button {
    margin: 0;
  }
}

.OrderCreate-proposals {
  md-input-container {
    margin: 0;
  }
}
