.pagination {
  margin: 23px 0;
  &.simple {
    margin: 0;
  }

  &__item:not(.mat-tab-link) {
    padding: 4px 12px;
    margin: 0 2px;
    text-decoration: none;
    font-size: 14px;
    color: #2196f3;
    background-color: #ffffff;
    cursor: pointer;
    display: inline-block;;
    border-radius: 8px;

    &:hover {
      color: #0a6ebd;
      background-color: #E7F4FF;
      text-decoration: none;
    }

  }

  &__item--disabled:not(.mat-tab-link) {
    color: #bbbbbb;
    background-color: #ffffff;
    cursor: not-allowed;

    &:hover {
      color: #bbbbbb;
      background-color: #ffffff;
    }

  }

  &__item--active:not(.mat-tab-link) {
    color: #ffffff;
    background-color: #2196f3;
  }

}
