.ToolBtns {
  //position: fixed;
  //bottom: 0;
  //left: 0;
  //width: 250px;
  //@include rgbaBackground($menu-color, 0.75);
  //z-index: 1;

  button {
    display: inline-block;
    background-color: transparent !important;
    box-shadow: none;
    line-height: initial;

    svg {
      color: white;
      fill: currentColor;
    }
  }
}

button.ToolBtns-important svg {
  color: #d84315;
  fill: currentColor;
}
