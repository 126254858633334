.Regions {
  .Regions-List {
    .Regions-List--Item {
      padding: 4px;
      margin: 1px;
      font-size: $font-size-6;
      cursor: pointer;
    }
  }
}
