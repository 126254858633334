.ttt-calendar {
  position: relative;
  padding-top: 41px;
  overflow: hidden;
  border-bottom: 3px solid #f6f6f6;

  *, *:before, *:after {
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .md-button {
    margin: 0;
  }

  .ttt-calendar-pane {
    position: absolute;
    top: 0px;
    border-right: 1px solid $black-40;

    &.last {
      border-right: none;
    }

    &.first {
      border-left: 1px solid $black-40;
    }
  }
}
.ttt-calendar-inner-wrapper {
  position: relative;
  overflow: hidden;
}
.ttt-calendar-scale {
  position: relative;
}
.ttt-calendar-show-newer-date-button, .ttt-calendar-show-older-date-button {
  background: #40ACE4;
  color: #fff;
  text-align: center;
  padding: 4px 0;
  cursor:pointer;
}
.ttt-calendar-scale-info-placeholder, .ttt-calendar-scale-info {
  border-top: 1px solid #AED0EA;
  border-bottom: 1px solid #AED0EA;
  margin: 0px;
  padding:0px;
  height: 24px;
  line-height: 24px;
  vertical-align: middle;
  font-size: 10px;
}
.ttt-calendar-scale-info {
  background: #E0EEF7;
  color: #2694E8;
  text-align: center;
  font-size: 10px;
  position: absolute;
}
.ttt-calendar-day-info {
  position: relative;
  background: #F0F3F5;
  color: #555;
  text-align: center;
  margin: 0px;
  height: 22px;
  font-size: 12px;
  font-weight: bold;
  z-index: 10;
}
.ttt-calendar-day-info-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}
.ttt-calendar-day-info .buttons {
  position: absolute;
  right: 0px;
  top: 0px;
}

.ttt-calendar-step {
  border-top: 1px solid #F0F3F5;
  margin:0px;
  color: $color4;
  font-size: 10px;
  height: 21px;
  line-height: 21px;
  vertical-align: middle;

  &:hover, &.highlight {
    position: relative;
    background-color: rgba(33, 150, 243, 0.2);
    border-top: 1px solid $color3;
    border-bottom: 1px solid $color3;
    z-index: 1;
  }
}

.ttt-calendar-step-label {
  display: block;
  width: 40px;
  text-align: center;
}
.ttt-calendar-day {
  margin: 0;
  padding: 0;
}
.ttt-calendar-today-button {
  margin: 5px 0px;
}
.ttt-calendar-events-container, .ttt-calendar-disabled-intervals-container {
  position: absolute;
}
.ttt-calendar-disabled-interval {
  //background: url('../../assets/images/calendar-stripe-bg.png') repeat;
  position: absolute;
}
.ttt-calendar-events-overlay-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index:20;
}
.ttt-calendar-event {
  position: absolute;
  border-bottom: 1px solid $color1;
  cursor: pointer;
  z-index: 1;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  .ttt-calendar-event-border {
    content: '';
    display: block;
    width: 3px;
    padding-left: -3px;
    height: 100%;
    position: absolute;
    z-index: 3;
  }

  &.maintenance {
    background-image: -webkit-repeating-linear-gradient(135deg, rgba(0,0,0,.3), rgba(0,0,0,.3) 1px, transparent 2px, transparent 2px, rgba(0,0,0,.3) 3px);
    background-image: -moz-repeating-linear-gradient(135deg, rgba(0,0,0,.3), rgba(0,0,0,.3) 1px, transparent 2px, transparent 2px, rgba(0,0,0,.3) 3px);
    background-image: -o-repeating-linear-gradient(135deg, rgba(0,0,0,.3), rgba(0,0,0,.3) 1px, transparent 2px, transparent 2px, rgba(0,0,0,.3) 3px);
    background-image: repeating-linear-gradient(135deg, rgba(0,0,0,.3), rgba(0,0,0,.3) 1px, transparent 2px, transparent 2px, rgba(0,0,0,.3) 3px);
    -webkit-background-size: 4px 4px;
    -moz-background-size: 4px 4px;
    background-size: 4px 4px;
  }
}

.ttt-calendar-event-dependencies-container {
  margin-left: 3px;
  z-index: 2;

  table {
    table-layout: fixed;
    width: 100%;

    tbody {
      tr td {
        padding: 0;
        border-top: none;
      }
    }
  }
}

.ttt-calendar-event-operation-dependency {
  font-size: 8px;
  color: #FFF;
  text-shadow: 1px 1px 1px #161618;
  text-align: center;
  height: 10px;
}
.ttt-calendar-event-body-container {
  padding-left: 3px;
  z-index: 1;
  overflow: hidden;

  p {
    margin: 0;
  }
}
.ttt-calendar-event-body {
  text-align: center;
  word-wrap: break-word;
  line-height: 13px;
  font-size: 10px;
  color: #fff;
  position: relative;

  span {
    text-shadow: 1px 1px 1px #161618;
  }
}
.ui-resizable-s {
  bottom: -3px;
  height: 5px;
}
.ui-resizable-n  {
  top: -3px;
  height: 5px;
}

.ttt-calendar-column-headers-container {
  position: relative;
  top: 0;
  left: 0px;
  height: 20px;
}
.ttt-calendar-column-header {
  background-color: #FDFDFD;
  position: absolute;
  text-align: center;
  word-wrap: break-word;
  padding: 0;
  border-right: 1px solid #F0F3F5;
  height: 20px;
  line-height: 20px;
  vertical-align: middle;
  z-index: 10;
}
.ttt-calendar-column-header:last-child {
  border-right: none;
}
.ttt-calendar-column:last-child {
  border-right: none;
}

.ttt-calendar-pane.last {
  border-right: 0px;
}
.ttt-calendar-columns-container {
  position: relative;
  pointer-events: none;
}
.ttt-calendar-column {
  border-right: 1px solid #F0F3F5;
  position: absolute;
  pointer-events: none;
}
.ttt-calendar-pane .ttt-calendar-column:last-child {
  /*border-right: 1px solid #aaa;*/
}
.ttt-calendar-pane:last-child .ttt-calendar-column:last-child {
  border-right: none;
}

.ttt-calendar-highlight {
  background-color: #F3F6FC;
  position: absolute;
  width: 100%;
}
.ttt-calendar-current-time-marker {
  background-color: #E2F2FF;
  position: relative;
  width: 100%;
  height: 1px;
}
.tooltip-info td {
  padding: 0 2px 0 0;
  margin: 0;
}
.ttt-calendar-interactive-event-info {
  border-radius: 3px;
  font-size: 10px;
  width: 150px;
}
.placeholder-event {
  opacity: 0.2;
}

/* CALENDAR COLORS */
.calendar-legend {
  width: 100%;
  table-layout: fixed;
  text-align: center;
  border-collapse: collapse;
  margin-top: 10px;
}
.calendar-legend td {
  padding: 3px;
}


.calendar-legend .minicolors-swatch {
  width: 22px;
  height: 22px;
}
.calendar-legend .minicolors-swatch-color {
  border: 2px solid #fff;
  border-radius: 2px;
  box-shadow: 1px 1px 2px #aaa;
}
.editable .minicolors-swatch {
  height: 22px;
}

.ttt-calendar-event-body-container .fa {
  display: inline-block;
  font-size: 1em;
}

.ttt-calendar-column-headers-container {
  overflow: hidden;
}

.ttt-calendar-interactive-event {
  display: none;
}

.Calendar {
  border: 3px solid #DCDCDC;

  md-input-container {
    margin: 0;
  }
}

.Calendar-indicator {
  display: inline-block;
  width: 8px;
  height: 8px;
  @include border-radius(4px);
}

// ----------- BOARDS STYLES

.Board {

  span, a {
    white-space: pre-wrap;
  }

  .Table tbody tr > td {
    padding: 0;
    margin: 0;
    vertical-align: top;
    border-left: 1px solid #e3e3e3;

    &:first-child {
      border-left: 0;
    }
  }
  
  .Table thead tr, .Table tfoot tr {
    text-align: center;
  }

  .Table tfoot, .Table tfoot td, .Table tfoot th, .Table tfoot tr {
    border: none;
  }

  .DayName {
    vertical-align: middle;
    text-align: center;
    width: 11.5em;
    min-width: 11.5em;
    & > div {
      min-height: 3.25em;
      line-height: 3.25em;
    }

  }

  .WeekRow {
    min-height: 125px;
    border-bottom: 1px solid #333;

    & > div {
      border-right: 1px solid #333;

      &:first-child {
        border-left: 1px solid #333;
      }

    }
  }
  .WeekRow > div {  }
  .WeekRow > div:first-child {  }

  .FirstColumn {
    text-align: center;

    span {
      width: 170px!important;
    }

  }

  .AddRow {
    height: 20px;
    margin-top: -8px;
    margin-right: -8px;

    div > a, div > span {
      margin-bottom: -4px;
    }

  }

  .ContentCell {
    min-height: 1.25em;

    & > div {
      position: relative;
      min-height: 1.25em;
      //min-width: 10em;
      //max-width: 300px;
    }
  }

  .size-sm, .size-md {
    .FirstColumn {
      span {
        width: 80px!important;
        transform: rotate(-60deg);
      }

    }
  }

}

.container {
  /* Attach fixed-th-table to this container,
     in order to layout fixed-th-table
     in the same way as scolled-td-table" */
  position: relative;

  /* Truncate fixed-th-table */
  overflow: hidden;
}

.fixed-th-table-wrapper td,
.fixed-th-table-wrapper th,
.scrolled-td-table-wrapper td,
.scrolled-td-table-wrapper th {
  /* Set background to non-transparent color
     because two tables are one above another.
   */
  background: white;
}
.fixed-th-table-wrapper {
  /* Make table out of flow */
  position: absolute;
}

.fixed-th-table-wrapper td {
  visibility: hidden;
}
.fixed-th-table-wrapper th {
  /* Place fixed-th-table th-cells above
     scrolled-td-table td-cells.
   */
  position: relative;
  z-index: 1;
}
.scrolled-td-table-wrapper td {
  /* Place scrolled-td-table td-cells
     above fixed-th-table.
   */
  position: relative;
}
.scrolled-td-table-wrapper {
  /* Make horizonal scrollbar if needed */
  overflow-x: auto;
}


/* Simulating border-collapse: collapse,
   because fixed-th-table borders
   are below ".scrolling-td-wrapper table" borders
*/

table {
  border-spacing: 0;
  tbody {
    td, th {
      //border-style: solid;
      //border-color: black;
      //border-width: 1px 1px 0 0;
    }
//

/* important styles */

    th:first-child {
      border-left-width: 1px;
    }
    tr:last-child td,
    tr:last-child th {
      border-bottom-width: 1px;
    }
  }
}


/* Unimportant styles */

.container {
  width: 100%;
}
