.Permissions {
  .Switch {
    border-bottom: 0;
    padding-bottom: 2px;
    margin-bottom: 0;
  }

  .md-button {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
  }

  md-checkbox {
    margin-bottom: 0;
  }
}

.Permissions-fixedHead {
  top: 0;
  position: fixed;
  width: auto;
  display: none;
  z-index: 2;
}
