.Menu {
  position: relative;

  &.compact {
    .Menu-category--name, .Menu-category--indicator, .Menu-category span[fxFlex] {
      display: none;
    }

    .Menu-tabs {
      position: absolute;
      padding: 10px 4px;
      left: 75px;
      top: 0;
      @include rgbaBackground($menu-color, 0.95);
      border-left: 1px solid;
    }

    .Menu-tabs--tab {
      padding-left: 15px !important;

    }

    .Menu-category {
      text-align: center !important;

      .mat-button-wrapper {
        justify-content: center;
      }
    }
  }

}

.Menu-category {
  padding-left: 18px !important;
  line-height: 40px !important;
  min-width: 75px !important;

  .mat-button-wrapper {
    display: block;
    white-space:normal;
    align-items: center;
  }

  &.active {
    color: $color3 !important;

    svg {
      fill: $color3;
    }

  }

}

.Menu-tabs--tab {
  padding-left: 25px !important;
  line-height: 30px !important;

  .mat-button-wrapper {
    text-transform: capitalize;
  }

  &.active {
    color: white !important;
    font-weight: bold !important;
  }
}

.Menu-tabs {
  background-color: rgba(255, 255, 255, 0.05);
  padding: 4px 0;
  overflow: hidden;
}

.Menu-category, .Menu-tabs--tab {
  color: rgba(255, 255, 255, 0.8) !important;
  fill: currentColor;
  font-family: 'Open Sans Regular' !important;
  font-size: $font-size-3 !important;
  text-align: left !important;

  & > span.mat-button-wrapper {
    place-content: center center;
    flex: 1 0 auto;

    fa.Category-icon {
      margin-right: 4px;
      width: 20px;
      font-size: 0.9em;
      margin-top: 2px;

    }

  }

  &:hover {
    text-decoration: none !important;
  }
}

.Menu-category--name {
  padding-left: 6px;
  font-size: $font-size-1;
}

.Menu-category--indicator {
  float: right;
  padding: 1px 0;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  transition-property: transform;
  font-size: 0.9em;
}

.is-rotated {
  transform: rotate(180deg);
}

