.EntityActivityLog {
  padding: 8px;

  .EntityActivityLog-container {
    position: relative;

    span{
      float:left;
      width: 33.333%;
    }

    span:first-child {
      text-align: left;
    }

    span:nth-child(2) {
      text-align: center;
    }

    span:last-child {
      text-align: right;
    }

    .EntityActivityLog-header {
      font-size: $font-size-2;
    }

    .EntityActivityLog-row {
      font-size: $font-size-4;
      span:last-child {
        font-style: italic;
      }
    }

  }
}