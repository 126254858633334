.Preview {
  width: auto;
  height: 100%;
}

.Preview-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 81;
  background-color: #000000;
  opacity: .7;

  &.ng-enter {
    opacity: 0;

    &.ng-enter-active {
      opacity: .7;
    }
  }

  &.ng-leave {
    opacity: .7;
    transition: opacity 400ms;

    &.ng-leave-active {
      opacity: 0;
    }
  }
}

.Preview-container {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 82;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

  &.ng-enter {
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    -webkit-transform: translate(0, 0) scale(0);
    transform: translate(0, 0) scale(0);

    &.ng-enter-active {
      opacity: 1;
      -webkit-transform: translate(0, 0) scale(1);
      transform: translate(0, 0) scale(1);
    }
  }

  &.ng-leave {
    opacity: 1;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    -webkit-transform: translate(0, 0) scale(1);
    transform: translate(0, 0) scale(1);

    &.ng-leave-active {
      opacity: 0;
      -webkit-transform: translate(0, 0) scale(0);
      transform: translate(0, 0) scale(0);
    }
  }
}

.Preview-container--content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  height: 100%;
  max-width: 80%;
  max-height: 90%;
}
