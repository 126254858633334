.NavigationHeader {
  margin: 0;

  .NavigationHeader-wrapper {
    position: relative;
    padding: 4px 8px 4px 16px;
  }

  h3 {
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 8px;
    line-height: 1;
  }

  .NavigationHeader-Toolbar {
    .md-button {
      margin: 6px 4px 6px 0;
    }
  }

  .NavigationHeader-Title {
    margin-left: 6px;
  }

  &.with-create {

    .NavigationHeader-Title {
      margin-left: 45px;
    }

    .NavigationHeader-menuButtons {
      margin-left: 55px;
    }
  }

  .NavigationHeader-Subheader, .NavigationHeader-dynamicStatus {
    margin: 0 8px 8px 0;
    color: $color8;
    font-size: $font-size-2;
    font-weight: normal;
  }

  .NavigationHeader-dynamicStatus {
    color: $color12;
  }

  .NavigationHeader-createBtn {
    position: absolute;
    bottom: -25px;
    left: 10px;
  }

  .md-button {
    margin: 6px 2px;
  }
}
