.Time {
  .Time-Label {
    padding-right: 15px;
  }

  .Time-Block {
    width: 35px;
  }

  md-input-container {
    padding-bottom: 0;
  }
}
