.EntityTablePreloader {
  z-index: 61;
  opacity: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);

  &.ng-leave {
    transition: background-color 600ms linear;
  }

  &.ng-leave.ng-leave-active {
    background-color: rgba(255, 255, 255, 0);
  }
}
