.VideoPlayer {
  position: relative;

  video {
    display: block;
    width: 100%;
    height: auto;
  }

  .VideoPlayer-Actions {
    position: absolute;
    left: 52px;
    bottom: 10%;
  }

  .Button svg {
    width: 50px;
    height: 50px;
  }

  .md-button {
    height: 52px;
    width: 52px;
    margin: 0;
    border: 2px solid $color1;

    md-icon {
      width: 30px;
      height: 30px;
    }
  }
}
