$blue-1: #b3e3ff;
$blue-2: #49a0ff;

.ExpressionN {

  .ExpressionN-item, .ExpressionN-createItem {
    padding: 0 2px 2px 2px;
    margin-right: 6px;
    border-bottom: 1px solid $blue-1;
    cursor: pointer;

    span {
      line-height: 26px;
    }

    &.selected {
      padding: 0 2px 1px 2px;
      border-bottom: 2px solid $blue-2;
    }
  }

  .ExpressionN-placeholder {
    color: #0085ff;
  }

  .ExpressionN-preview {
    margin: 6px 0 0 2px;
    font-size: 12px;
    font-style: italic;
    color: #6a7982;
    border: 0;
  }
}

.ExpressionN-menu {
  position: fixed;
  margin-left: 8px;
  z-index: 1000;
  max-height: 262px;

  .is-current {
    background-color: #dadada;
  }
}

.ExpressionN-menu--item {
  padding: 4px 8px;
  cursor: pointer;
}

.ExpressionN-menu--label {
  padding: 4px 8px;
  color: gray;
  border-bottom: 1px solid #dadada;
}

// expression autocomplete dropdown styles

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
  box-shadow: 0 6px 12px rgba(0,0,0,.175);

  &>li {

    &>a {
      display: block;
      padding: 3px 20px;
      clear: both;
      font-weight: 400;
      line-height: 1.42857143;
      color: #333;
      white-space: nowrap;

      &:hover {
        cursor: pointer;
      }

    }

  }

}
