.toast-container {
  .toast-bottom-right {
    right: 8px;
    bottom: 0;
  }

  .toast {
    padding: 12px;
    font-size: 14px;
    text-align: center;
    width: auto;
    min-width: 250px;
  }

  .toast-title {
    white-space: normal;
    word-break: break-all;
  }

  .toast-pm-success {
    background-color: #51A351;
  }

  .toast-pm-error {
    background-color: $color12;
  }

  .toast-pm-info {
    background-color: $color-accent;
  }

  .toast-pm-warning {
    color: $color1;
    background-color: #7a48b6;
  }
}
