.Calendar-Container {
  position: absolute;
  //overflow: auto;
  width: 100%;
  top: 50px;

  .Calendar {
    margin-bottom: 16px;
    border: 3px solid #DCDCDC;
    //overflow: hidden;
  }

  .leftbar {
    width: 40px;
  }

  .header {

    pro-btn button {
      width: 20px;
      height: 20px;
    }

    .far{
      font-size: 1em!important;
    }

  }

  .pane-header {
    color: #555;
    margin: 0;
    font-size: 12px;
    font-weight: bold;
  }

  .day {
    border-left: 2px solid lightgrey;
    box-sizing: border-box;
  }

  .timestep {
    height: 20px;
    line-height: 20px;
    font-size: 0.75em;
    text-align: center;
    position:relative;
  }

  .timestep::before {
    content: "";
    position: absolute;
    width: 99999px;
    height: 20px;
    top: 0;
    left: 0;
    border-top: 1px solid rgba(222,222,222,0.4);
    box-sizing: border-box;
  }

  .timestep:hover:before {
    background: rgba(222,222,222,0.4);
  }

  .prev-operations {
    margin-left: 3px;
    z-index: 2;

    table {
      table-layout: fixed;
      width: 100%;

      tbody {
        tr td {
          padding: 0;
          border-top: none;
        }
      }
    }
  }

  .Calendar-column {
    border-right: 1px solid rgba(222,222,222,0.4);
    //overflow: hidden;
  }

  .Calendar-day {
    border-right: 1px solid rgba(0, 0, 0 ,0.4);
  }

  .event-container {
    padding: 0 2px;
    border-radius: 2px;
    box-sizing: border-box;
    overflow: hidden;
    position: absolute;
    width: 100%;
    z-index: 5;
    user-select: none;
  }

  .prev-operation-dependency {
    font-size: 8px;
    color: #FFF;
    text-shadow: 1px 1px 1px #161618;
    text-align: center;
    height: 10px;
  }

  .event-border {
    content: '';
    display: block;
    width: 3px;
    margin: -2px;
    height: 100%;
    position: absolute;
    z-index: 3;
  }

  .event-indicator {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
  }

  .event-body {
    text-align: center;
    word-wrap: break-word;
    line-height: 13px;
    font-size: 10px;
    color: #fff;
    position: relative;
    text-shadow: 1px 1px 1px #161618;
    padding: 4px;
  }

  .col {
    position: relative;
  }

}

.Buffer-container {
  min-height: 5px;
}

.Calendar.CalendarHorizontal {

  .timestep {
    height: 25px;
    line-height: 25px;
  }

  .event-container {
    padding: 0;
    border-radius: 0;
  }

  .Calendar-column {
    overflow: hidden;
  }

}

.Calendar-sticky-header {
  position: sticky;
  top: 0;
  z-index: 90;
}

.Calendar-sticky-header > div {
  background: white;
}

pm-calendar .cdk-drag-dragging {
  z-index: 9999;
}
