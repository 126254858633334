.Event {
  md-list {
    padding-top: 0;
  }

  .active-event {
    background-color: #A0A0A0;

    .md-subheader {
      background-color: #A0A0A0;
      color: white;
    }
  }

  .active-booking {
    margin: -3px -8px -1px -8px;
    border-color: $color3;
    border-width: 8px;
    border-style: solid;
    border-top-width: 3px;
    border-bottom-width: 1px;
  }
}

.Event-header {
  padding: 4px 18px;
  margin-bottom: 8px;

  h5 {
    margin: 0;
  }
}

.Event-header--time {
  color: #6F6F6F;
}
