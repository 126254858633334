.EntityTableColor {
  display: block;
  width: 17px;
  height: 17px;
  @include border-radius(9px);
  border: 1px solid transparent;

  &.no-color {
    border: 1px solid $color6;
  }
}
