@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin appearance($appearance) {
  -webkit-appearance: $appearance;
  -moz-appearance: $appearance;
  appearance: $appearance;
}

@mixin rgbaBackground($color, $alpha) {
  $rgba: rgba($color, $alpha);
  background-color: $color;
  background-color: $rgba;
}
