.proman-list li {
  border-bottom: 0.1rem solid #cccccc;
  padding: 0.8rem 0.4rem;
  position: relative;
}
.proman-list-padded {
  padding-left: 1.5rem;
}
.proman-list li:hover {
  background: #fcfdff;
}
.proman-list.proman-list-no-highlight li:hover {
  background: inherit;
}
.proman-list li:last-child {
  border-bottom: none;
}
.proman-list li .buttons {
  position: absolute;
  right: 0.4rem;
  top: 0.4rem;
}
.proman-list.proman-list-collapsible .content {
  margin-top: 1rem;
}
.proman-list li.collapsible-open:hover {
  background: none;
}
.proman-list li.disabled {
  color: #a1a1a1;
}
.proman-list li.disabled:hover {
  background: #fdfeff;
}

.proman-list-collapsible li {
  height: auto;
  min-height: 2.8rem;
}
