$smallBtnSize: 20px;

.EntityTable {
  position: relative;

  table button {
    box-shadow: none !important;
    background-color: transparent !important;
  }

  .with-top-btn {
    margin: 10px;
  }

  md-input-container {
    padding-bottom: 0;
  }

  &.with-multiselect {
    .EntityTable-multiselectcheckbox, .EntityTable-multiselectplaceholder {
      display: table-cell;
      padding-left: 14px;
      line-height: 1;
      border-top: 0;

      md-checkbox ._md-label {
        display: none;
      }
    }
  }

  .Table-RowButtons {
    display: none;
  }

  &.with-rowButtons {

    .Table-RowButtons {
      display: table-cell;
      overflow: visible;

        .fa-2x {
            font-size: 1.35em;
        }
    }

  }
    .TableFilter-closeButton button {
        padding: 0;
        height: 16px;
        width: 16px;
        margin: 0;
    }


.EntityTable-multiselectcheckbox, .EntityTable-multiselectcheckbox, .EntityTable-multiselectplaceholder {
    display: none;
  }

  thead {

    th.desc, th.asc {
      color: rgba(0, 0, 0, 0.87);
    }

    .EntityTable-sortAsc, .EntityTable-sortDesc {
      min-width: $smallBtnSize;
      min-height: $smallBtnSize;
      width: $smallBtnSize;
      height: $smallBtnSize;
      margin-right: 4px;
      color: rgba(0, 0, 0, 0.87);
      fill: rgba(0, 0, 0, 0.87);
    }

    th.desc {

      .EntityTable-sortDesc {
        display: table!important;
      }
    }

    th.asc {

      .EntityTable-sortAsc {
        display: table!important;
      }
    }

    th.is-sortable {
      cursor: pointer;
    }

  }

  .EntityTable-lineNumber {
    display: none;
  }

  tfoot {
    td {
      font-size: $font-size-5;
      font-style: italic;
      white-space: nowrap;
      vertical-align: top;
    }
  }

  .EntityTable-subtotal-row {
    td {
      font-size: $font-size-5;
      font-style: italic;
      white-space: nowrap;
      vertical-align: top;
      font-weight: bold;
    }
  }

  &.isLineNumbering {
    .EntityTable-lineNumber {
      display: table-cell;
      width: 50px;
    }
  }

}

.Table-customFilters {
  box-shadow: 0 0 6px rgba(55, 55, 55, 1);
  border-radius: 3px;
  position: relative;
  background: white;
  margin-left: 20px;
}

.Table-customFilters pro-btn:first-child button {
  padding: 4px 32px 4px 10px;
  font-size: 12px;
  height: 24px;
  line-height: 16px;
}

.Table-customFilters pro-btn:last-child{
  position: absolute;
  right: -3px;
  top: 0;
  padding: 0;
  margin-top: -4px;
  pointer-events: none;

  .mat-mini-fab {
    height: 16px;
    width: 16px;
    position: relative;
    left: -7px;
    top: -7px;
  }

  fa i {
    font-size: 1.35em;
  }

}

.EntityTable-sortAsc, .EntityTable-sortDesc {
  display: none!important;
}

.EntityTable-actions {

  .md-menu {
    padding: 0;
  }

  md-select {
    padding: 12px 0;
  }

}

.EntityTable-clearFilterBtn {
  .md-button {
    margin: 0 0 0 4px;
    padding: 0;
    width: $smallBtnSize;
    height: $smallBtnSize;
    min-height: $smallBtnSize;

    md-icon {
      min-width: $smallBtnSize;
      min-height: $smallBtnSize;
      width: $smallBtnSize;
      height: $smallBtnSize;
    }
  }
}

.EntityTable-multiselectcheckbox {
  width: 18px;

  md-checkbox {
    margin-bottom: 0;
  }
}

.EntityTable-rowsActionBtns {
  padding: 8px 16px;
  background-color: #f0f2fd;
  -webkit-animation-duration: 0.2s;
  -moz-animation-duration: 0.2s;
  animation-duration: 0.2s;
  overflow: scroll;

  &.ng-enter {
    animation-name: slideInDown;
  }

}

.EntityTable-rowsActionBtns--info {
  color: $color20;
  margin-right: 8px;
}

@keyframes slideInDown {

  from {
    height: 0;
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    height: auto;
    transform: translate3d(0, 0, 0);
  }

}

.EntityTable-filter {
  vertical-align: top;
}

.EntityTable-column--dateTime, .EntityTable-column--money {
  white-space: nowrap;
}

.EntityTable-autocompleteFilterSelectedOptions {
  padding-left: 6px;
}

.EntityTable-textIcon-container {

  span {
    margin: 2px 4px;
    font-size: 12px;
  }

  fa {
    margin-right: 4px;
  }

  .blue {
    color: #2196F3;
    fill: #2196F3;
  }

  .green {
    color: $color-green;
    fill: $color-green;
  }

  .yellow {
    color: #FFC107;
    fill: #FFC107;
  }

  .red {
    color: $color12;
    fill: $color12;
  }

  .grey {
    color: $color8;
    fill: $color8;
  }

}

pm-entity-table-filter-autocomplete {
  .md-input-focused, .md-input-has-value {
    label{
      visibility: hidden!important;
    }
  }
}
