.MinEditables {
  md-input-container, .Price {
    padding-bottom: 0;
  }

  md-input-container .md-input {
    line-height: normal;
  }

  md-input-container.md-default-theme, md-input-container.md-default-theme.md-input-has-value {
    .md-input, label {
      color: $black-87;
    }

    label {
      font-weight: bold;
    }
  }

  .Editable-Label {
    font-weight: bold;
    color: $black-87;
  }

  .Editable-Disabled {
    .Editable-Item {
      color: $black-87;
      line-height: normal;
    }
  }

  .table-condensed tbody > tr > td {
    padding: 2px;
  }

  .Editable {
    padding: 0;
    margin-bottom: 0;
    background-image: none;

    &.Editable-Disabled {

      .Editable-Item {
        padding-top: 0;
      }
    }
  }

  md-input-container.md-default-theme .md-input {
    height: 13px;
    background-image: none;
  }

  .MinEditables-Label {
    padding-right: 10px;
    font-weight: bold;
  }

  .Price {
    .Price-Currency {
      top: 28%;
      left: 17px;
    }
  }
}
