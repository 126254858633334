//@use '@angular/material' as mat;
// OLD STUFF BEFORE LEGACY


//@import '/node_modules/@angular/material/legacy-prebuilt-themes/legacy-indigo-pink.css';
// Plus imports for other components in your app.

// Include the common-components styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
//// Be sure that you only ever include this mixin once!
//@include mat.core();
//
//// Define the palettes for your theme using the Material Design palettes available in palette.scss
//// (imported above). For each palette, you can optionally specify a default, lighter, and darker
//// hue.
//$candy-app-primary: mat.define-palette(mat.$grey-palette);
//$candy-app-accent:  mat.define-palette(mat.$blue-palette);
//
//// The warn palette is optional (defaults to red).
//$candy-app-warn:    mat.define-palette(mat.$red-palette);
//$candy-app-success:    mat.define-palette(mat.$green-palette);
//
//// Create the theme object (a Sass map containing all of the palettes).
//$candy-app-theme: mat.define-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);
//
//// Include theme styles for core and each component used in your app.
//// Alternatively, you can import and @include the theme mixins for each component
//// that you are using.
//@include mat.all-component-themes($candy-app-theme);
//
//$color-accent: $color-accent;
//
//// OVERRIDES
//.mat-ink-bar {
//  background-color: mat.get-color-from-palette($candy-app-accent);
//}
@import '../_variables.scss';


.mat-tab-label, .mat-button-wrapper {
  text-transform: uppercase;
}
//
//.mat-mini-fab .mat-button-wrapper {
//  line-height: 22px!important;
//}
//
//$warn: map-get($candy-app-theme, warn);
//$primary: map-get($candy-app-theme, primary);
//$accent: map-get($candy-app-theme, accent);
//
//$candy-app-green: mat.define-light-theme($candy-app-primary, $candy-app-success, $candy-app-warn);

// Include the alternative theme styles inside of a block with a CSS class. You can make this
// CSS class whatever you want. In this example, any component inside of an element with
// `.unicorn-dark-theme` will be affected by this alternate dark theme instead of the default theme.
//.accent-green {
//  @include mat.all-component-themes($candy-app-green);
//}

$color-primary: #9e9e9e;
$color-accent: #2196f3;

/*.Btn-invert {

  .mat-mini-fab {
    background-color: transparent;
    box-shadow: none!important;
  }

  .mat-mini-fab.mat-warn:not([disabled]) {
    color: mat.get-color-from-palette($warn, 'default');
    background-color: transparent;
    box-shadow: none!important;

    &.hover {
      border: 2px mat.get-color-from-palette($warn, 'default');
    }

  }

  .mat-mini-fab.mat-primary {
    color: mat.get-color-from-palette($primary, 'default');
    background-color: transparent;
    box-shadow: none;
  }

  .mat-mini-fab.mat-accent {
    color: mat.get-color-from-palette($accent, 'default');
    background-color: transparent;
    box-shadow: none;

    &[disabled] {
      color: mat.get-color-from-palette($primary, 'default');
    }

  }

  .mat-mini-fab:not([disabled]):active {
    box-shadow: none;
  }

  .mat-mini-fab[disabled]{
    background-color: initial;
  }

}

.NoRadius {
  .mat-mini-fab {
    border-radius: initial!important;
  }
}*/


.mat-form-field-underline, .mat-select-underline {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-snack-bar-container.warning {
  background: rgba(198,40,40,0.87);
}


.mat-ripple{
    overflow:hidden;
    position:relative
}
.mat-ripple:not(:empty){
    transform:translateZ(0)
}
.mat-ripple.mat-ripple-unbounded{
    overflow:visible
}
.mat-ripple-element{
    position:absolute;
    border-radius:50%;
    pointer-events:none;
    transition:opacity,transform 0ms cubic-bezier(0, 0, 0.2, 1);
    transform:scale3d(0, 0, 0)
}
.cdk-high-contrast-active .mat-ripple-element{
    display:none
}
.cdk-visually-hidden{
    border:0;
    clip:rect(0 0 0 0);
    height:1px;
    margin:-1px;
    overflow:hidden;
    padding:0;
    position:absolute;
    width:1px;
    white-space:nowrap;
    outline:0;
    -webkit-appearance:none;
    -moz-appearance:none;
    left:0
}
[dir=rtl] .cdk-visually-hidden{
    left:auto;
    right:0
}
.cdk-overlay-container,.cdk-global-overlay-wrapper{
    pointer-events:none;
    top:0;
    left:0;
    height:100%;
    width:100%
}
.cdk-overlay-container{
    position:fixed;
    z-index:1000
}
.cdk-overlay-container:empty{
    display:none
}
.cdk-global-overlay-wrapper{
    display:flex;
    position:absolute;
    z-index:1000
}
.cdk-overlay-pane{
    position:absolute;
    pointer-events:auto;
    box-sizing:border-box;
    z-index:1000;
    display:flex;
    max-width:100%;
    max-height:100%
}
.cdk-overlay-backdrop{
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    z-index:1000;
    pointer-events:auto;
    -webkit-tap-highlight-color:rgba(0,0,0,0);
    transition:opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    opacity:0
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing{
    opacity:1
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing{
    opacity:.6
}
.cdk-overlay-dark-backdrop{
    background:rgba(0,0,0,.32)
}
.cdk-overlay-transparent-backdrop{
    transition:visibility 1ms linear,opacity 1ms linear;
    visibility:hidden;
    opacity:1
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing{
    opacity:0;
    visibility:visible
}
.cdk-overlay-backdrop-noop-animation{
    transition:none
}
.cdk-overlay-connected-position-bounding-box{
    position:absolute;
    z-index:1000;
    display:flex;
    flex-direction:column;
    min-width:1px;
    min-height:1px
}
.cdk-global-scrollblock{
    position:fixed;
    width:100%;
    overflow-y:scroll
}
textarea.cdk-textarea-autosize{
    resize:none
}
textarea.cdk-textarea-autosize-measuring{
    padding:2px 0 !important;
    box-sizing:content-box !important;
    height:auto !important;
    overflow:hidden !important
}
textarea.cdk-textarea-autosize-measuring-firefox{
    padding:2px 0 !important;
    box-sizing:content-box !important;
    height:0 !important
}
@keyframes cdk-text-field-autofill-start{
    /*!*/
}
@keyframes cdk-text-field-autofill-end{
    /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill{
    animation:cdk-text-field-autofill-start 0s 1ms
}
.cdk-text-field-autofill-monitored:not(:-webkit-autofill){
    animation:cdk-text-field-autofill-end 0s 1ms
}
.mat-focus-indicator{
    position:relative
}
.mat-focus-indicator::before{
    top:0;
    left:0;
    right:0;
    bottom:0;
    position:absolute;
    box-sizing:border-box;
    pointer-events:none;
    display:var(--mat-focus-indicator-display, none);
    border:var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
    border-radius:var(--mat-focus-indicator-border-radius, 4px)
}
.mat-focus-indicator:focus::before{
    content:""
}
.cdk-high-contrast-active{
    --mat-focus-indicator-display: block
}
.mat-mdc-focus-indicator{
    position:relative
}
.mat-mdc-focus-indicator::before{
    top:0;
    left:0;
    right:0;
    bottom:0;
    position:absolute;
    box-sizing:border-box;
    pointer-events:none;
    display:var(--mat-mdc-focus-indicator-display, none);
    border:var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
    border-radius:var(--mat-mdc-focus-indicator-border-radius, 4px)
}
.mat-mdc-focus-indicator:focus::before{
    content:""
}
.cdk-high-contrast-active{
    --mat-mdc-focus-indicator-display: block
}
.mat-button,.mat-icon-button,.mat-stroked-button{
    color:inherit;
    background:rgba(0,0,0,0)
}
.mat-button.mat-primary,.mat-icon-button.mat-primary,.mat-stroked-button.mat-primary{
    color:$color-primary
}
.mat-button.mat-accent,.mat-icon-button.mat-accent,.mat-stroked-button.mat-accent{
    color:$color-accent
}
.mat-button.mat-warn,.mat-icon-button.mat-warn,.mat-stroked-button.mat-warn{
    color:#f44336
}
.mat-button.mat-green,.mat-icon-button.mat-green,.mat-stroked-button.mat-green{
    color:$color-green;
}
.mat-button.mat-grey,.mat-icon-button.mat-grey,.mat-stroked-button.mat-grey{
    color:$color16;
}
.mat-button.mat-primary.mat-button-disabled,.mat-button.mat-accent.mat-button-disabled,.mat-button.mat-warn.mat-button-disabled,.mat-button.mat-button-disabled.mat-button-disabled,.mat-icon-button.mat-primary.mat-button-disabled,.mat-icon-button.mat-accent.mat-button-disabled,.mat-icon-button.mat-warn.mat-button-disabled,.mat-icon-button.mat-button-disabled.mat-button-disabled,.mat-stroked-button.mat-primary.mat-button-disabled,.mat-stroked-button.mat-accent.mat-button-disabled,.mat-stroked-button.mat-warn.mat-button-disabled,.mat-stroked-button.mat-button-disabled.mat-button-disabled{
    color:rgba(0,0,0,.26)
}
.mat-button.mat-primary .mat-button-focus-overlay,.mat-icon-button.mat-primary .mat-button-focus-overlay,.mat-stroked-button.mat-primary .mat-button-focus-overlay{
    background-color:$color-primary
}
.mat-button.mat-accent .mat-button-focus-overlay,.mat-icon-button.mat-accent .mat-button-focus-overlay,.mat-stroked-button.mat-accent .mat-button-focus-overlay{
    background-color:$color-accent
}
.mat-button.mat-warn .mat-button-focus-overlay,.mat-icon-button.mat-warn .mat-button-focus-overlay,.mat-stroked-button.mat-warn .mat-button-focus-overlay{
    background-color:#f44336
}
.mat-button.mat-green .mat-button-focus-overlay,.mat-icon-button.mat-green .mat-button-focus-overlay,.mat-stroked-button.mat-green .mat-button-focus-overlay{
    background-color:$color-green;
}
.mat-button.mat-grey .mat-button-focus-overlay,.mat-icon-button.mat-grey .mat-button-focus-overlay,.mat-stroked-button.mat-grey .mat-button-focus-overlay{
    background-color:$color16;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay,.mat-icon-button.mat-button-disabled .mat-button-focus-overlay,.mat-stroked-button.mat-button-disabled .mat-button-focus-overlay{
    background-color:rgba(0,0,0,0)
}
.mat-button .mat-ripple-element,.mat-icon-button .mat-ripple-element,.mat-stroked-button .mat-ripple-element{
    opacity:.1;
    background-color:currentColor
}
.mat-button-focus-overlay{
    background:#000
}
.mat-stroked-button:not(.mat-button-disabled){
    border-color:rgba(0,0,0,.12)
}
.mat-flat-button,.mat-raised-button,.mat-fab,.mat-mini-fab{
    color:rgba(0,0,0,.87);
    background-color:#fff
}
.mat-flat-button.mat-primary,.mat-raised-button.mat-primary,.mat-fab.mat-primary,.mat-mini-fab.mat-primary{
    color:#fff
}
.mat-flat-button.mat-accent,.mat-raised-button.mat-accent,.mat-fab.mat-accent,.mat-mini-fab.mat-accent{
    color:#fff
}
.mat-flat-button.mat-warn,.mat-raised-button.mat-warn,.mat-fab.mat-warn,.mat-mini-fab.mat-warn{
    color:#fff
}
.mat-flat-button.mat-primary.mat-button-disabled,.mat-flat-button.mat-accent.mat-button-disabled,.mat-flat-button.mat-warn.mat-button-disabled,.mat-flat-button.mat-button-disabled.mat-button-disabled,.mat-raised-button.mat-primary.mat-button-disabled,.mat-raised-button.mat-accent.mat-button-disabled,.mat-raised-button.mat-warn.mat-button-disabled,.mat-raised-button.mat-button-disabled.mat-button-disabled,.mat-fab.mat-primary.mat-button-disabled,.mat-fab.mat-accent.mat-button-disabled,.mat-fab.mat-warn.mat-button-disabled,.mat-fab.mat-button-disabled.mat-button-disabled,.mat-mini-fab.mat-primary.mat-button-disabled,.mat-mini-fab.mat-accent.mat-button-disabled,.mat-mini-fab.mat-warn.mat-button-disabled,.mat-mini-fab.mat-button-disabled.mat-button-disabled{
    color:rgba(0,0,0,.26)
}
.mat-flat-button.mat-primary,.mat-raised-button.mat-primary,.mat-fab.mat-primary,.mat-mini-fab.mat-primary{
    background-color:$color-primary
}
.mat-flat-button.mat-accent,.mat-raised-button.mat-accent,.mat-fab.mat-accent,.mat-mini-fab.mat-accent{
    background-color:$color-accent
}
.mat-flat-button.mat-warn,.mat-raised-button.mat-warn,.mat-fab.mat-warn,.mat-mini-fab.mat-warn{
    background-color:#f44336
}
.mat-flat-button.mat-green,.mat-raised-button.mat-green,.mat-fab.mat-green,.mat-mini-fab.mat-green{
    background-color:$color-green;
}
.mat-flat-button.mat-grey,.mat-raised-button.mat-grey,.mat-fab.mat-grey,.mat-mini-fab.mat-grey{
    background-color:$color16;
}
.mat-flat-button.mat-primary.mat-button-disabled,.mat-flat-button.mat-accent.mat-button-disabled,.mat-flat-button.mat-warn.mat-button-disabled,.mat-flat-button.mat-button-disabled.mat-button-disabled,.mat-raised-button.mat-primary.mat-button-disabled,.mat-raised-button.mat-accent.mat-button-disabled,.mat-raised-button.mat-warn.mat-button-disabled,.mat-raised-button.mat-button-disabled.mat-button-disabled,.mat-fab.mat-primary.mat-button-disabled,.mat-fab.mat-accent.mat-button-disabled,.mat-fab.mat-warn.mat-button-disabled,.mat-fab.mat-button-disabled.mat-button-disabled,.mat-mini-fab.mat-primary.mat-button-disabled,.mat-mini-fab.mat-accent.mat-button-disabled,.mat-mini-fab.mat-warn.mat-button-disabled,.mat-mini-fab.mat-button-disabled.mat-button-disabled{
    background-color:rgba(0,0,0,.12)
}
.mat-flat-button.mat-primary .mat-ripple-element,.mat-raised-button.mat-primary .mat-ripple-element,.mat-fab.mat-primary .mat-ripple-element,.mat-mini-fab.mat-primary .mat-ripple-element{
    background-color:rgba(255,255,255,.1)
}
.mat-flat-button.mat-accent .mat-ripple-element,.mat-raised-button.mat-accent .mat-ripple-element,.mat-fab.mat-accent .mat-ripple-element,.mat-mini-fab.mat-accent .mat-ripple-element{
    background-color:rgba(255,255,255,.1)
}
.mat-flat-button.mat-warn .mat-ripple-element,.mat-raised-button.mat-warn .mat-ripple-element,.mat-fab.mat-warn .mat-ripple-element,.mat-mini-fab.mat-warn .mat-ripple-element{
    background-color:rgba(255,255,255,.1)
}
.mat-stroked-button:not([class*=mat-elevation-z]),.mat-flat-button:not([class*=mat-elevation-z]){
    box-shadow:0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)
}
.mat-raised-button:not([class*=mat-elevation-z]){
    box-shadow:0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]){
    box-shadow:0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]){
    box-shadow:0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)
}
.mat-fab:not([class*=mat-elevation-z]),.mat-mini-fab:not([class*=mat-elevation-z]){
    box-shadow:0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]),.mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]){
    box-shadow:0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12)
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]),.mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]){
    box-shadow:0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)
}
.mat-datepicker-content .mat-mdc-button.mat-unthemed{
    --mdc-text-button-label-text-color:#000
}
.mat-datepicker-content .mat-mdc-button.mat-primary{
    --mdc-text-button-label-text-color:$color-primary
}
.mat-datepicker-content .mat-mdc-button.mat-accent{
    --mdc-text-button-label-text-color:$color-accent
}
.mat-datepicker-content .mat-mdc-button.mat-warn{
    --mdc-text-button-label-text-color:#f44336
}
.mat-datepicker-content .mat-mdc-button[disabled][disabled]{
    --mdc-text-button-disabled-label-text-color:rgba(0, 0, 0, 0.38);
    --mdc-text-button-label-text-color:rgba(0, 0, 0, 0.38)
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-unthemed{
    --mdc-filled-button-container-color:#fff;
    --mdc-filled-button-label-text-color:#000
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-primary{
    --mdc-filled-button-container-color:$color-primary;
    --mdc-filled-button-label-text-color:#fff
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-accent{
    --mdc-filled-button-container-color:$color-accent;
    --mdc-filled-button-label-text-color:#fff
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-warn{
    --mdc-filled-button-container-color:#f44336;
    --mdc-filled-button-label-text-color:#fff
}
.mat-datepicker-content .mat-mdc-unelevated-button[disabled][disabled]{
    --mdc-filled-button-disabled-container-color:rgba(0, 0, 0, 0.12);
    --mdc-filled-button-disabled-label-text-color:rgba(0, 0, 0, 0.38);
    --mdc-filled-button-container-color:rgba(0, 0, 0, 0.12);
    --mdc-filled-button-label-text-color:rgba(0, 0, 0, 0.38)
}
.mat-datepicker-content .mat-mdc-raised-button.mat-unthemed{
    --mdc-protected-button-container-color:#fff;
    --mdc-protected-button-label-text-color:#000
}
.mat-datepicker-content .mat-mdc-raised-button.mat-primary{
    --mdc-protected-button-container-color:$color-primary;
    --mdc-protected-button-label-text-color:#fff
}
.mat-datepicker-content .mat-mdc-raised-button.mat-accent{
    --mdc-protected-button-container-color:$color-accent;
    --mdc-protected-button-label-text-color:#fff
}
.mat-datepicker-content .mat-mdc-raised-button.mat-warn{
    --mdc-protected-button-container-color:#f44336;
    --mdc-protected-button-label-text-color:#fff
}
.mat-datepicker-content .mat-mdc-raised-button[disabled][disabled]{
    --mdc-protected-button-disabled-container-color:rgba(0, 0, 0, 0.12);
    --mdc-protected-button-disabled-label-text-color:rgba(0, 0, 0, 0.38);
    --mdc-protected-button-container-color:rgba(0, 0, 0, 0.12);
    --mdc-protected-button-label-text-color:rgba(0, 0, 0, 0.38);
    --mdc-protected-button-container-elevation:0
}
.mat-datepicker-content .mat-mdc-outlined-button{
    --mdc-outlined-button-outline-color:rgba(0, 0, 0, 0.12)
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-unthemed{
    --mdc-outlined-button-label-text-color:#000
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-primary{
    --mdc-outlined-button-label-text-color:$color-primary
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-accent{
    --mdc-outlined-button-label-text-color:$color-accent
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-warn{
    --mdc-outlined-button-label-text-color:#f44336
}
.mat-datepicker-content .mat-mdc-outlined-button[disabled][disabled]{
    --mdc-outlined-button-label-text-color:rgba(0, 0, 0, 0.38);
    --mdc-outlined-button-disabled-label-text-color:rgba(0, 0, 0, 0.38);
    --mdc-outlined-button-outline-color:rgba(0, 0, 0, 0.12);
    --mdc-outlined-button-disabled-outline-color:rgba(0, 0, 0, 0.12)
}
.mat-datepicker-content .mat-mdc-button,.mat-datepicker-content .mat-mdc-outlined-button{
    --mat-mdc-button-persistent-ripple-color: #000;
    --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1)
}
.mat-datepicker-content .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,.mat-datepicker-content .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before{
    opacity:.04
}
.mat-datepicker-content .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,.mat-datepicker-content .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,.mat-datepicker-content .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,.mat-datepicker-content .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before{
    opacity:.12
}
.mat-datepicker-content .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,.mat-datepicker-content .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before{
    opacity:.12
}
.mat-datepicker-content .mat-mdc-button.mat-primary,.mat-datepicker-content .mat-mdc-outlined-button.mat-primary{
    --mat-mdc-button-persistent-ripple-color: $color-primary;
    --mat-mdc-button-ripple-color: rgba(63, 81, 181, 0.1)
}
.mat-datepicker-content .mat-mdc-button.mat-accent,.mat-datepicker-content .mat-mdc-outlined-button.mat-accent{
    --mat-mdc-button-persistent-ripple-color: $color-accent;
    --mat-mdc-button-ripple-color: rgba(255, 64, 129, 0.1)
}
.mat-datepicker-content .mat-mdc-button.mat-warn,.mat-datepicker-content .mat-mdc-outlined-button.mat-warn{
    --mat-mdc-button-persistent-ripple-color: #f44336;
    --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1)
}
.mat-datepicker-content .mat-mdc-raised-button,.mat-datepicker-content .mat-mdc-unelevated-button{
    --mat-mdc-button-persistent-ripple-color: #000;
    --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1)
}
.mat-datepicker-content .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,.mat-datepicker-content .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before{
    opacity:.04
}
.mat-datepicker-content .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,.mat-datepicker-content .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,.mat-datepicker-content .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,.mat-datepicker-content .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before{
    opacity:.12
}
.mat-datepicker-content .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,.mat-datepicker-content .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before{
    opacity:.12
}
.mat-datepicker-content .mat-mdc-raised-button.mat-primary,.mat-datepicker-content .mat-mdc-unelevated-button.mat-primary{
    --mat-mdc-button-persistent-ripple-color: #fff;
    --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1)
}
.mat-datepicker-content .mat-mdc-raised-button.mat-accent,.mat-datepicker-content .mat-mdc-unelevated-button.mat-accent{
    --mat-mdc-button-persistent-ripple-color: #fff;
    --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1)
}
.mat-datepicker-content .mat-mdc-raised-button.mat-warn,.mat-datepicker-content .mat-mdc-unelevated-button.mat-warn{
    --mat-mdc-button-persistent-ripple-color: #fff;
    --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1)
}
.mat-datepicker-content .mat-mdc-icon-button{
    --mat-mdc-button-persistent-ripple-color: #000;
    --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
    --mdc-icon-button-icon-color:inherit;
    --mat-mdc-button-persistent-ripple-color: #000;
    --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1)
}
.mat-datepicker-content .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before{
    opacity:.04
}
.mat-datepicker-content .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,.mat-datepicker-content .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before{
    opacity:.12
}
.mat-datepicker-content .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before{
    opacity:.12
}
.mat-datepicker-content .mat-mdc-icon-button.mat-primary{
    --mat-mdc-button-persistent-ripple-color: #6200ee;
    --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1)
}
.mat-datepicker-content .mat-mdc-icon-button.mat-accent{
    --mat-mdc-button-persistent-ripple-color: #018786;
    --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1)
}
.mat-datepicker-content .mat-mdc-icon-button.mat-warn{
    --mat-mdc-button-persistent-ripple-color: #b00020;
    --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1)
}
.mat-datepicker-content .mat-mdc-icon-button.mat-primary{
    --mdc-icon-button-icon-color:$color-primary;
    --mat-mdc-button-persistent-ripple-color: $color-primary;
    --mat-mdc-button-ripple-color: rgba(63, 81, 181, 0.1)
}
.mat-datepicker-content .mat-mdc-icon-button.mat-accent{
    --mdc-icon-button-icon-color:$color-accent;
    --mat-mdc-button-persistent-ripple-color: $color-accent;
    --mat-mdc-button-ripple-color: rgba(255, 64, 129, 0.1)
}
.mat-datepicker-content .mat-mdc-icon-button.mat-warn{
    --mdc-icon-button-icon-color:#f44336;
    --mat-mdc-button-persistent-ripple-color: #f44336;
    --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1)
}
.mat-datepicker-content .mat-mdc-icon-button[disabled][disabled]{
    --mdc-icon-button-icon-color:rgba(0, 0, 0, 0.38);
    --mdc-icon-button-disabled-icon-color:rgba(0, 0, 0, 0.38)
}
.mat-datepicker-content .mat-mdc-button.mat-mdc-button-base,.mat-datepicker-content .mat-mdc-raised-button.mat-mdc-button-base,.mat-datepicker-content .mat-mdc-unelevated-button.mat-mdc-button-base,.mat-datepicker-content .mat-mdc-outlined-button.mat-mdc-button-base{
    height:36px
}
.mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base{
    --mdc-icon-button-state-layer-size:48px;
    width:var(--mdc-icon-button-state-layer-size);
    height:var(--mdc-icon-button-state-layer-size);
    padding:12px
}
.mat-button,.mat-raised-button,.mat-icon-button,.mat-stroked-button,.mat-flat-button,.mat-fab,.mat-mini-fab{
    font-family:Roboto, sans-serif;
    font-size:14px;
    font-weight:500
}
.mat-datepicker-content .mdc-button{
    -moz-osx-font-smoothing:grayscale;
    -webkit-font-smoothing:antialiased;
    font-family:var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
    font-size:var(--mdc-typography-button-font-size, 14px);
    line-height:var(--mdc-typography-button-line-height, 36px);
    font-weight:var(--mdc-typography-button-font-weight, 500);
    letter-spacing:var(--mdc-typography-button-letter-spacing, 0.0892857143em);
    text-decoration:var(--mdc-typography-button-text-decoration, none);
    text-transform:var(--mdc-typography-button-text-transform, none)
}
.mat-option{
    color:rgba(0,0,0,.87)
}
.mat-option:hover:not(.mat-option-disabled),.mat-option:focus:not(.mat-option-disabled){
    background:rgba(0,0,0,.04)
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled){
    background:rgba(0,0,0,.04)
}
.mat-option.mat-active{
    background:rgba(0,0,0,.04);
    color:rgba(0,0,0,.87)
}
.mat-option.mat-option-disabled{
    color:rgba(0,0,0,.38)
}
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled){
    color:$color-primary
}
.mat-accent .mat-option.mat-selected:not(.mat-option-disabled){
    color:$color-accent
}
.mat-warn .mat-option.mat-selected:not(.mat-option-disabled){
    color:#f44336
}
.mat-option{
    font-family:Roboto, sans-serif;
    font-size:16px
}
.mat-optgroup-label{
    color:rgba(0,0,0,.54)
}
.mat-optgroup-disabled .mat-optgroup-label{
    color:rgba(0,0,0,.38)
}
.mat-optgroup-label{
    font-size:16px;
    font-weight:400;
    line-height:24px;
    font-family:Roboto, sans-serif;
    letter-spacing:.03125em
}
.mat-ripple-element{
    background-color:rgba(0,0,0,.1)
}
.mat-pseudo-checkbox-full{
    color:rgba(0,0,0,.54)
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled{
    color:#b0b0b0
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after{
    color:$color-primary
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full{
    background:$color-primary
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after{
    color:#fafafa
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after{
    color:$color-accent
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full{
    background:$color-accent
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after{
    color:#fafafa
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after{
    color:$color-accent
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full{
    background:$color-accent
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after{
    color:#fafafa
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after{
    color:#f44336
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full{
    background:#f44336
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after{
    color:#fafafa
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after{
    color:#b0b0b0
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full{
    background:#b0b0b0
}
.mat-app-background{
    background-color:#fafafa;
    color:rgba(0,0,0,.87)
}
.mat-elevation-z0{
    box-shadow:0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)
}
.mat-elevation-z1{
    box-shadow:0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)
}
.mat-elevation-z2{
    box-shadow:0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)
}
.mat-elevation-z3{
    box-shadow:0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)
}
.mat-elevation-z4{
    box-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)
}
.mat-elevation-z5{
    box-shadow:0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)
}
.mat-elevation-z6{
    box-shadow:0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)
}
.mat-elevation-z7{
    box-shadow:0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12)
}
.mat-elevation-z8{
    box-shadow:0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)
}
.mat-elevation-z9{
    box-shadow:0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12)
}
.mat-elevation-z10{
    box-shadow:0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)
}
.mat-elevation-z11{
    box-shadow:0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12)
}
.mat-elevation-z12{
    box-shadow:0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12)
}
.mat-elevation-z13{
    box-shadow:0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12)
}
.mat-elevation-z14{
    box-shadow:0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12)
}
.mat-elevation-z15{
    box-shadow:0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12)
}
.mat-elevation-z16{
    box-shadow:0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)
}
.mat-elevation-z17{
    box-shadow:0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12)
}
.mat-elevation-z18{
    box-shadow:0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12)
}
.mat-elevation-z19{
    box-shadow:0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12)
}
.mat-elevation-z20{
    box-shadow:0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12)
}
.mat-elevation-z21{
    box-shadow:0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12)
}
.mat-elevation-z22{
    box-shadow:0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12)
}
.mat-elevation-z23{
    box-shadow:0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12)
}
.mat-elevation-z24{
    box-shadow:0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)
}
.mat-theme-loaded-marker{
    display:none
}
.mat-card{
    background:#fff;
    color:rgba(0,0,0,.87)
}
.mat-card:not([class*=mat-elevation-z]){
    box-shadow:0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]){
    box-shadow:0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)
}
.mat-card-subtitle{
    color:rgba(0,0,0,.54)
}
.mat-card{
    font-family:Roboto, sans-serif
}
.mat-card-title{
    font-size:24px;
    font-weight:500
}
.mat-card-header .mat-card-title{
    font-size:20px
}
.mat-card-subtitle,.mat-card-content{
    font-size:14px
}
.mat-progress-bar-background{
    fill:#cbd0e9
}
.mat-progress-bar-buffer{
    background-color:#cbd0e9
}
.mat-progress-bar-fill::after{
    background-color:$color-primary
}
.mat-progress-bar.mat-accent .mat-progress-bar-background{
    fill:#fbccdc
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer{
    background-color:#fbccdc
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after{
    background-color:$color-accent
}
.mat-progress-bar.mat-warn .mat-progress-bar-background{
    fill:#f9ccc9
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer{
    background-color:#f9ccc9
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after{
    background-color:#f44336
}
.mat-progress-spinner circle,.mat-spinner circle{
    stroke:$color-primary
}
.mat-progress-spinner.mat-accent circle,.mat-spinner.mat-accent circle{
    stroke:$color-accent
}
.mat-progress-spinner.mat-warn circle,.mat-spinner.mat-warn circle{
    stroke:#f44336
}
.mat-tooltip{
    background:rgba(97,97,97,.9)
}
.mat-tooltip{
    font-family:Roboto, sans-serif;
    font-size:10px;
    padding-top:6px;
    padding-bottom:6px
}
.mat-tooltip-handset{
    font-size:14px;
    padding-top:8px;
    padding-bottom:8px
}
.mat-form-field-type-mat-native-select .mat-form-field-infix::after{
    color:rgba(0,0,0,.54)
}
.mat-input-element:disabled,.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after{
    color:rgba(0,0,0,.38)
}
.mat-input-element{
    caret-color:$color-primary
}
.mat-input-element::placeholder{
    color:rgba(0,0,0,.42)
}
.mat-input-element::-moz-placeholder{
    color:rgba(0,0,0,.42)
}
.mat-input-element::-webkit-input-placeholder{
    color:rgba(0,0,0,.42)
}
.mat-input-element:-ms-input-placeholder{
    color:rgba(0,0,0,.42)
}
.mat-form-field.mat-accent .mat-input-element{
    caret-color:$color-accent
}
.mat-form-field.mat-warn .mat-input-element,.mat-form-field-invalid .mat-input-element{
    caret-color:#f44336
}
.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after{
    color:#f44336
}
.mat-form-field-disabled .mat-form-field-prefix,.mat-form-field-disabled .mat-form-field-suffix{
    color:rgba(0,0,0,.38)
}
input.mat-input-element{
    margin-top:-0.0625em
}
.mat-form-field-label{
    color:rgba(0,0,0,.6)
}
.mat-hint{
    color:rgba(0,0,0,.6)
}
.mat-form-field.mat-focused .mat-form-field-label{
    color:$color-primary
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent{
    color:$color-accent
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn{
    color:#f44336
}
.mat-focused .mat-form-field-required-marker{
    color:$color-accent
}
.mat-form-field-ripple{
    background-color:rgba(0,0,0,.87)
}
.mat-form-field.mat-focused .mat-form-field-ripple{
    background-color:$color-primary
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent{
    background-color:$color-accent
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn{
    background-color:#f44336
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after{
    color:$color-primary
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after{
    color:$color-accent
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after{
    color:#f44336
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label{
    color:#f44336
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker{
    color:#f44336
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent{
    background-color:#f44336
}
.mat-error{
    color:#f44336
}
.mat-form-field-appearance-legacy .mat-form-field-label{
    color:rgba(0,0,0,.54)
}
.mat-form-field-appearance-legacy .mat-hint{
    color:rgba(0,0,0,.54)
}
.mat-form-field-appearance-legacy .mat-form-field-underline{
    background-color:rgba(0,0,0,.42)
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline{
    background-image:linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
    background-size:4px 100%;
    background-repeat:repeat-x
}
.mat-form-field-appearance-standard .mat-form-field-underline{
    background-color:rgba(0,0,0,.42)
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline{
    background-image:linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
    background-size:4px 100%;
    background-repeat:repeat-x
}
.mat-form-field-appearance-fill .mat-form-field-flex{
    background-color:rgba(0,0,0,.04)
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex{
    background-color:rgba(0,0,0,.02)
}
.mat-form-field-appearance-fill .mat-form-field-underline::before{
    background-color:rgba(0,0,0,.42)
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label{
    color:rgba(0,0,0,.38)
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before{
    background-color:rgba(0,0,0,0)
}
.mat-form-field-appearance-outline .mat-form-field-outline{
    color:rgba(0,0,0,.12)
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick{
    color:rgba(0,0,0,.87)
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick{
    color:$color-primary
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick{
    color:$color-accent
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick{
    color:#f44336
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick{
    color:#f44336
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label{
    color:rgba(0,0,0,.38)
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline{
    color:rgba(0,0,0,.06)
}
.mat-form-field{
    font-size:inherit;
    font-weight:400;
    line-height:1.125;
    font-family:Roboto, sans-serif;
    letter-spacing:normal
}
.mat-form-field-wrapper{
    padding-bottom:1.34375em
}
.mat-form-field-prefix .mat-icon,.mat-form-field-suffix .mat-icon{
    font-size:150%;
    line-height:1.125
}
.mat-form-field-prefix .mat-icon-button,.mat-form-field-suffix .mat-icon-button{
    height:1.5em;
    width:1.5em
}
.mat-form-field-prefix .mat-icon-button .mat-icon,.mat-form-field-suffix .mat-icon-button .mat-icon{
    height:1.125em;
    line-height:1.125
}
.mat-form-field-infix{
    padding:.5em 0;
    border-top:.84375em solid rgba(0,0,0,0)
}
.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label{
    transform:translateY(-1.34375em) scale(0.75);
    width:133.3333333333%
}
.mat-form-field-can-float .mat-input-server[label]+.mat-form-field-label-wrapper .mat-form-field-label{
    transform:translateY(-1.34374em) scale(0.75);
    width:133.3333433333%
}
.mat-form-field-label-wrapper{
    top:-0.84375em;
    padding-top:.84375em
}
.mat-form-field-label{
    top:1.34375em
}
.mat-form-field-underline{
    bottom:1.34375em
}
.mat-form-field-subscript-wrapper{
    font-size:75%;
    margin-top:.6666666667em;
    top:calc(100% - 1.7916666667em)
}
.mat-form-field-appearance-legacy .mat-form-field-wrapper{
    padding-bottom:1.25em
}
.mat-form-field-appearance-legacy .mat-form-field-infix{
    padding:.4375em 0
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label{
    transform:translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
    width:133.3333333333%
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill+.mat-form-field-label-wrapper .mat-form-field-label{
    transform:translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
    width:133.3333433333%
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]+.mat-form-field-label-wrapper .mat-form-field-label{
    transform:translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
    width:133.3333533333%
}
.mat-form-field-appearance-legacy .mat-form-field-label{
    top:1.28125em
}
.mat-form-field-appearance-legacy .mat-form-field-underline{
    bottom:1.25em
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper{
    margin-top:.5416666667em;
    top:calc(100% - 1.6666666667em)
}
@media print{
    .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label{
        transform:translateY(-1.28122em) scale(0.75)
    }
    .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill+.mat-form-field-label-wrapper .mat-form-field-label{
        transform:translateY(-1.28121em) scale(0.75)
    }
    .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]+.mat-form-field-label-wrapper .mat-form-field-label{
        transform:translateY(-1.2812em) scale(0.75)
    }
}
.mat-form-field-appearance-fill .mat-form-field-infix{
    padding:.25em 0 .75em 0
}
.mat-form-field-appearance-fill .mat-form-field-label{
    top:1.09375em;
    margin-top:-0.5em
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label{
    transform:translateY(-0.59375em) scale(0.75);
    width:133.3333333333%
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]+.mat-form-field-label-wrapper .mat-form-field-label{
    transform:translateY(-0.59374em) scale(0.75);
    width:133.3333433333%
}
.mat-form-field-appearance-outline .mat-form-field-infix{
    padding:1em 0 1em 0
}
.mat-form-field-appearance-outline .mat-form-field-label{
    top:1.84375em;
    margin-top:-0.25em
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label{
    transform:translateY(-1.59375em) scale(0.75);
    width:133.3333333333%
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]+.mat-form-field-label-wrapper .mat-form-field-label{
    transform:translateY(-1.59374em) scale(0.75);
    width:133.3333433333%
}
.mat-select-value{
    color:rgba(0,0,0,.87)
}
.mat-select-placeholder{
    color:rgba(0,0,0,.42)
}
.mat-select-disabled .mat-select-value{
    color:rgba(0,0,0,.38)
}
.mat-select-arrow{
    color:rgba(0,0,0,.54)
}
.mat-select-panel{
    background:#fff
}
.mat-select-panel:not([class*=mat-elevation-z]){
    box-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple){
    background:rgba(0,0,0,.12)
}
.mat-form-field.mat-focused.mat-primary .mat-select-arrow{
    color:$color-primary
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow{
    color:$color-accent
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow{
    color:#f44336
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow{
    color:#f44336
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow{
    color:rgba(0,0,0,.38)
}
.mat-select{
    font-family:Roboto, sans-serif
}
.mat-select-trigger{
    height:1.125em
}
.mat-checkbox-frame{
    border-color:rgba(0,0,0,.54)
}
.mat-checkbox-checkmark{
    fill:#fafafa
}
.mat-checkbox-checkmark-path{
    stroke:#fafafa !important
}
.mat-checkbox-mixedmark{
    background-color:#fafafa
}
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,.mat-checkbox-checked.mat-primary .mat-checkbox-background{
    background-color:$color-primary
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,.mat-checkbox-checked.mat-accent .mat-checkbox-background{
    background-color:$color-accent
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background,.mat-checkbox-checked.mat-warn .mat-checkbox-background{
    background-color:#f44336
}
.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background{
    background-color:#b0b0b0
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame{
    border-color:#b0b0b0
}
.mat-checkbox-disabled .mat-checkbox-label{
    color:rgba(0,0,0,.38)
}
.mat-checkbox .mat-ripple-element{
    background-color:#000
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element{
    background:$color-primary
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element{
    background:$color-accent
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element{
    background:#f44336
}
.mat-checkbox{
    font-family:Roboto, sans-serif
}
.mat-checkbox-layout .mat-checkbox-label{
    line-height:24px
}
.mat-autocomplete-panel{
    background:#fff;
    color:rgba(0,0,0,.87)
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]){
    box-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover){
    background:#fff
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled){
    color:rgba(0,0,0,.87)
}
.mat-dialog-container{
    box-shadow:0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    background:#fff;
    color:rgba(0,0,0,.87)
}
.mat-dialog-title{
    font-size:20px;
    font-weight:500;
    line-height:32px;
    font-family:Roboto, sans-serif;
    letter-spacing:.0125em
}
.mat-chip.mat-standard-chip{
    background-color:#e0e0e0;
    color:rgba(0,0,0,.87)
}
.mat-chip.mat-standard-chip .mat-chip-remove{
    color:rgba(0,0,0,.87);
    opacity:.4
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active{
    box-shadow:0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover{
    opacity:.54
}
.mat-chip.mat-standard-chip.mat-chip-disabled{
    opacity:.4
}
.mat-chip.mat-standard-chip::after{
    background:#000
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary{
    background-color:$color-primary;
    color:#fff
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove{
    color:#fff;
    opacity:.4
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element{
    background-color:rgba(255,255,255,.1)
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn{
    background-color:#f44336;
    color:#fff
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove{
    color:#fff;
    opacity:.4
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element{
    background-color:rgba(255,255,255,.1)
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent{
    background-color:$color-accent;
    color:#fff
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove{
    color:#fff;
    opacity:.4
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element{
    background-color:rgba(255,255,255,.1)
}
.mat-chip{
    font-size:16px;
    font-weight:400
}
.mat-chip .mat-chip-trailing-icon.mat-icon,.mat-chip .mat-chip-remove.mat-icon{
    font-size:18px
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb{
    background-color:$color-accent
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar{
    background-color:rgba(255,64,129,.54)
}
.mat-slide-toggle.mat-checked .mat-ripple-element{
    background-color:$color-accent
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb{
    background-color:$color-primary
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar{
    background-color:rgba(63,81,181,.54)
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element{
    background-color:$color-primary
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb{
    background-color:#f44336
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar{
    background-color:rgba(244,67,54,.54)
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element{
    background-color:#f44336
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element{
    background-color:#000
}
.mat-slide-toggle-thumb{
    box-shadow:0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    background-color:#fafafa
}
.mat-slide-toggle-bar{
    background-color:rgba(0,0,0,.38)
}
.mat-slide-toggle-content{
    font-family:Roboto, sans-serif
}
.mat-radio-outer-circle{
    border-color:rgba(0,0,0,.54)
}
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle{
    border-color:$color-primary
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,.mat-radio-button.mat-primary:active .mat-radio-persistent-ripple{
    background-color:$color-primary
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
    border-color:$color-accent
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple{
    background-color:$color-accent
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle{
    border-color:#f44336
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple,.mat-radio-button.mat-warn:active .mat-radio-persistent-ripple{
    background-color:#f44336
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle{
    border-color:rgba(0,0,0,.38)
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle{
    background-color:rgba(0,0,0,.38)
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content{
    color:rgba(0,0,0,.38)
}
.mat-radio-button .mat-ripple-element{
    background-color:#000
}
.mat-radio-button{
    font-family:Roboto, sans-serif
}
.mat-slider-track-background{
    background-color:rgba(0,0,0,.26)
}
.mat-slider.mat-primary .mat-slider-track-fill,.mat-slider.mat-primary .mat-slider-thumb,.mat-slider.mat-primary .mat-slider-thumb-label{
    background-color:$color-primary
}
.mat-slider.mat-primary .mat-slider-thumb-label-text{
    color:#fff
}
.mat-slider.mat-primary .mat-slider-focus-ring{
    background-color:rgba(63,81,181,.2)
}
.mat-slider.mat-accent .mat-slider-track-fill,.mat-slider.mat-accent .mat-slider-thumb,.mat-slider.mat-accent .mat-slider-thumb-label{
    background-color:$color-accent
}
.mat-slider.mat-accent .mat-slider-thumb-label-text{
    color:#fff
}
.mat-slider.mat-accent .mat-slider-focus-ring{
    background-color:rgba(255,64,129,.2)
}
.mat-slider.mat-warn .mat-slider-track-fill,.mat-slider.mat-warn .mat-slider-thumb,.mat-slider.mat-warn .mat-slider-thumb-label{
    background-color:#f44336
}
.mat-slider.mat-warn .mat-slider-thumb-label-text{
    color:#fff
}
.mat-slider.mat-warn .mat-slider-focus-ring{
    background-color:rgba(244,67,54,.2)
}
.mat-slider:hover .mat-slider-track-background,.mat-slider.cdk-focused .mat-slider-track-background{
    background-color:rgba(0,0,0,.38)
}
.mat-slider.mat-slider-disabled .mat-slider-track-background,.mat-slider.mat-slider-disabled .mat-slider-track-fill,.mat-slider.mat-slider-disabled .mat-slider-thumb{
    background-color:rgba(0,0,0,.26)
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background{
    background-color:rgba(0,0,0,.26)
}
.mat-slider.mat-slider-min-value .mat-slider-focus-ring{
    background-color:rgba(0,0,0,.12)
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label{
    background-color:rgba(0,0,0,.87)
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label{
    background-color:rgba(0,0,0,.26)
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb{
    border-color:rgba(0,0,0,.26);
    background-color:rgba(0,0,0,0)
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb,.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb{
    border-color:rgba(0,0,0,.38)
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb,.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb{
    border-color:rgba(0,0,0,.26)
}
.mat-slider-has-ticks .mat-slider-wrapper::after{
    border-color:rgba(0,0,0,.7)
}
.mat-slider-horizontal .mat-slider-ticks{
    background-image:repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
    background-image:-moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent)
}
.mat-slider-vertical .mat-slider-ticks{
    background-image:repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent)
}
.mat-slider-thumb-label-text{
    font-family:Roboto, sans-serif;
    font-size:12px;
    font-weight:400
}
.mat-menu-panel{
    background:#fff
}
.mat-menu-panel:not([class*=mat-elevation-z]){
    box-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)
}
.mat-menu-item{
    background:rgba(0,0,0,0);
    color:rgba(0,0,0,.87)
}
.mat-menu-item[disabled],.mat-menu-item[disabled] .mat-menu-submenu-icon,.mat-menu-item[disabled] .mat-icon-no-color{
    color:rgba(0,0,0,.38)
}
.mat-menu-item .mat-icon-no-color,.mat-menu-submenu-icon{
    color:rgba(0,0,0,.54)
}
.mat-menu-item:hover:not([disabled]),.mat-menu-item.cdk-program-focused:not([disabled]),.mat-menu-item.cdk-keyboard-focused:not([disabled]),.mat-menu-item-highlighted:not([disabled]){
    background:rgba(0,0,0,.04)
}
.mat-menu-item{
    font-family:Roboto, sans-serif;
    font-size:14px;
    font-weight:400
}
.mat-list-base .mat-list-item{
    color:rgba(0,0,0,.87)
}
.mat-list-base .mat-list-option{
    color:rgba(0,0,0,.87)
}
.mat-list-base .mat-subheader{
    color:rgba(0,0,0,.54)
}
.mat-list-base .mat-list-item-disabled{
    background-color:#eee;
    color:rgba(0,0,0,.38)
}
.mat-list-option:hover,.mat-list-option:focus,.mat-nav-list .mat-list-item:hover,.mat-nav-list .mat-list-item:focus,.mat-action-list .mat-list-item:hover,.mat-action-list .mat-list-item:focus{
    background:rgba(0,0,0,.04)
}
.mat-list-single-selected-option,.mat-list-single-selected-option:hover,.mat-list-single-selected-option:focus{
    background:rgba(0,0,0,.12)
}
.mat-list-item{
    font-family:Roboto, sans-serif
}
.mat-list-option{
    font-family:Roboto, sans-serif
}
.mat-list-base .mat-list-item{
    font-size:16px
}
.mat-list-base .mat-list-item .mat-line{
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    display:block;
    box-sizing:border-box
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2){
    font-size:14px
}
.mat-list-base .mat-list-option{
    font-size:16px
}
.mat-list-base .mat-list-option .mat-line{
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    display:block;
    box-sizing:border-box
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2){
    font-size:14px
}
.mat-list-base .mat-subheader{
    font-family:Roboto, sans-serif;
    font-size:16px;
    font-weight:400
}
.mat-list-base[dense] .mat-list-item{
    font-size:12px
}
.mat-list-base[dense] .mat-list-item .mat-line{
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    display:block;
    box-sizing:border-box
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2){
    font-size:12px
}
.mat-list-base[dense] .mat-list-option{
    font-size:12px
}
.mat-list-base[dense] .mat-list-option .mat-line{
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    display:block;
    box-sizing:border-box
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2){
    font-size:12px
}
.mat-list-base[dense] .mat-subheader{
    font-family:Roboto, sans-serif;
    font-size:12px;
    font-weight:400
}
.mat-paginator{
    background:#fff
}
.mat-paginator,.mat-paginator-page-size .mat-select-trigger{
    color:rgba(0,0,0,.54)
}
.mat-paginator-decrement,.mat-paginator-increment{
    border-top:2px solid rgba(0,0,0,.54);
    border-right:2px solid rgba(0,0,0,.54)
}
.mat-paginator-first,.mat-paginator-last{
    border-top:2px solid rgba(0,0,0,.54)
}
.mat-icon-button[disabled] .mat-paginator-decrement,.mat-icon-button[disabled] .mat-paginator-increment,.mat-icon-button[disabled] .mat-paginator-first,.mat-icon-button[disabled] .mat-paginator-last{
    border-color:rgba(0,0,0,.38)
}
.mat-paginator-container{
    min-height:56px
}
.mat-paginator,.mat-paginator-page-size .mat-select-trigger{
    font-family:Roboto, sans-serif;
    font-size:12px
}
.mat-tab-nav-bar,.mat-tab-header{
    border-bottom:1px solid rgba(0,0,0,.12)
}
.mat-tab-group-inverted-header .mat-tab-nav-bar,.mat-tab-group-inverted-header .mat-tab-header{
    border-top:1px solid rgba(0,0,0,.12);
    border-bottom:none
}
.mat-tab-label,.mat-tab-link{
    color:rgba(0,0,0,.87)
}
.mat-tab-label.mat-tab-disabled,.mat-tab-link.mat-tab-disabled{
    color:rgba(0,0,0,.38)
}
.mat-tab-header-pagination-chevron{
    border-color:rgba(0,0,0,.87)
}
.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron{
    border-color:rgba(0,0,0,.38)
}
.mat-tab-group[class*=mat-background-]>.mat-tab-header,.mat-tab-nav-bar[class*=mat-background-]{
    border-bottom:none;
    border-top:none
}
.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused,.mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused,.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused,.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused,.mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused,.mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused,.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused,.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused{
    background-color:rgba(197,202,233,.3)
}
.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled,.mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled,.mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled,.mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled{
    background-color:rgba(0,0,0,.1)
}
.mat-tab-group.mat-primary .mat-ink-bar,.mat-tab-nav-bar.mat-primary .mat-ink-bar{
    background-color:$color-primary
}
.mat-tab-group.mat-primary.mat-background-primary>.mat-tab-header .mat-ink-bar,.mat-tab-group.mat-primary.mat-background-primary>.mat-tab-link-container .mat-ink-bar,.mat-tab-nav-bar.mat-primary.mat-background-primary>.mat-tab-header .mat-ink-bar,.mat-tab-nav-bar.mat-primary.mat-background-primary>.mat-tab-link-container .mat-ink-bar{
    background-color:#fff
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused,.mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused,.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused,.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused,.mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused,.mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused,.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused,.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused{
    background-color:rgba(255,128,171,.3)
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled,.mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled,.mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled,.mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled{
    background-color:rgba(0,0,0,.1)
}
.mat-tab-group.mat-accent .mat-ink-bar,.mat-tab-nav-bar.mat-accent .mat-ink-bar{
    background-color:$color-accent
}
.mat-tab-group.mat-accent.mat-background-accent>.mat-tab-header .mat-ink-bar,.mat-tab-group.mat-accent.mat-background-accent>.mat-tab-link-container .mat-ink-bar,.mat-tab-nav-bar.mat-accent.mat-background-accent>.mat-tab-header .mat-ink-bar,.mat-tab-nav-bar.mat-accent.mat-background-accent>.mat-tab-link-container .mat-ink-bar{
    background-color:#fff
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused,.mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused,.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused,.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused,.mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused,.mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused,.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused,.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused{
    background-color:rgba(255,205,210,.3)
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled,.mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled,.mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled,.mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled{
    background-color:rgba(0,0,0,.1)
}
.mat-tab-group.mat-warn .mat-ink-bar,.mat-tab-nav-bar.mat-warn .mat-ink-bar{
    background-color:#f44336
}
.mat-tab-group.mat-warn.mat-background-warn>.mat-tab-header .mat-ink-bar,.mat-tab-group.mat-warn.mat-background-warn>.mat-tab-link-container .mat-ink-bar,.mat-tab-nav-bar.mat-warn.mat-background-warn>.mat-tab-header .mat-ink-bar,.mat-tab-nav-bar.mat-warn.mat-background-warn>.mat-tab-link-container .mat-ink-bar{
    background-color:#fff
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused,.mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused,.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused,.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused,.mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused,.mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused,.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused,.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused{
    background-color:rgba(197,202,233,.3)
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled,.mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled,.mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled,.mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled{
    background-color:rgba(0,0,0,.1)
}
.mat-tab-group.mat-background-primary>.mat-tab-header,.mat-tab-group.mat-background-primary>.mat-tab-link-container,.mat-tab-group.mat-background-primary>.mat-tab-header-pagination,.mat-tab-nav-bar.mat-background-primary>.mat-tab-header,.mat-tab-nav-bar.mat-background-primary>.mat-tab-link-container,.mat-tab-nav-bar.mat-background-primary>.mat-tab-header-pagination{
    background-color:$color-primary
}
.mat-tab-group.mat-background-primary>.mat-tab-header .mat-tab-label,.mat-tab-group.mat-background-primary>.mat-tab-link-container .mat-tab-link,.mat-tab-nav-bar.mat-background-primary>.mat-tab-header .mat-tab-label,.mat-tab-nav-bar.mat-background-primary>.mat-tab-link-container .mat-tab-link{
    color:#fff
}
.mat-tab-group.mat-background-primary>.mat-tab-header .mat-tab-label.mat-tab-disabled,.mat-tab-group.mat-background-primary>.mat-tab-link-container .mat-tab-link.mat-tab-disabled,.mat-tab-nav-bar.mat-background-primary>.mat-tab-header .mat-tab-label.mat-tab-disabled,.mat-tab-nav-bar.mat-background-primary>.mat-tab-link-container .mat-tab-link.mat-tab-disabled{
    color:rgba(255,255,255,.4)
}
.mat-tab-group.mat-background-primary>.mat-tab-header .mat-tab-header-pagination-chevron,.mat-tab-group.mat-background-primary>.mat-tab-header-pagination .mat-tab-header-pagination-chevron,.mat-tab-group.mat-background-primary>.mat-tab-link-container .mat-focus-indicator::before,.mat-tab-group.mat-background-primary>.mat-tab-header .mat-focus-indicator::before,.mat-tab-nav-bar.mat-background-primary>.mat-tab-header .mat-tab-header-pagination-chevron,.mat-tab-nav-bar.mat-background-primary>.mat-tab-header-pagination .mat-tab-header-pagination-chevron,.mat-tab-nav-bar.mat-background-primary>.mat-tab-link-container .mat-focus-indicator::before,.mat-tab-nav-bar.mat-background-primary>.mat-tab-header .mat-focus-indicator::before{
    border-color:#fff
}
.mat-tab-group.mat-background-primary>.mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,.mat-tab-group.mat-background-primary>.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,.mat-tab-nav-bar.mat-background-primary>.mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,.mat-tab-nav-bar.mat-background-primary>.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron{
    border-color:#fff;
    opacity:.4
}
.mat-tab-group.mat-background-primary>.mat-tab-header .mat-ripple-element,.mat-tab-group.mat-background-primary>.mat-tab-link-container .mat-ripple-element,.mat-tab-group.mat-background-primary>.mat-tab-header-pagination .mat-ripple-element,.mat-tab-nav-bar.mat-background-primary>.mat-tab-header .mat-ripple-element,.mat-tab-nav-bar.mat-background-primary>.mat-tab-link-container .mat-ripple-element,.mat-tab-nav-bar.mat-background-primary>.mat-tab-header-pagination .mat-ripple-element{
    background-color:#fff;
    opacity:.12
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused,.mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused,.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused,.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused,.mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused,.mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused,.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused,.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused{
    background-color:rgba(255,128,171,.3)
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled,.mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled,.mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled,.mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled{
    background-color:rgba(0,0,0,.1)
}
.mat-tab-group.mat-background-accent>.mat-tab-header,.mat-tab-group.mat-background-accent>.mat-tab-link-container,.mat-tab-group.mat-background-accent>.mat-tab-header-pagination,.mat-tab-nav-bar.mat-background-accent>.mat-tab-header,.mat-tab-nav-bar.mat-background-accent>.mat-tab-link-container,.mat-tab-nav-bar.mat-background-accent>.mat-tab-header-pagination{
    background-color:$color-accent
}
.mat-tab-group.mat-background-accent>.mat-tab-header .mat-tab-label,.mat-tab-group.mat-background-accent>.mat-tab-link-container .mat-tab-link,.mat-tab-nav-bar.mat-background-accent>.mat-tab-header .mat-tab-label,.mat-tab-nav-bar.mat-background-accent>.mat-tab-link-container .mat-tab-link{
    color:#fff
}
.mat-tab-group.mat-background-accent>.mat-tab-header .mat-tab-label.mat-tab-disabled,.mat-tab-group.mat-background-accent>.mat-tab-link-container .mat-tab-link.mat-tab-disabled,.mat-tab-nav-bar.mat-background-accent>.mat-tab-header .mat-tab-label.mat-tab-disabled,.mat-tab-nav-bar.mat-background-accent>.mat-tab-link-container .mat-tab-link.mat-tab-disabled{
    color:rgba(255,255,255,.4)
}
.mat-tab-group.mat-background-accent>.mat-tab-header .mat-tab-header-pagination-chevron,.mat-tab-group.mat-background-accent>.mat-tab-header-pagination .mat-tab-header-pagination-chevron,.mat-tab-group.mat-background-accent>.mat-tab-link-container .mat-focus-indicator::before,.mat-tab-group.mat-background-accent>.mat-tab-header .mat-focus-indicator::before,.mat-tab-nav-bar.mat-background-accent>.mat-tab-header .mat-tab-header-pagination-chevron,.mat-tab-nav-bar.mat-background-accent>.mat-tab-header-pagination .mat-tab-header-pagination-chevron,.mat-tab-nav-bar.mat-background-accent>.mat-tab-link-container .mat-focus-indicator::before,.mat-tab-nav-bar.mat-background-accent>.mat-tab-header .mat-focus-indicator::before{
    border-color:#fff
}
.mat-tab-group.mat-background-accent>.mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,.mat-tab-group.mat-background-accent>.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,.mat-tab-nav-bar.mat-background-accent>.mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,.mat-tab-nav-bar.mat-background-accent>.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron{
    border-color:#fff;
    opacity:.4
}
.mat-tab-group.mat-background-accent>.mat-tab-header .mat-ripple-element,.mat-tab-group.mat-background-accent>.mat-tab-link-container .mat-ripple-element,.mat-tab-group.mat-background-accent>.mat-tab-header-pagination .mat-ripple-element,.mat-tab-nav-bar.mat-background-accent>.mat-tab-header .mat-ripple-element,.mat-tab-nav-bar.mat-background-accent>.mat-tab-link-container .mat-ripple-element,.mat-tab-nav-bar.mat-background-accent>.mat-tab-header-pagination .mat-ripple-element{
    background-color:#fff;
    opacity:.12
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused,.mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused,.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused,.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused,.mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused,.mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused,.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused,.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused{
    background-color:rgba(255,205,210,.3)
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled,.mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled,.mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled,.mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled{
    background-color:rgba(0,0,0,.1)
}
.mat-tab-group.mat-background-warn>.mat-tab-header,.mat-tab-group.mat-background-warn>.mat-tab-link-container,.mat-tab-group.mat-background-warn>.mat-tab-header-pagination,.mat-tab-nav-bar.mat-background-warn>.mat-tab-header,.mat-tab-nav-bar.mat-background-warn>.mat-tab-link-container,.mat-tab-nav-bar.mat-background-warn>.mat-tab-header-pagination{
    background-color:#f44336
}
.mat-tab-group.mat-background-warn>.mat-tab-header .mat-tab-label,.mat-tab-group.mat-background-warn>.mat-tab-link-container .mat-tab-link,.mat-tab-nav-bar.mat-background-warn>.mat-tab-header .mat-tab-label,.mat-tab-nav-bar.mat-background-warn>.mat-tab-link-container .mat-tab-link{
    color:#fff
}
.mat-tab-group.mat-background-warn>.mat-tab-header .mat-tab-label.mat-tab-disabled,.mat-tab-group.mat-background-warn>.mat-tab-link-container .mat-tab-link.mat-tab-disabled,.mat-tab-nav-bar.mat-background-warn>.mat-tab-header .mat-tab-label.mat-tab-disabled,.mat-tab-nav-bar.mat-background-warn>.mat-tab-link-container .mat-tab-link.mat-tab-disabled{
    color:rgba(255,255,255,.4)
}
.mat-tab-group.mat-background-warn>.mat-tab-header .mat-tab-header-pagination-chevron,.mat-tab-group.mat-background-warn>.mat-tab-header-pagination .mat-tab-header-pagination-chevron,.mat-tab-group.mat-background-warn>.mat-tab-link-container .mat-focus-indicator::before,.mat-tab-group.mat-background-warn>.mat-tab-header .mat-focus-indicator::before,.mat-tab-nav-bar.mat-background-warn>.mat-tab-header .mat-tab-header-pagination-chevron,.mat-tab-nav-bar.mat-background-warn>.mat-tab-header-pagination .mat-tab-header-pagination-chevron,.mat-tab-nav-bar.mat-background-warn>.mat-tab-link-container .mat-focus-indicator::before,.mat-tab-nav-bar.mat-background-warn>.mat-tab-header .mat-focus-indicator::before{
    border-color:#fff
}
.mat-tab-group.mat-background-warn>.mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,.mat-tab-group.mat-background-warn>.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,.mat-tab-nav-bar.mat-background-warn>.mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,.mat-tab-nav-bar.mat-background-warn>.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron{
    border-color:#fff;
    opacity:.4
}
.mat-tab-group.mat-background-warn>.mat-tab-header .mat-ripple-element,.mat-tab-group.mat-background-warn>.mat-tab-link-container .mat-ripple-element,.mat-tab-group.mat-background-warn>.mat-tab-header-pagination .mat-ripple-element,.mat-tab-nav-bar.mat-background-warn>.mat-tab-header .mat-ripple-element,.mat-tab-nav-bar.mat-background-warn>.mat-tab-link-container .mat-ripple-element,.mat-tab-nav-bar.mat-background-warn>.mat-tab-header-pagination .mat-ripple-element{
    background-color:#fff;
    opacity:.12
}
.mat-tab-group{
    font-family:Roboto, sans-serif
}
.mat-tab-label,.mat-tab-link{
    font-family:Roboto, sans-serif;
    font-size:14px;
    font-weight:500
}
.mat-snack-bar-container{
    color:rgba(255,255,255,.7);
    background:#323232;
    box-shadow:0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)
}
.mat-simple-snackbar-action{
    color:$color-accent
}
.mat-simple-snackbar{
    font-family:Roboto, sans-serif;
    font-size:14px
}
.mat-simple-snackbar-action{
    line-height:1;
    font-family:inherit;
    font-size:inherit;
    font-weight:500
}
.mat-table{
    background:#fff
}
.mat-table thead,.mat-table tbody,.mat-table tfoot,mat-header-row,mat-row,mat-footer-row,[mat-header-row],[mat-row],[mat-footer-row],.mat-table-sticky{
    background:inherit
}
mat-row,mat-header-row,mat-footer-row,th.mat-header-cell,td.mat-cell,td.mat-footer-cell{
    border-bottom-color:rgba(0,0,0,.12)
}
.mat-header-cell{
    color:rgba(0,0,0,.54)
}
.mat-cell,.mat-footer-cell{
    color:rgba(0,0,0,.87)
}
.mat-table{
    font-family:Roboto, sans-serif
}
.mat-header-cell{
    font-size:12px;
    font-weight:400
}
.mat-cell,.mat-footer-cell{
    font-size:14px
}
.mat-badge{
    position:relative
}
.mat-badge.mat-badge{
    overflow:visible
}
.mat-badge-hidden .mat-badge-content{
    display:none
}
.mat-badge-content{
    position:absolute;
    text-align:center;
    display:inline-block;
    border-radius:50%;
    transition:transform 200ms ease-in-out;
    transform:scale(0.6);
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    pointer-events:none
}
.ng-animate-disabled .mat-badge-content,.mat-badge-content._mat-animation-noopable{
    transition:none
}
.mat-badge-content.mat-badge-active{
    transform:none
}
.mat-badge-small .mat-badge-content{
    width:16px;
    height:16px;
    line-height:16px
}
.mat-badge-small.mat-badge-above .mat-badge-content{
    top:-8px
}
.mat-badge-small.mat-badge-below .mat-badge-content{
    bottom:-8px
}
.mat-badge-small.mat-badge-before .mat-badge-content{
    left:-16px
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content{
    left:auto;
    right:-16px
}
.mat-badge-small.mat-badge-after .mat-badge-content{
    right:-16px
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content{
    right:auto;
    left:-16px
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content{
    left:-8px
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content{
    left:auto;
    right:-8px
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content{
    right:-8px
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content{
    right:auto;
    left:-8px
}
.mat-badge-medium .mat-badge-content{
    width:22px;
    height:22px;
    line-height:22px
}
.mat-badge-medium.mat-badge-above .mat-badge-content{
    top:-11px
}
.mat-badge-medium.mat-badge-below .mat-badge-content{
    bottom:-11px
}
.mat-badge-medium.mat-badge-before .mat-badge-content{
    left:-22px
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content{
    left:auto;
    right:-22px
}
.mat-badge-medium.mat-badge-after .mat-badge-content{
    right:-22px
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content{
    right:auto;
    left:-22px
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content{
    left:-11px
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content{
    left:auto;
    right:-11px
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content{
    right:-11px
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content{
    right:auto;
    left:-11px
}
.mat-badge-large .mat-badge-content{
    width:28px;
    height:28px;
    line-height:28px
}
.mat-badge-large.mat-badge-above .mat-badge-content{
    top:-14px
}
.mat-badge-large.mat-badge-below .mat-badge-content{
    bottom:-14px
}
.mat-badge-large.mat-badge-before .mat-badge-content{
    left:-28px
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content{
    left:auto;
    right:-28px
}
.mat-badge-large.mat-badge-after .mat-badge-content{
    right:-28px
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content{
    right:auto;
    left:-28px
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content{
    left:-14px
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content{
    left:auto;
    right:-14px
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content{
    right:-14px
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content{
    right:auto;
    left:-14px
}
.mat-badge-content{
    color:#fff;
    background:$color-primary
}
.cdk-high-contrast-active .mat-badge-content{
    outline:solid 1px;
    border-radius:0
}
.mat-badge-accent .mat-badge-content{
    background:$color-accent;
    color:#fff
}
.mat-badge-warn .mat-badge-content{
    color:#fff;
    background:#f44336
}
.mat-badge-disabled .mat-badge-content{
    background:#b9b9b9;
    color:rgba(0,0,0,.38)
}
.mat-badge-content{
    font-weight:600;
    font-size:12px;
    font-family:Roboto, sans-serif
}
.mat-badge-small .mat-badge-content{
    font-size:9px
}
.mat-badge-large .mat-badge-content{
    font-size:24px
}
.mat-bottom-sheet-container{
    box-shadow:0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    background:#fff;
    color:rgba(0,0,0,.87)
}
.mat-bottom-sheet-container{
    font-size:14px;
    font-weight:400;
    line-height:20px;
    font-family:Roboto, sans-serif;
    letter-spacing:.0178571429em
}
.mat-button-toggle-standalone:not([class*=mat-elevation-z]),.mat-button-toggle-group:not([class*=mat-elevation-z]){
    box-shadow:0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)
}
.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]){
    box-shadow:none
}
.mat-button-toggle{
    color:rgba(0,0,0,.38)
}
.mat-button-toggle .mat-button-toggle-focus-overlay{
    background-color:rgba(0,0,0,.12)
}
.mat-button-toggle-appearance-standard{
    color:rgba(0,0,0,.87);
    background:#fff
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay{
    background-color:#000
}
.mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle{
    border-left:solid 1px #e0e0e0
}
[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle{
    border-left:none;
    border-right:solid 1px #e0e0e0
}
.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle+.mat-button-toggle{
    border-left:none;
    border-right:none;
    border-top:solid 1px #e0e0e0
}
.mat-button-toggle-checked{
    background-color:#e0e0e0;
    color:rgba(0,0,0,.54)
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard{
    color:rgba(0,0,0,.87)
}
.mat-button-toggle-disabled{
    color:rgba(0,0,0,.26);
    background-color:#eee
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard{
    background:#fff
}
.mat-button-toggle-disabled.mat-button-toggle-checked{
    background-color:#bdbdbd
}
.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,.mat-button-toggle-group-appearance-standard{
    border:solid 1px #e0e0e0
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content{
    line-height:48px
}
.mat-button-toggle{
    font-family:Roboto, sans-serif
}
.mat-calendar-arrow{
    fill:rgba(0,0,0,.54)
}
.mat-datepicker-toggle,.mat-datepicker-content .mat-calendar-next-button,.mat-datepicker-content .mat-calendar-previous-button{
    color:rgba(0,0,0,.54)
}
.mat-calendar-table-header-divider::after{
    background:rgba(0,0,0,.12)
}
.mat-calendar-table-header,.mat-calendar-body-label{
    color:rgba(0,0,0,.54)
}
.mat-calendar-body-cell-content,.mat-date-range-input-separator{
    color:rgba(0,0,0,.87);
    border-color:rgba(0,0,0,0)
}
.mat-calendar-body-disabled>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
    color:rgba(0,0,0,.38)
}
.mat-form-field-disabled .mat-date-range-input-separator{
    color:rgba(0,0,0,.38)
}
.mat-calendar-body-in-preview{
    color:rgba(0,0,0,.24)
}
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
    border-color:rgba(0,0,0,.38)
}
.mat-calendar-body-disabled>.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
    border-color:rgba(0,0,0,.18)
}
.mat-calendar-body-in-range::before{
    background:rgba(63,81,181,.2)
}
.mat-calendar-body-comparison-identical,.mat-calendar-body-in-comparison-range::before{
    background:rgba(249,171,0,.2)
}
.mat-calendar-body-comparison-bridge-start::before,[dir=rtl] .mat-calendar-body-comparison-bridge-end::before{
    background:linear-gradient(to right, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%)
}
.mat-calendar-body-comparison-bridge-end::before,[dir=rtl] .mat-calendar-body-comparison-bridge-start::before{
    background:linear-gradient(to left, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%)
}
.mat-calendar-body-in-range>.mat-calendar-body-comparison-identical,.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after{
    background:#a8dab5
}
.mat-calendar-body-comparison-identical.mat-calendar-body-selected,.mat-calendar-body-in-comparison-range>.mat-calendar-body-selected{
    background:#46a35e
}
.mat-calendar-body-selected{
    background-color:$color-primary;
    color:#fff
}
.mat-calendar-body-disabled>.mat-calendar-body-selected{
    background-color:rgba(63,81,181,.4)
}
.mat-calendar-body-today.mat-calendar-body-selected{
    box-shadow:inset 0 0 0 1px #fff
}
.cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),.cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
    background-color:rgba(63,81,181,.3)
}
@media(hover: hover){
    .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
        background-color:rgba(63,81,181,.3)
    }
}
.mat-datepicker-content{
    box-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    background-color:#fff;
    color:rgba(0,0,0,.87)
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before{
    background:rgba(255,64,129,.2)
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before{
    background:rgba(249,171,0,.2)
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before{
    background:linear-gradient(to right, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%)
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before{
    background:linear-gradient(to left, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%)
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range>.mat-calendar-body-comparison-identical,.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after{
    background:#a8dab5
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range>.mat-calendar-body-selected{
    background:#46a35e
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected{
    background-color:$color-accent;
    color:#fff
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled>.mat-calendar-body-selected{
    background-color:rgba(255,64,129,.4)
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected{
    box-shadow:inset 0 0 0 1px #fff
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
    background-color:rgba(255,64,129,.3)
}
@media(hover: hover){
    .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
        background-color:rgba(255,64,129,.3)
    }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before{
    background:rgba(244,67,54,.2)
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before{
    background:rgba(249,171,0,.2)
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before{
    background:linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%)
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before{
    background:linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%)
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range>.mat-calendar-body-comparison-identical,.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after{
    background:#a8dab5
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range>.mat-calendar-body-selected{
    background:#46a35e
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected{
    background-color:#f44336;
    color:#fff
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled>.mat-calendar-body-selected{
    background-color:rgba(244,67,54,.4)
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected{
    box-shadow:inset 0 0 0 1px #fff
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
    background-color:rgba(244,67,54,.3)
}
@media(hover: hover){
    .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
        background-color:rgba(244,67,54,.3)
    }
}
.mat-datepicker-content-touch{
    box-shadow:0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)
}
.mat-datepicker-toggle-active{
    color:$color-primary
}
.mat-datepicker-toggle-active.mat-accent{
    color:$color-accent
}
.mat-datepicker-toggle-active.mat-warn{
    color:#f44336
}
.mat-date-range-input-inner[disabled]{
    color:rgba(0,0,0,.38)
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base{
    --mdc-icon-button-state-layer-size:40px;
    width:var(--mdc-icon-button-state-layer-size);
    height:var(--mdc-icon-button-state-layer-size);
    padding:8px
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target{
    display:none
}
.mat-calendar{
    font-family:Roboto, sans-serif
}
.mat-calendar-body{
    font-size:13px
}
.mat-calendar-body-label,.mat-calendar-period-button{
    font-size:14px;
    font-weight:500
}
.mat-calendar-table-header th{
    font-size:11px;
    font-weight:400
}
.mat-divider{
    border-top-color:rgba(0,0,0,.12)
}
.mat-divider-vertical{
    border-right-color:rgba(0,0,0,.12)
}
.mat-expansion-panel{
    background:#fff;
    color:rgba(0,0,0,.87)
}
.mat-expansion-panel:not([class*=mat-elevation-z]){
    box-shadow:0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)
}
.mat-action-row{
    border-top-color:rgba(0,0,0,.12)
}
.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),.mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]){
    background:rgba(0,0,0,.04)
}
@media(hover: none){
    .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover{
        background:#fff
    }
}
.mat-expansion-panel-header-title{
    color:rgba(0,0,0,.87)
}
.mat-expansion-panel-header-description,.mat-expansion-indicator::after{
    color:rgba(0,0,0,.54)
}
.mat-expansion-panel-header[aria-disabled=true]{
    color:rgba(0,0,0,.26)
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description{
    color:inherit
}
.mat-expansion-panel-header{
    height:48px
}
.mat-expansion-panel-header.mat-expanded{
    height:64px
}
.mat-expansion-panel-header{
    font-family:Roboto, sans-serif;
    font-size:14px;
    font-weight:500
}
.mat-expansion-panel-content{
    font-size:14px;
    font-weight:400;
    line-height:20px;
    font-family:Roboto, sans-serif;
    letter-spacing:.0178571429em
}
.mat-grid-tile-header,.mat-grid-tile-footer{
    font-size:14px
}
.mat-grid-tile-header .mat-line,.mat-grid-tile-footer .mat-line{
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    display:block;
    box-sizing:border-box
}
.mat-grid-tile-header .mat-line:nth-child(n+2),.mat-grid-tile-footer .mat-line:nth-child(n+2){
    font-size:12px
}
.mat-icon.mat-primary{
    color:$color-primary
}
.mat-icon.mat-accent{
    color:$color-accent
}
.mat-icon.mat-warn{
    color:#f44336
}
.mat-drawer-container{
    background-color:#fafafa;
    color:rgba(0,0,0,.87)
}
.mat-drawer{
    background-color:#fff;
    color:rgba(0,0,0,.87)
}
.mat-drawer.mat-drawer-push{
    background-color:#fff
}
.mat-drawer:not(.mat-drawer-side){
    box-shadow:0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)
}
.mat-drawer-side{
    border-right:solid 1px rgba(0,0,0,.12)
}
.mat-drawer-side.mat-drawer-end{
    border-left:solid 1px rgba(0,0,0,.12);
    border-right:none
}
[dir=rtl] .mat-drawer-side{
    border-left:solid 1px rgba(0,0,0,.12);
    border-right:none
}
[dir=rtl] .mat-drawer-side.mat-drawer-end{
    border-left:none;
    border-right:solid 1px rgba(0,0,0,.12)
}
.mat-drawer-backdrop.mat-drawer-shown{
    background-color:rgba(0,0,0,.6)
}
.mat-step-header.cdk-keyboard-focused,.mat-step-header.cdk-program-focused,.mat-step-header:hover:not([aria-disabled]),.mat-step-header:hover[aria-disabled=false]{
    background-color:rgba(0,0,0,.04)
}
.mat-step-header:hover[aria-disabled=true]{
    cursor:default
}
@media(hover: none){
    .mat-step-header:hover{
        background:none
    }
}
.mat-step-header .mat-step-label,.mat-step-header .mat-step-optional{
    color:rgba(0,0,0,.54)
}
.mat-step-header .mat-step-icon{
    background-color:rgba(0,0,0,.54);
    color:#fff
}
.mat-step-header .mat-step-icon-selected,.mat-step-header .mat-step-icon-state-done,.mat-step-header .mat-step-icon-state-edit{
    background-color:$color-primary;
    color:#fff
}
.mat-step-header.mat-accent .mat-step-icon{
    color:#fff
}
.mat-step-header.mat-accent .mat-step-icon-selected,.mat-step-header.mat-accent .mat-step-icon-state-done,.mat-step-header.mat-accent .mat-step-icon-state-edit{
    background-color:$color-accent;
    color:#fff
}
.mat-step-header.mat-warn .mat-step-icon{
    color:#fff
}
.mat-step-header.mat-warn .mat-step-icon-selected,.mat-step-header.mat-warn .mat-step-icon-state-done,.mat-step-header.mat-warn .mat-step-icon-state-edit{
    background-color:#f44336;
    color:#fff
}
.mat-step-header .mat-step-icon-state-error{
    background-color:rgba(0,0,0,0);
    color:#f44336
}
.mat-step-header .mat-step-label.mat-step-label-active{
    color:rgba(0,0,0,.87)
}
.mat-step-header .mat-step-label.mat-step-label-error{
    color:#f44336
}
.mat-stepper-horizontal,.mat-stepper-vertical{
    background-color:#fff
}
.mat-stepper-vertical-line::before{
    border-left-color:rgba(0,0,0,.12)
}
.mat-horizontal-stepper-header::before,.mat-horizontal-stepper-header::after,.mat-stepper-horizontal-line{
    border-top-color:rgba(0,0,0,.12)
}
.mat-horizontal-stepper-header{
    height:72px
}
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,.mat-vertical-stepper-header{
    padding:24px 24px
}
.mat-stepper-vertical-line::before{
    top:-16px;
    bottom:-16px
}
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after,.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before{
    top:36px
}
.mat-stepper-label-position-bottom .mat-stepper-horizontal-line{
    top:36px
}
.mat-stepper-vertical,.mat-stepper-horizontal{
    font-family:Roboto, sans-serif
}
.mat-step-label{
    font-size:14px;
    font-weight:400
}
.mat-step-sub-label-error{
    font-weight:normal
}
.mat-step-label-error{
    font-size:16px
}
.mat-step-label-selected{
    font-size:16px;
    font-weight:400
}
.mat-sort-header-arrow{
    color:$color-primary
}
//.mat-toolbar{
//    background:#f5f5f5;
//    color:rgba(0,0,0,.87)
//}
//.mat-toolbar.mat-primary{
//    background:$color-primary;
//    color:#fff
//}
//.mat-toolbar.mat-accent{
//    background:$color-accent;
//    color:#fff
//}
//.mat-toolbar.mat-warn{
//    background:#f44336;
//    color:#fff
//}
.mat-toolbar .mat-form-field-underline,.mat-toolbar .mat-form-field-ripple,.mat-toolbar .mat-focused .mat-form-field-ripple{
    background-color:currentColor
}
.mat-toolbar .mat-form-field-label,.mat-toolbar .mat-focused .mat-form-field-label,.mat-toolbar .mat-select-value,.mat-toolbar .mat-select-arrow,.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow{
    color:inherit
}
.mat-toolbar .mat-input-element{
    caret-color:currentColor
}
.mat-toolbar-multiple-rows{
    min-height:64px
}
.mat-toolbar-row,.mat-toolbar-single-row{
    height:64px
}
@media(max-width: 599px){
    .mat-toolbar-multiple-rows{
        min-height:56px
    }
    .mat-toolbar-row,.mat-toolbar-single-row{
        height:56px
    }
}
.mat-toolbar,.mat-toolbar h1,.mat-toolbar h2,.mat-toolbar h3,.mat-toolbar h4,.mat-toolbar h5,.mat-toolbar h6{
    font-size:20px;
    font-weight:500;
    line-height:32px;
    font-family:Roboto, sans-serif;
    letter-spacing:.0125em;
    margin:0
}
.mat-tree{
    background:#fff
}
.mat-tree-node,.mat-nested-tree-node{
    color:rgba(0,0,0,.87)
}
.mat-tree-node{
    min-height:48px
}
.mat-tree{
    font-family:Roboto, sans-serif
}
.mat-tree-node,.mat-nested-tree-node{
    font-weight:400;
    font-size:14px
}
.mat-h1,.mat-headline,.mat-typography .mat-h1,.mat-typography .mat-headline,.mat-typography h1{
    font-size:24px;
    font-weight:400;
    line-height:32px;
    font-family:Roboto, sans-serif;
    letter-spacing:normal;
    margin:0 0 16px
}
.mat-h2,.mat-title,.mat-typography .mat-h2,.mat-typography .mat-title,.mat-typography h2{
    font-size:20px;
    font-weight:500;
    line-height:32px;
    font-family:Roboto, sans-serif;
    letter-spacing:.0125em;
    margin:0 0 16px
}
.mat-h3,.mat-subheading-2,.mat-typography .mat-h3,.mat-typography .mat-subheading-2,.mat-typography h3{
    font-size:16px;
    font-weight:400;
    line-height:28px;
    font-family:Roboto, sans-serif;
    letter-spacing:.009375em;
    margin:0 0 16px
}
.mat-h4,.mat-subheading-1,.mat-typography .mat-h4,.mat-typography .mat-subheading-1,.mat-typography h4{
    font-size:14px;
    font-weight:500;
    line-height:22px;
    font-family:Roboto, sans-serif;
    letter-spacing:.0071428571em;
    margin:0 0 16px
}
.mat-h5,.mat-typography .mat-h5,.mat-typography h5{
    font:400 calc(14px * 0.83)/20px Roboto, sans-serif;
    margin:0 0 12px
}
.mat-h6,.mat-typography .mat-h6,.mat-typography h6{
    font:400 calc(14px * 0.67)/20px Roboto, sans-serif;
    margin:0 0 12px
}
.mat-body-strong,.mat-body-2,.mat-typography .mat-body-strong,.mat-typography .mat-body-2{
    font-size:16px;
    font-weight:400;
    line-height:24px;
    font-family:Roboto, sans-serif;
    letter-spacing:.03125em
}
.mat-body,.mat-body-1,.mat-typography .mat-body,.mat-typography .mat-body-1,.mat-typography{
    font-size:14px;
    font-weight:400;
    line-height:20px;
    font-family:Roboto, sans-serif;
    letter-spacing:.0178571429em
}
.mat-body p,.mat-body-1 p,.mat-typography .mat-body p,.mat-typography .mat-body-1 p,.mat-typography p{
    margin:0 0 12px
}
.mat-small,.mat-caption,.mat-typography .mat-small,.mat-typography .mat-caption{
    font-size:12px;
    font-weight:400;
    line-height:20px;
    font-family:Roboto, sans-serif;
    letter-spacing:.0333333333em
}
.mat-display-4,.mat-typography .mat-display-4{
    font-size:96px;
    font-weight:300;
    line-height:96px;
    font-family:Roboto, sans-serif;
    letter-spacing:-0.015625em;
    margin:0 0 56px
}
.mat-display-3,.mat-typography .mat-display-3{
    font-size:60px;
    font-weight:300;
    line-height:60px;
    font-family:Roboto, sans-serif;
    letter-spacing:-.0083333333em;
    margin:0 0 64px
}
.mat-display-2,.mat-typography .mat-display-2{
    font-size:48px;
    font-weight:400;
    line-height:50px;
    font-family:Roboto, sans-serif;
    letter-spacing:normal;
    margin:0 0 64px
}
.mat-display-1,.mat-typography .mat-display-1{
    font-size:34px;
    font-weight:400;
    line-height:40px;
    font-family:Roboto, sans-serif;
    letter-spacing:.0073529412em;
    margin:0 0 64px
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
    background: $color-primary !important;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
    background: $color-accent !important;
}
