// source https://gist.github.com/devinrhode2/2573411
/**
 * Like, basically PERFECT scrollbars
 */

/*
   It's pure CSS.
   Since a quick google search will confirm people going crazy about Mac OS Lion scrollbars...
   this has no fade-out effect.

   In Mac OS Lion, the lowest common-components denominator is always showing scrollbars by a setting.
   So, this fits that lowest common-components denominator.
   Facebook, lifehacker, and Google have all basically taken this approach. Of course Google uses incredibly ugly square scrollbars, but so be it.
   Also, in regards to the fade in/out effect, this may just be one reason why soo many people outraged (and still hide) the ticker.
   Ending note: I recommend this for non-lion users. As a lion user, I prefer my fading out scrollbars. If you sniff a UA of mac os lion, don't add this css.
*/

.CustomScroll {
  /* Turn on custom 8px wide scrollbar */
  ::-webkit-scrollbar {
    width: 8px; /* 1px wider than Lion. */
    /* This is more usable for users trying to click it. */
    background-color: rgba(0, 0, 0, 0);
    -webkit-border-radius: 100px;
  }
  /* hover effect for both scrollbar area, and scrollbar 'thumb' */
  ::-webkit-scrollbar:hover {
    background-color: rgba(0, 0, 0, 0.09);
  }

  /* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
  ::-webkit-scrollbar-thumb:vertical {
    /* This is the EXACT color of Mac OS scrollbars.
       Yes, I pulled out digital color meter */
    background: rgba(0, 0, 0, 0.5);
    -webkit-border-radius: 100px;
  }
  ::-webkit-scrollbar-thumb:vertical:active {
    background: rgba(0, 0, 0, 0.61); /* Some darker color when you click it */
    -webkit-border-radius: 100px;
  }
  /**
   * Like, basically PERFECT scrollbars
   */

  /*
     It's pure CSS.
     Since a quick google search will confirm people going crazy about Mac OS Lion scrollbars...
     this has no fade-out effect.

     In Mac OS Lion, the lowest common-components denominator is always showing scrollbars by a setting.
     So, this fits that lowest common-components denominator.
     Facebook, lifehacker, and Google have all basically taken this approach. Of course Google uses incredibly ugly square scrollbars, but so be it.
     Also, in regards to the fade in/out effect, this may just be one reason why soo many people outraged (and still hide) the ticker.
     Ending note: I recommend this for non-lion users. As a lion user, I prefer my fading out scrollbars. If you sniff a UA of mac os lion, don't add this css.
  */

  /* Turn on custom 8px wide scrollbar */
  ::-webkit-scrollbar {
    width: 8px; /* 1px wider than Lion. */
    /* This is more usable for users trying to click it. */
    background-color: rgba(0, 0, 0, 0);
    -webkit-border-radius: 100px;
  }
  /* hover effect for both scrollbar area, and scrollbar 'thumb' */
  ::-webkit-scrollbar:hover {
    background-color: rgba(0, 0, 0, 0.09);
  }

  /* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
  ::-webkit-scrollbar-thumb:vertical {
    /* This is the EXACT color of Mac OS scrollbars.
       Yes, I pulled out digital color meter */
    background: rgba(0, 0, 0, 0.5);
    -webkit-border-radius: 100px;
  }
  ::-webkit-scrollbar-thumb:vertical:active {
    background: rgba(0, 0, 0, 0.61); /* Some darker color when you click it */
    -webkit-border-radius: 100px;
  }

  /*
    After doing my first post here on Dabblet, I dug into their custom
    scrollbar css.

    here that is, I starred the box-shadow css for the nice pop.

  section.page:not(.focus):not(:hover)::-webkit-scrollbar {
    display: none;
  }
  ::-webkit-scrollbar-track,::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 999px;
  }
  ::-webkit-scrollbar-track {
    ** box-shadow: 1px 1px 5px rgba(0,0,0,.2) inset; **
  }
  ::-webkit-scrollbar-thumb {
    background: url(/img/noise.png);
    background-clip: content-box;
    ** box-shadow: 0 0 0 5px hsla(24, 20%, 50%,.4) inset; **
    min-height: 20px;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
  */

  ::-webkit-scrollbar {
    width: 8px; /* 1px wider than Lion. /
  height: 8px; / 1px wider than Lion. /
  / This is more usable for users trying to click it. /
  background-color: rgba(0,0,0,0);
  -webkit-border-radius: 100px;
  }
  / hover effect for both scrollbar area, and scrollbar 'thumb' */

  ::-webkit-scrollbar:hover {
    background-color: rgba(0, 0, 0, 0.09);
  }

  /* The scrollbar 'thumb' ...that marque oval shape in a scrollbar /
  ::-webkit-scrollbar-thumb {
  / This is the EXACT color of Mac OS scrollbars.
  Yes, I pulled out digital color meter /
  background: rgba(0,0,0,0.5);
  -webkit-border-radius: 100px;
  }
  ::-webkit-scrollbar-thumb:active {
  background: rgba(0,0,0,0.61); / Some darker color when you click it */
  -webkit-border-radius: 100px;
}

}
