@font-face {
  font-family: 'Open Sans Condensed';
  src: url('/assets/fonts/Open_Sans/OpenSans-Light.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans Regular';
  src: url('/assets/fonts/Open_Sans/OpenSans-Regular.ttf');
  font-display: swap;
}
