.Tags {
  .Tags-List {
    .Tags-List--Item {
      padding: 4px;
      margin: 2px;
      font-size: $font-size-2;
      cursor: pointer;
      border-radius: 4px;
    }
  }
}

pro-table pm-tags {
  .Tags {
    .Tags-List {
      .Tags-List--Item {
        font-size: $font-size-7;
      }
    }
  }
}
