$invalid-color: rgb(221, 44, 0);

.FormItem {

  &.invalid {
    color: $invalid-color;

    .FormItem-label, md-input-container label {
      color: $invalid-color;
    }

    md-input-container .md-input {
      color: $invalid-color;
      border-color: $invalid-color;
    }
  }
}

.FormItem-inline {
  margin: 0;
}

.FormItem-container {
  margin: 18px 0;
}

.FormItem-label {
  padding-left: 2px;
  color: rgba(0, 0, 0, 0.54);
}

.FormItem-messages {
  padding-left: 2px;
  font-size: 12px;
}

.FormItem-help {

  .md-button {
    margin: 0 8px 0 4px;
  }

  svg {
    color: rgb(189,189,189);
  }
}

.FormItem-time {
  width: 22px;
  margin: 0;
}
