.AgentMap {
  .node circle {
    fill: #fff;
    stroke: steelblue;
    stroke-width: 1.5px;
  }

  .node {
    font: 10px sans-serif;
  }

  .link {
    fill: none;
    stroke: #ccc;
    stroke-width: 1.5px;
  }

  .node rect {
    fill: #ccc;
  }

  tspan.discount {
    fill: #006EE6;
  }

  tspan.commission {
    fill: #0EAC00;
  }
}
