svg {
}

.chart rect {
  fill: steelblue;
}

.chart text {
  fill: white;
  font: 10px sans-serif;
  text-anchor: end;
}

.axis {
  shape-rendering: crispEdges;
}

.axis path, .axis line {
  fill: none;
  stroke-width: 1px;
  stroke: #000;
  margin: 100px;
  padding: 100px;
}

.axis text {
  fill: black;
  font-size: 10px;
  text-indent: 5px;
  margin: 100px;
  padding: 100px;
}

.x.axis path {
  stroke: #333;
}

.x.axis path {
  stroke: #333;
}

.x.axis line {
  stroke: #777;
  stroke-opacity: .3;
}

.y.axis line {
  stroke: #777;
  stroke-opacity: .3;
}

path.line {
  fill: none;
  stroke: #333;
  stroke-width: 1px;
}

rect.pane {
  cursor: move;
  fill: #eee;
  opacity: .3;
  pointer-events: all;
}

.barColor {
  stroke: #555;
  stroke-width: 1px;
}

/*  Tree  */

.node {
  cursor: pointer;
  font: 10px sans-serif;
}

.overlay{
  background-color:#EEE;
}

.node circle {
  fill: #fff;
  stroke: steelblue;
  stroke-width: 1.5px;
}

.node text {
  font-size:10px;
  font-family:sans-serif;
}

.link {
  fill: none;
  stroke: #ccc;
  stroke-width: 1.5px;
}

.templink {
  fill: none;
  stroke: blue;
  stroke-width: 3px;
}

.ghostCircle.show{
  display:block;
}

.ghostCircle, .activeDrag .ghostCircle{
  display: none;
}

// scales graph

rect.range.s0, rect.range.s2 {
  fill: rgb(222, 222 , 222);
}

rect.range.s1 {
  fill: rgb(190, 190, 190);
}
