.UserThumbnail {

  img {
    border-radius: 30px;
  }

  svg {
    color: $color3;
    fill: $color3;
    width: 64px;
    height: 64px;
  }
}
