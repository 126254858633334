.Editable {
  margin-bottom: 16px;

  .Editable-Label, .Editable-CustomLabel {
    color: rgba(0,0,0,0.54);
  }

  .Editable-CustomLabel {
    margin-bottom: 8px;
  }

  .Editable-Help {
    .md-button {
      margin: 0 8px 0 4px;
    }

    svg {
      color: rgb(189,189,189);
    }
  }

  &.Editable-Disabled {

    .Editable-Item {
      color: rgba(0,0,0,0.87);
      line-height: 25px;

      &.Error {
        color: $color-warn;
      }
    }

    .Editable-Label {
      transform: translate3d(0, 4px, 0) scale(0.8);
      transform-origin: left top;
    }
  }

  &.isFocused {
    border-bottom: 2px solid rgb(33,150,243);
    
    .Editable-Label {
      color: rgb(33,150,243);
    }
  }
}
