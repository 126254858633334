.InlineList {
  position: absolute;
  padding-bottom: 16px;
  display: block;
  z-index: 10002;
  background: #ffffff;
  max-height: 30rem;
  overflow: hidden;
  overflow-y: auto;

  pro-text-simple {
    .mat-input-flex {
      padding-left: 20px;
    }

    .mat-form-field-label , input{
      text-indent: 16px;
    }

  }



}

.InlineList-group, .InlineList-item {
  line-height: 18px;
  padding: 10px 16px 10px 16px;
}

.InlineList-item {
  cursor: pointer;

  &:hover {
    background-color: rgb(238, 238, 238);
  }

}

.InlineList-group {
  font-weight: 500;
}
