.FilesManager {
  position: relative;
  margin: 0 0 16px 0;

  .Editable, .Switch {
    margin: 0;
    padding: 0;
  }

  .md-avatar-icon {
    width: 24px;
    height: 24px;
    padding: 15px;
  }

  .md-avatar {
    width: 50px;
    height: 50px;
  }

  md-list {
    padding-top: 0;
  }

  .md-title {
    margin: 0;
  }
}

.FilesManager-footer {
  color: $color8;
  font-size: $font-size-6;
}

.FilesManager-fileName {
  width: 0;
}
