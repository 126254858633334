.Toolbar {
  margin: 0;
  white-space: nowrap;
  overflow: auto;

  .Toolbar-wrapper {
    background: rgb(224, 224, 224);
    color: rgba(0, 0, 0, 0.87);
  }

  .Toolbar-Headline {
    font-size: 28px;
    font-weight: 300;
    padding: 5px 0;
  }

  .Toolbar-link {
    display: inline-block;
    padding: 4px 0;
  }
  pm-tags {
    margin-bottom: 8px;
  }

  h5 {
    margin: 0;
    line-height: 20px;
  }

  .Toolbar-actions {
    padding: 0 8px 0 14px;
  }

  .Toolbar-tools {
    padding: 0 24px;
  }

  .Toolbar-wrapper {
    position: relative;
  }

  .Toolbar-Title {
    padding: 6px 2px 4px 24px;
    max-width: 100%;
    overflow: hidden;
  }

  .Toolbar-Subheader {
    padding: 2px 0 0 2px;
    color: $color8;
    font-weight: normal;
  }

  h3 {
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 8px;
    line-height: 1;
  }

  a, a:not(.md-button) {
    color: #0687e8;
    text-decoration: none;
  }

  .md-button {
    margin: 0;
  }
}

.Toolbar-Subheader {

  fa {
    margin-right: 4px;
  }

  .blue {
    color: #2196F3;
    fill: #2196F3;
  }

  .green {
    color: $color-green;
    fill: $color-green;
  }

  .yellow {
    color: #FFC107;
    fill: #FFC107;
  }

  .red {
    color: $color12;
    fill: $color12;
  }

  .grey {
    color: $color8;
    fill: $color8;
  }

}
