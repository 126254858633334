.BottomToolbar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px;
  background-color: $color7;
  z-index: 11;
}

.BottomToolbarStatic {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px;
  background-color: $color7;
  z-index: 11;
}
