.OperationsContainer {
  text {

    // show only 2 lines of text, 3rd overlaps with action buttons

    tspan {
      visibility: hidden;

      &:first-child {
        visibility: visible;
      }

      &:nth-child(2) {
        visibility: visible;
      }

    }
  }
}
