.TimeGraph {

  pm-slider {
    width: 200px;
  }

  .TimeGraph-Container {
    position: relative;
  }

  .entity-table-filter-preview {
    font-size: 9px;
    font-weight: normal;
    color: #aaa;
  }

  .TimeGraph-Header {
    border: 1px solid #ddd;
    font-weight: bold;
    text-align: center;
    height: 44px;
    position: absolute;
    display: table;
    color: rgba(0, 0, 0, 0.54);
  }

  .TimeGraph-Event {
    position: absolute;
    height: 20px;
    vertical-align: middle;

    .TimeGraph-EventTitle {
      font-size: $font-size-4;
      text-shadow: black 0px 0px 10px;
    }

    .TimeGraph-Event--GradientContainer {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      background-color: #C3DBEF;
      box-shadow: inset 0px 0px 2px rgba(255, 255, 255, 1);
    }

    .TimeGraph-Event--GradientOverlay {
      height: 100%;
      background: #37709E;
    }

    .TimeGraph-Event--TitleContainer {
      line-height: 21px;
      font-size: 10px;
      font-weight: bold;
      padding: 0 5px;
      position: absolute;
      left: 0;
      right: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: $color1;
    }
  }
}


.TimeGraph-Panel {
  width: 200px;

  .TimeGraph-Items {
    width: 200px;

    .TimeGraph-Items--Item {
      border-top: 1px solid #ddd;
      font-size: 12px;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .TimeGraph-Label {
    height: 44px;
  }
}

.TimeGraph-HeaderInner {
  display: table-cell;
  vertical-align: middle;
}

.TimeGraph-HeaderEvents {
  width: 150px;
  right: 0px;
  border-left: none;
}

.TimeGraph-Customer--Separator {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  left: 148px;
  background: #ddd;
}


.TimeGraph-Customer--EventType {
  text-align: left;
  font-size: 12px;
  line-height: 10px;
  padding: 5px 10px;
  vertical-align: middle;
  border-bottom: 1px solid #ddd;
}
.TimeGraph-Customer--EventType:last-child {
  border-bottom: none;
}
.TimeGraph-Content {

}
.TimeGraph-Canvas {
  height: 200px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.TimeGraph-ContentHeader {

}

.TimeGraph-Ruler {
  text-align: center;
}
.TimeGraph-RulerBlock {
  width: 80px;
  display: inline-block;
  height: 24px;
  line-height: 24px;
  vertical-align: middle;
  font-weight: bold;
  color: #888;
}
.TimeGraph-RulerBlock.active {
  color: #000;
}

.TimeGraph-DragContainer {
  width: 100%;
  height: 100%;
  position: absolute;
}
.TimeGraph-Columns {
  height: 100%;
}
.TimeGraph-Column {
  position: absolute;
  border-right: 1px dotted #ddd;
  height: 100%;
}
.TimeGraph-ColumnHeader {
  background: #F0F3F5;
  color: #555;
  text-align: center;
  height: 20px;
  line-height: 20px;
  vertical-align: middle;
  font-size: 12px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}
.TimeGraph-Events {

}

.TimeGraph-EventGradientOverlay {
  position: absolute;
  height: 100%;
  top: 0px;
  right: 0px;
  background: rgba(255, 255, 255, 0.7);

}
