mat-autocomplete {
  position: relative;
}
mat-autocomplete::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid;
  margin: 0 4px;
  top: 0;
  right: 0;
  pointer-events: none;
  font-size: 10px;
  color: $color-primary;
}
.mat-form-field input {
  text-overflow: ellipsis;
}
