.Notifications {
  .Notifications-Notification {
    &.isSelected {
      background-color: rgba(158,158,158,0.2);
    }

    .Button {
      margin: 0;
    }

    > button {
      padding: 10px 10px 10px 0;
    }

    .Notifications-Notification--Actions {
      width: 48px;
    }
  }

  .Notifications-Header {
    border-bottom: 1px solid $black-12;
  }

  .Notifications-Actions {
    margin-left: 4px;
  }

  .md-menu {
    padding: 0;
  }

  md-list {
    padding-top: 0;
  }
}
