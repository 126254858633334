table {
  border-spacing: 0;
}

*:focus {
  outline: 0;
}

* {
  -webkit-overflow-scrolling: touch;
}
