.operations-manager {

  &__link, &__preview-link {
    stroke: #A5C6E4;
    opacity: 0.4;
    fill: none;
    stroke-width: 4px;

    &--conditional {
      stroke: #E4A5A5;
    }

    &--conditional:hover {
      stroke: #E86161;
    }
  }

  &__link:hover, &__preview-link:hover {
    stroke: #61A8E8;
    opacity: 1;
  }

  &__operation {
    box-shadow: 2px 2px 7px #ADADAD;
    float:left;
    width: 120px;
    height: 100px;
    background: $color1;
    border: 1px solid #8a9aa5;
    position: relative;
    margin-right: 20px;
    z-index: 20;
    fill: #d6d6d6;
    cursor: pointer;

    > rect {
      stroke: $color8;
      stroke-width: 1;
    }

    &__name {
      display: -webkit-box;
      padding: 4px;
      overflow: hidden;
      text-align: center;
      font-family: 'Open Sans Regular', sans-serif;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }

    &--placeholder{
      background: #f4fbff;
      border: 1px solid #a2c0cb;
      float:left;
      width: 120px;
      height: 100px;
      position: relative;
      margin-right: 20px;
      z-index: 20;
      fill: $color7;
    }
  }

  .svg-icon-primary {
    fill: #2196F3;
  }

  .svg-icon-warn {
    fill: #C62828;
  }

  .operation-target .background {
    stroke: #0eac00;
    stroke-width: 1;
  }
}

.level {
  position: relative;
  margin-bottom: 10px;
  padding: 10px;
  border-bottom: 1px dashed #a2c0cb;
  background: rgba(255,255,255,0.9);
}

.level .name {
  text-align: center;
  padding: 5px;
  font-size: 11px;
  color: #fff;
  background: -moz-linear-gradient(top, #AEB2BD, #5E5C5C);
  background: -ms-linear-gradient(top, #AEB2BD, #5E5C5C);
  background: -o-linear-gradient(top, #AEB2BD, #5E5C5C);
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#AEB2BD), to(#5E5C5C));
  background: linear-gradient(0deg, #5E5C5C, #AEB2BD);
}
