$account_photo_width: 74px;
$account_actions_width: $sidebar-width - 12;

.Account {
  cursor: pointer;
  margin-bottom: 8px;

  a, a:focus {
    text-decoration: none;
    color: $color6;
  }

  .Account-name {
    //width: $account_actions_width;
    min-height: 23px;

    .Account-name--label {
      display: block;
      float: left;
      //width: $account_actions_width;
      position: relative;
      overflow: hidden;
      line-height: normal;
      font-family: 'Open Sans Regular';
      text-align: center;

      a {
        font-size: $font-size-0;
        font-weight: bold;
        color: $color1;
        white-space: nowrap;
        line-height: normal;
      }
    }
  }

  .Account-links {
  }

  .Account-photo {
    width: $account_photo_width;
    height: $account_photo_width;
  }
}
