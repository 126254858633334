@import '../../styles/variables';

.Warning {
  width: 100%;
  padding: 8px 8px 8px 16px;
  color: white;
  background-color: #E67777;
  border-top: 1px solid #FF9292;

  md-icon {
    color: white;
  }

  .md-button {
    margin: 0;
  }

  a, a:not(.md-button) {
    color: white;
    text-decoration: underline;
    font-weight: bold;

    &:hover {
      text-decoration: none;
      color: white;
    }
  }
}

.Warning-content {
  padding-left: 8px;
  font-size: $font-size-3;
  font-weight: 300;
  font-family: 'Open Sans Regular', sans-serif;
}
