@import "mixins";
@import "variables";
@import "entity_table/index";

$red_row: #ffbaba;
$red_row_odd: #ffdddd;

$green_row: #C5E1A5;
$green_row_odd: #DCEDC8;

$grey_row: #EEEEEE;
$grey_row_odd: #DDDDDD;

$purple_row: #c9b0ff;
$purple_row_odd: #e8d6ff;

$blue_row: #a8c8fa;
$blue_row_odd: #cee4ff;

.TableContainer {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
}

.Table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  border-spacing: 0;
  border-collapse: collapse;

  .Table-RowButtons {
    white-space: nowrap;
    text-align: right;
  }

  thead {
    background-color: white;

    th {
      text-align: left;
      color: rgba(0, 0, 0, 0.6);
      border: 0;

    }
  }

  tbody {
    > tr:hover, > tr.marked {
      -webkit-transition: background-color 100ms linear;
      -moz-transition: background-color 100ms linear;
      -o-transition: background-color 100ms linear;
      -ms-transition: background-color 100ms linear;
      transition: background-color 100ms linear;
      background-color: $color9;
    }

    td {
      span {
        white-space: nowrap;
      }
    }

  }

  tbody, tfoot, thead {

    tr {
      border-bottom: 1px solid $color10;
      vertical-align: middle;
    }

    tr > th, tr > td {
      padding: 0 4px;
      line-height: 1.42857143;
    }
  }

  &.stripped {
    tbody {
      > tr:nth-child(odd) > td,  > tr:nth-child(odd) > th {
        background-color: transparent;
      }

      > tr:nth-child(odd) {
        background-color: rgba(249,249,249, 0.55);

        &:hover {
          background-color: rgba(249,249,249, 0.95);
        }

      }

      > tr.green {
        background-color: rgba($green_row, 0.8);

        &:hover {
          background-color: rgba($green_row, 0.5);
        }

        &:nth-child(odd) {
          background-color: rgba($green_row_odd, 0.8);

          &:hover {
            background-color: rgba($green_row_odd, 0.5);
          }
        }
      }

      > tr.red {
        background-color: rgba($red_row, 0.8);

        &:hover {
          background-color: rgba($red_row, 0.5);
        }

        &:nth-child(odd) {
          background-color: rgba($red_row_odd, 0.8);

          &:hover {
            background-color: rgba($red_row_odd, 0.5);
          }
        }
      }

      > tr.purple {
        background-color: rgba($purple_row, 0.8);

        &:hover {
          background-color: rgba($purple_row, 0.5);
        }

        &:nth-child(odd) {
          background-color: rgba($purple_row_odd, 0.8);

          &:hover {
            background-color: rgba($purple_row_odd, 0.5);
          }
        }
      }

      > tr.blue {
        background-color: rgba($blue_row, 0.8);

        &:hover {
          background-color: rgba($blue_row, 0.5);
        }

        &:nth-child(odd) {
          background-color: rgba($blue_row_odd, 0.8);

          &:hover {
            background-color: rgba($blue_row_odd, 0.5);
          }
        }
      }

      > tr.grey {
        background-color: rgba($grey_row, 0.8);

        &:hover {
          background-color: rgba($grey_row, 0.5);
        }

        &:nth-child(odd) {
          background-color: rgba($grey_row_odd, 0.8);

          &:hover {
            background-color: rgba($grey_row_odd, 0.5);
          }
        }
      }

      > tr.yellow {
        background-color: rgba($color-table-yellow, 0.8);

        &:hover {
          background-color: rgba($color-table-yellow, 0.5);
        }

        &:nth-child(odd) {
          background-color: rgba($color-table-yellow_alt, 0.8);

          &:hover {
            background-color: rgba($color-table-yellow_alt, 0.5);
          }
        }
      }
    }
  }

  .Editable {
    margin: 0;
    padding: 0;
    border: 0;
  }

  .Price {
    padding-bottom: 0;
  }

}

pro-table-cell {

  pro-text-simple {

    mat-form-field {
      height: 40px;
      margin-top: -7px;
    }

  }

  .TableCell-Parameter {
    display: block;

    &::after {
      content: "\A";
      white-space: pre;
    }

    &:last-child {
      content: "";
    }

  }

  pro-table-cell-default {
    span:not(:has(fa)) {
      &.green {
        color: $color-green;
      }

      &.red {
        color: $color-warn;
      }

      &.purple {
        color: purple;
      }

      &.blue {
        color: $color-accent;
      }

      &.grey {
        color: $color-primary;
      }

      &.yellow {
        background-color: yellow;
      }
    }
  }

  pro-table-cell-default {
    &:has(span.green-bg) {
      background-color: $green_row;
      padding: 3px;
    }

    &:has(span.red-bg) {
      background-color: $color-table-red;
      padding: 3px;
    }

    &:has(span.purple-bg) {
      background-color: $purple_row;
      padding: 3px;
    }

    &:has(span.blue-bg) {
      background-color: $color-table-blue;
      padding: 3px;
    }

    &:has(span.grey-bg) {
      background-color: $grey_row;
      padding: 3px;
    }

    &:has(span.yellow-bg) {
      background-color: $color-table-yellow;
      padding: 3px;
    }
  }
}

.FixedHeader {
  overflow: hidden;

  .Table {
    margin-bottom: 0;
    position: relative;
  }
}
