.mat-tab-label, .Menu-category > .mat-button-wrapper{
  text-transform: capitalize!important;
}

.mat-tab-label, .mat-tab-link {
  min-width: 140px!important;
}

.mat-form-field-infix {
  width: initial!important;
}

.Long-Label .mat-checkbox-layout {
  white-space: initial;
}

.DialogTransparent {
  box-shadow: none!important;
  background: transparent!important;
}

.hasFlags .mat-option-text img.flag {
  position: relative;
  top: 6px;
}

pm-time-dropdown {
  .mat-form-field-label-wrapper {
    overflow: visible;

    label {
      overflow: visible;
    }

  }
}

mat-toolbar {
  overflow: hidden;
}

.mat-dialog-container {
  height: inherit!important;
}

.mat-form-field-suffix {
  position: relative;
  top: 1px;
}

.select-hide-arrow {
    .mat-select-arrow {
      border: none;
    }
}

pro-clock-timepicker {
  ngx-timepicker-field {
    div {
      border-bottom: none !important;
    }
  }

  .ngx-timepicker-control__input {
    background: transparent;
  }
}
