.notification {
  line-height: 1.5em;
}

.notification-condition-operator {
  width: auto;
  display: inline-block;
}

.notification-condition-value {
  display: inline-block;
}

.notification-condition-value-separator {
  font-style: italic;
  display: inline-block;
  padding: 0 3px;
}
