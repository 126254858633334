.GoogleSettings {
  padding: 16px;

  .GoogleSettings-label {
    padding:16px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.010em;
    line-height: 24px;
  }

  .GoogleSettings-UserContainer {
     & > div > div {
      flex: 1 1 auto;

     }

  }

}
