@media print {
  body,
  mat-sidenav-content,
  .MainContent,
  .OverflowAuto,
  pm-ui-view-outlet
  {
    overflow: visible!important;
  }

  .Header,
  .Footer,
  mat-sidenav,
  .EntityTable .EntityTable-Actions,
  .Table-Filters,
  .FixedHeader,
  .Pagination,
  .EntityTable .Btn-actions,
  .Table .Table-RowButtons,
  .ToolBtns,
  .mat-drawer,
  pm-navigation-header,
  pm-toolbar pm-tags,
  .Toolbar-actions,
  .Toolbar-link,
  pro-warning,
  pm-tabs {
    display: none !important;
  }

  .MainContent {
    margin: 0;
    transform: translate3d(0px, 0px, 0px);
  }

  .Padding {
    padding: 0!important;
  }

  .Layout {
    .mat-sidenav-container,
    .mat-drawer-content {
      display: block;
      overflow: visible;
    }
  }

  .mat-drawer-content {
    margin-left: 0!important;
  }

  .EntityTable-subtotal-row {
    td {
      font-size: $font-size-2!important;
    }
  }
}
