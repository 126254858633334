.List {
  .List-Item {
    .List-Item--Name {
      padding-right: 5px;
    }

    .pmIcon {
      width: 18px;
      height: 18px;
    }
  }

  .List-NewItem {
    .pmIcon {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
}
