.CustomerPortalArticles {
  pm-image {
    margin-right: 15px;
  }

  .CustomerPortalArticles-Description {
    padding-top: 5px;
  }

  .CustomerPortalArticles-AritcleImagePlacehodler {
    display: block;
    width: 150px;
    height: 150px;
    margin-right: 15px;
    background-color: #F3F6FC;
  }
}
