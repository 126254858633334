.ImageRuler {
  padding-left: 11.4px;
  padding-bottom: 9px;
  margin: 20px;
  background-image: url('/src/assets/images/imageruler.png');
  background-position: left bottom;
  background-repeat: no-repeat;

  .ImageRuler-Border {
    border: 1px solid rgba(0,0,0,0.32);
    margin: 15px;
  }
}
